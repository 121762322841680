import { type FormId, type Page } from '../../../common/constants/pathInterfaces.js';
import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { type OpenFormGotoProps, useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { OpenFormGrid, OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { type OpenFormOutlet } from '../OpenFormLayout/OpenFormLayoutOutlet.js';
import { OpenFormViewSection } from './OpenFormViewSection.js';
import { OpenFormViewSummary } from './OpenFormViewSummary.js';
import { backMsg, clearFormMsg, t } from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { useOpenForm } from '../OpenFormHooks/useOpenForm.js';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import classNames from 'classnames';

export const OpenFormViewLayout = () => {
  const { list = paths.OPENFORM_LIST_FORM, referenceNumber } = (useLocation().state ?? {}) as OpenFormGotoProps;
  const { preview } = useOutletContext<Required<OpenFormOutlet>>();
  const { formState, reset } = useFormContext();
  const { answers, disabled, page } = useOpenFormState();
  const params = useParams<FormId & Page>();
  const async = useOpenFormAsync();
  const goto = useOpenFormGoto();
  const form = useOpenForm(preview, params.formId!, referenceNumber);
  const breadcrumbs = useMemo(() => Array.from({ length: (form?.sections.length || 0) + 1 }, (_, i) => i + 1), [form]);

  const back = useCallback(() => goto({ list }), [list, goto]);

  const clear = useCallback(() => {
    async.cache.clear();
    async.actions.answers(undefined);
    reset({});
    goto({ page: 1 });
  }, [reset, async, goto]);

  useEffect(() => {
    if (form?.reference && params.page !== String(page)) {
      goto({ page });
    }
  }, [page, params, async, goto, form]);

  return (
    <OpenFormBar
      leftFunc={preview ? undefined : back}
      leftIcon="arrow-left"
      leftText={t.D1E9(backMsg)}
      rightFunc={answers.size ? clear : undefined}
      rightIcon="delete"
      rightText={t.JQ1V(clearFormMsg)}
    >
      {!form ? null : (
        <OpenFormGrid className="of-openform__view">
          <OpenFormGridRow className="of-openform__view__breadcrumbs">
            <OpenFormGridCol className="of-openform__view__breadcrumbs__grid" colWidth={9}>
              {breadcrumbs.map(num => (
                <OpenFormButton
                  className={classNames({ ['breadcrumb--incomplete']: num > page })}
                  disabled={disabled}
                  color="primary"
                  size="l"
                  text={num}
                  key={num}
                />
              ))}
            </OpenFormGridCol>
          </OpenFormGridRow>
          {page > form.sections.length ? (
            <OpenFormViewSummary
              form={form}
              answers={answers}
              disabled={disabled}
              preview={preview}
              async={async}
              goto={goto}
            />
          ) : (
            <OpenFormViewSection
              form={form}
              answers={answers}
              disabled={disabled}
              errors={formState.errors}
              page={page}
              goto={goto}
            />
          )}
        </OpenFormGrid>
      )}
    </OpenFormBar>
  );
};
