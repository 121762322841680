import { type DetailedHTMLProps, type InputHTMLAttributes, type ReactNode, useId } from 'react';

export const OpenFormCheckbox = ({
  indeterminate,
  label,
  ...props
}: {
  indeterminate?: boolean;
  label?: ReactNode;
} & Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'id' | 'ref' | 'type'>) => {
  const id = useId();
  return (
    <div className="of-openform-checkbox">
      <input {...props} id={id} ref={el => el && (el.indeterminate = !!indeterminate)} type="checkbox" />
      {!label ? null : <label htmlFor={id}>{label}</label>}
    </div>
  );
};
