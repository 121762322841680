import { OF, has } from '../../OpenForm.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormItem } from '../../OpenFormComponents/OpenFormItem.js';
import { type OpenFormSummary } from '../../OpenFormHooks/useOpenFormSummary.js';
import { concat } from '../../OpenFormUtils.js';
import { orderingContactMsg, t } from '../../../../common/i18n/index.js';

export const OpenFormSummaryOrderingContact = ({
  summary: { contactEntries, installationAddress },
}: {
  summary: OpenFormSummary;
}) => {
  const address = installationAddress;
  const contact = contactEntries.find(([question]) => has(OF.Ordering_Contact__c)(question))?.[1];
  return !address && !contact ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.IY5V(orderingContactMsg)}</h4>
        <OpenFormGridRow>
          <OpenFormGridCol>
            <OpenFormItem>
              {!contact ? null : (
                <>
                  <div>
                    {contact.FirstName} {contact.LastName}
                  </div>
                  <div>{contact.Email}</div>
                  <div>{contact.MobilePhone}</div>
                </>
              )}
              {!address ? null : (
                <>
                  <div>{concat(address.line1, address.line2).join(' ')}</div>
                  <div>
                    {address.postalCode} {address.postOffice}
                  </div>
                </>
              )}
            </OpenFormItem>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
