import * as CL from '@design-system/component-library';
import { type OpenFormOutlet } from '../OpenFormLayout/OpenFormLayoutOutlet.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useMemo } from 'react';
import { useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { useOutletContext } from 'react-router-dom';
import classNames from 'classnames';

export const OpenFormListNavigation = ({ disabled, pathname }: { disabled: boolean; pathname: string }) => {
  const { title } = useOutletContext<Required<OpenFormOutlet>>();
  const goto = useOpenFormGoto();
  return (
    <CL.TabNavigation className={classNames({ ['ds-tabnavigation--disabled']: disabled })}>
      {useMemo(
        () =>
          [paths.OPENFORM_LIST_FORM, paths.OPENFORM_LIST_DRAFT].map(list => (
            <CL.TabNavigation.Tab
              key={list}
              selected={list === pathname}
              title={title(list)}
              onClick={() => goto({ list })}
            />
          )),
        [pathname, title, goto]
      )}
    </CL.TabNavigation>
  );
};
