import { AddOnRulesSubscriptionType, CustomerOrderListFields, ModelType, SubscriptionCategory } from './enums.js';
import { CustomerOrderStatus } from '../generated/api/customerOrderStatus.js';
import { OnlineModelCategory } from '../generated/api/onlineModelCategory.js';
import { SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM } from '../components/Subscriptions/SubscriptionsVoice.js';
import { SourceSystem } from '../generated/api/sourceSystem.js';
import { SubscriptionType } from '../generated/api/subscriptionType.js';
import { TableSortOrder, TableUrlParams, getItemsPerPageFromOptionsOrDefault } from '../components/Table';
import { collectProductCategoriesOfVirtualCatalog } from './utils/catalogUtils';
import { combineAddOnRulesResponses } from '../components/SubscriptionDetails/addOnDependencyUtils';
import { convertToCommonErrors } from './utils/errorUtils.js';
import { createDnsRecordsLoader } from '../components/DnsManagement/dnsManagementUtils.js';
import { createPreLoadedAddOnRules } from '../public/site/path/FixedBroadbandOrder/fixedBroadbandAddOnUtil.js';
import { defer } from 'react-router-dom';
import {
  fetchAddOnRules,
  fetchBillChannels,
  fetchBillingAccount,
  fetchBillingAccountScheduledChange,
  fetchBillingAccounts,
  fetchCatalogOnlineModelHeaders,
  fetchCompanyInfo,
  fetchCompanyInfoAndEnabledOnlineModels,
  fetchContact,
  fetchContacts,
  fetchCustomerOrder,
  fetchCustomerOrderAdditionalInfo,
  fetchCustomerOrders,
  fetchDiscountedPrices,
  fetchDnsRecordHistory,
  fetchDnsRecords,
  fetchDnsRecordsHistory,
  fetchHolidays,
  fetchInvoice,
  fetchInvoiceDocuments,
  fetchInvoices,
  fetchMfaDetails,
  fetchMobileIdContract,
  fetchMobileIdContracts,
  fetchMobileIdContractsByMdmId,
  fetchNumberRange,
  fetchOnboardingRequests,
  fetchOnlineModelEffectivePriceForCode,
  fetchOnlineModelForCode,
  fetchOnlineModels,
  fetchOnlineModelsForCategory,
  fetchOpenSupportCases,
  fetchPendingSubscriptionActions,
  fetchReports,
  fetchSecondaryAccounts,
  fetchSubscription,
  fetchSubscriptionAction,
  fetchSubscriptionActions,
  fetchSubscriptionAggregates,
  fetchSubscriptions,
  fetchSupportCase,
  fetchSupportCaseHistory,
  fetchSupportCases,
  fetchVirtualCatalog,
  fetchVirtualCatalogs,
  getMessagesFromChatHistory,
  hasPendingActionsForContact,
} from './fetch.js';
import {
  getActiveAccountMasterId,
  getAiChatSessionId,
  getInvoicePaymentMdmId,
  getPrimaryMdmId,
} from '../selfservice/common/localStorageUtils.js';
import { getContactSort } from './utils/contactUtils.js';
import { getOnlineModelFilteringOutNonOfferAddOns, mergeArrays } from '../selfservice/reducers/reducerUtils.js';
import { getShoppingBasketFromLocalStorage, parseBasketJson } from './hooks/useShoppingBasket';
import { getSubscriptionTypes } from '../public/common/util/category.js';
import { mergeObjects } from './utils/objectUtils.js';
import {
  mobileM2MSubscriptionCards4G,
  mobileM2MSubscriptionCards5G,
} from '../components/OrderSubscriptionSelection/content/LaitenettiSubscriptionCardContent';
import { replacePipeWithCommaInQueryParams } from './utils/filterUtils.js';
import { resolveSort } from './utils/supportCaseUtils.js';
import type { AddOnRule } from './types/addOnRule.js';
import type { AddOnRulesResponse } from '../generated/api/addOnRulesResponse.js';
import type { BillChannel } from '../generated/api/billChannel.js';
import type { BillingAccount } from '../generated/api/billingAccount.js';
import type { BillingAccountScheduledChangeResponse } from '../generated/api/billingAccountScheduledChangeResponse.js';
import type { BillingAccountSearchResponse } from '../generated/api/billingAccountSearchResponse.js';
import type { BillingAccountsResponse } from '../generated/api/billingAccountsResponse.js';
import type { CatalogSearchResponse } from '../generated/api/catalogSearchResponse';
import type { CommonError } from './types/errors.js';
import type { CompanyInfoResponse } from '../generated/api/companyInfoResponse.js';
import type { CompanyInfoState, OnlineModelHeadersState } from './types/states.js';
import type { Contact } from '../generated/api/contact.js';
import type { ContactsResponse } from '../generated/api/contactsResponse.js';
import type { Contract } from '../generated/api/contract';
import type { CustomerOrder } from '../generated/api/customerOrder.js';
import type { CustomerOrderAdditionalInfo } from '../generated/api/customerOrderAdditionalInfo.js';
import type { DefaultListSearchParams } from '../components/Table';
import type { DiscountedPricesResponse } from '../generated/api/discountedPricesResponse';
import type { Invoice } from '../generated/api/invoice.js';
import type { InvoiceDocumentsResponse } from '../generated/api/invoiceDocumentsResponse.js';
import type { InvoicesResponse } from '../generated/api/invoicesResponse.js';
import type { LoaderFunctionArgs } from 'react-router-dom';
import type { MfaDetailsResponse } from '../generated/api/mfaDetailsResponse';
import type { MoveContactPendingActionsResponse } from '../generated/api/moveContactPendingActionsResponse';
import type { NumberRangeResponse } from '../generated/api/numberRangeResponse';
import type { OnboardingRequestsResponse } from '../generated/api/onboardingRequestsResponse.js';
import type { OnlineModel } from '../generated/api/onlineModel.js';
import type { OnlineModelsResponse } from '../generated/api/onlineModelsResponse.js';
import type { ReportItemResponse } from '../generated/api/reportItemResponse';
import type { SecondaryAccount } from '../generated/api/secondaryAccount';
import type { ShoppingBasketType } from './types/shoppingBasket.js';
import type { Subscription } from '../generated/api/subscription.js';
import type { SubscriptionAction } from '../generated/api/subscriptionAction.js';
import type { SubscriptionActionsResponse } from '../generated/api/subscriptionActionsResponse.js';
import type { SubscriptionAggregationsResponse } from '../generated/api/subscriptionAggregationsResponse.js';
import type { SubscriptionDetailsSelectedAddOns } from '../generated/api/subscriptionDetailsSelectedAddOns';
import type { SubscriptionSearchResponse } from '../generated/api/subscriptionSearchResponse.js';
import type { SubscriptionsDeviceMultiFilter } from '../components/SubscriptionLists/SubscriptionTable';
import type { SubscriptionsQuery } from './fetch.js';
import type { SubscriptionsResponse } from '../generated/api/subscriptionsResponse.js';
import type { SupportCaseDataBundle } from '../generated/api/supportCaseDataBundle.js';
import type { SupportCaseHeader } from '../generated/api/supportCaseHeader.js';
import type { SupportCaseHistory } from '../generated/api/supportCaseHistory.js';
import type { SupportCasesResponse } from '../generated/api/supportCasesResponse.js';
import type { SupportCasesSearchResponse } from '../generated/api/supportCasesSearchResponse.js';
import type { VirtualCatalog } from '../generated/api/virtualCatalog';

export interface SubscriptionIdParams {
  subscriptionId: string;
}

export type SubscriptionLoaderArgs = LoaderFunctionArgs & { params: SubscriptionIdParams };

export interface SupportCaseLoaderResponse {
  supportCase: SupportCaseDataBundle;
  history: SupportCaseHistory[];
}

export interface BillingAccountLoaderResponse {
  billChannels: BillChannel[];
  contacts: Contact[];
}

export interface InvoiceLoaderResponse {
  invoice: Invoice;
  billChannels: BillChannel[];
  openSupportCases: SupportCaseHeader[];
  billingAccount?: BillingAccount;
}

export interface DeviceSubscriptionLoaderResponse {
  billingAccounts: BillingAccountsResponse;
  companyInfo: CompanyInfoResponse;
  contacts: Contact[];
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
}

export type DeviceSubPostChangeRequestLoaderResponse = Pick<
  DeviceSubscriptionLoaderResponse,
  'companyInfo' | 'pendingSubscriptionActions' | 'subscription'
>;

export interface SubscriptionAddOnLoaderResponse {
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
  addOn: SubscriptionDetailsSelectedAddOns;
}

const defaultRequest = {
  offset: 0,
  order: 'desc',
};

type LoaderDefaultParams = DefaultListSearchParams & {
  params: URLSearchParams;
  itemsPerPage?: number;
  mdmId?: string;
};

const requireMdmIdFromRequest = (request: Request): string =>
  new URL(request.url).searchParams.get('mdmId') ||
  (() => {
    throw new Error('missing mdmId');
  })();

const getMdmIdFromRequest = (request: Request): string | undefined =>
  new URL(request.url).searchParams.get('mdmId') || undefined;

export const getSearchParams = (request: Request): LoaderDefaultParams => {
  const params = new URLSearchParams(new URL(request.url).search);
  const itemsPerPage = getItemsPerPageFromOptionsOrDefault(params.get('limit') || undefined);
  const offset = params.get('offset') || '0';
  const order = params.get('order') || undefined;
  const search = params.get('search') || undefined;
  const sort = params.get('sort') || undefined;
  return { itemsPerPage, offset, order, search, sort, params };
};

const buildSubscriptionsQuery = (
  request: Request,
  category?: SubscriptionCategory,
  subscriptionTypes?: SubscriptionType[],
  subscriptionContactId?: string,
  deviceFilters?: SubscriptionsDeviceMultiFilter
): SubscriptionsQuery => {
  const { params, offset, itemsPerPage, ...rest } = getSearchParams(request);
  const subscriptionType =
    subscriptionTypes ||
    (params.get(SUBSCRIPTION_TYPE_SEARCH_FILTER_PARAM) as SubscriptionType | null) ||
    (category && getSubscriptionTypes(category)) ||
    undefined;
  const subscriptionSubType = params.get('subscriptionSubType');
  return mergeObjects(defaultRequest, {
    details: true,
    offset: Number(offset),
    limit: itemsPerPage,
    subscriptionType,
    subscriptionSubType,
    subscriptionContactId,
    useSearchService: true,
    ...replacePipeWithCommaInQueryParams(Object.fromEntries(params), ['limit']),
    ...deviceFilters,
    ...rest,
  });
};

const buildSubscriptionsQueryByBillingAccountDisplayId = (
  request: Request,
  billingAccountDisplayId: string,
  categories: SubscriptionCategory[]
): SubscriptionsQuery => {
  const { params, offset, itemsPerPage, ...rest } = getSearchParams(request);
  const subscriptionType = categories.flatMap(cat => getSubscriptionTypes(cat));
  return mergeObjects(defaultRequest, {
    details: true,
    offset: Number(offset),
    limit: itemsPerPage,
    subscriptionType,
    billingAccountDisplayId,
    useSearchService: true,
    ...replacePipeWithCommaInQueryParams(Object.fromEntries(params), ['limit']),
    ...rest,
  });
};

export const supportCaseLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<SupportCaseLoaderResponse> => {
  if (params.supportCaseDisplayId === undefined) {
    throw new Error('missing supportCaseDisplayId');
  }
  const mdmId = requireMdmIdFromRequest(request);
  return Promise.all([
    fetchSupportCase(params.supportCaseDisplayId, mdmId),
    fetchSupportCaseHistory(params.supportCaseDisplayId, mdmId),
  ]).then(fetchResponse => ({ supportCase: fetchResponse[0], history: fetchResponse[1].caseHistory || [] }));
};

export const supportCasesLoader = ({ request }: LoaderFunctionArgs) => {
  const { params, sort, offset, itemsPerPage, ...rest } = getSearchParams(request);
  const mdmId = getMdmIdFromRequest(request);
  return fetchSupportCases(
    mergeObjects(defaultRequest, {
      feature: params.get('feature'),
      status: params.get('status'),
      offset: Number(offset),
      limit: itemsPerPage,
      sort: resolveSort(sort),
      ...rest,
    }),
    mdmId
  );
};

export interface BillingAccountLayoutLoaderData {
  billingAccount: BillingAccount;
  billingAccountScheduledChange: BillingAccountScheduledChangeResponse;
}

export const billingAccountLayoutLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<BillingAccountLayoutLoaderData> => {
  if (params.billingAccountId === undefined) {
    throw new Error('missing billingAccountId');
  }
  const mdmId = requireMdmIdFromRequest(request);
  const billingAccountsResponse = await fetchBillingAccount(params.billingAccountId, mdmId);
  const billingAccount = billingAccountsResponse.billingAccounts?.[0] as BillingAccount;
  if (!billingAccount) {
    throw new Response('Billing account not found', { status: 404 });
  }
  const billingAccountScheduledChange = await fetchBillingAccountScheduledChange(billingAccount.billingAccountId!);

  return {
    billingAccount,
    billingAccountScheduledChange,
  };
};

export const billingAccountLoader = async ({ request }: LoaderFunctionArgs): Promise<BillingAccountLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [billChannels, contactsResponse] = await Promise.all([
    fetchBillChannels(),
    fetchContacts({ offset: 0 }, { mdmId }),
  ]);
  return {
    billChannels,
    contacts: contactsResponse.contacts || [],
  };
};

export const billingAccountSubscriptionsLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<SubscriptionsResponse> => {
  if (params.billingAccountId === undefined) {
    throw new Error('missing billingAccountId');
  }
  const mdmId = requireMdmIdFromRequest(request);
  const query = buildSubscriptionsQueryByBillingAccountDisplayId(
    request,
    params.billingAccountId,
    Object.values(SubscriptionCategory)
  );
  return await fetchSubscriptions(query, mdmId);
};

export interface BillingAccountInvoicesData {
  invoices: InvoicesResponse;
}

export const billingAccountInvoicesLoader = async ({
  request,
  params,
}: LoaderFunctionArgs): Promise<BillingAccountInvoicesData> => {
  const { itemsPerPage, offset = 0, search, sort = 'due', order = 'desc' } = getSearchParams(request);
  const mdmId = requireMdmIdFromRequest(request);

  return {
    invoices: await fetchInvoices(
      {
        useSearchService: true,
        search: `${params.billingAccountId}${search ? ' ' + search : ''}`,
        sort,
        order,
        limit: itemsPerPage,
        offset: Number(offset),
      },
      mdmId
    ),
  };
};

export const billingAccountInvoiceDocumentsLoader = async ({ request, params }: LoaderFunctionArgs) => {
  const { itemsPerPage, offset = 0, search, sort = 'created', order = 'desc' } = getSearchParams(request);
  const mdmId = requireMdmIdFromRequest(request);
  return {
    documents: await fetchInvoiceDocuments(
      {
        useSearchService: true,
        search: `${params.billingAccountId}${search ? ' ' + search : ''}`,
        sort,
        order,
        limit: itemsPerPage,
        offset: Number(offset),
      },
      mdmId
    ),
  };
};

export interface BillingAccountListLoaderData {
  billingAccounts?: BillingAccountsResponse;
}

/**
 * Get billing accounts from Elasticsearch so that they can be shown in the
 * billing account list.
 *
 * For the billing account list billing accounts from all source systems (SFDC,
 * MIPA, TELLUS) should be shown without restrictions. Restrictions may apply
 * to billing accounts shown in dropdowns when they are selected for orders
 * and such.
 */
export const billingAccountListLoader = async ({ request }: LoaderFunctionArgs) => {
  const { itemsPerPage, offset, search, sort, order } = getSearchParams(request);
  const mdmId = getMdmIdFromRequest(request);
  return {
    billingAccounts: await fetchBillingAccounts(
      {
        limit: itemsPerPage,
        offset: Number(offset),
        search,
        sort,
        order,
        useSearchService: true,
      },
      { mdmId }
    ),
  };
};

/**
 * Loader for Catalog Configurations including billing-accounts, bill-channels and contacts
 * billing-accounts and bill-contacts calls are fast, so they're required from the start
 * contacts are slow, so the data is referred and used later in the application for optimal UX
 */
export const catalogConfigurationsLoader = async ({ request }: LoaderFunctionArgs) => {
  const mdmId = requireMdmIdFromRequest(request);
  return defer({
    billingAccounts: await fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
    billChannels: await fetchBillChannels(),
    contacts: fetchContacts({ offset: 0 }, { mdmId }),
    companyInfo: await fetchCompanyInfo(mdmId),
  });
};

export interface BaLoaderData {
  billChannels: BillChannel[];
  contacts: ContactsResponse;
  companyInfo: CompanyInfoState;
}

export const createNewBillingAccountLoader = async ({ request }: LoaderFunctionArgs): Promise<BaLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  return {
    billChannels: await fetchBillChannels(),
    contacts: await fetchContacts({ offset: 0 }, { mdmId }),
    companyInfo: await fetchCompanyInfo(mdmId),
  };
};

export const customerOrdersLoader = ({ request }: LoaderFunctionArgs) => {
  const { params, sort, offset, itemsPerPage, ...rest } = getSearchParams(request);
  const mdmId = getMdmIdFromRequest(request);
  return fetchCustomerOrders(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      status: params.get('status') || undefined,
      sort: sort || CustomerOrderListFields.CREATED,
      ...rest,
    }),
    mdmId
  );
};

export interface CustomerOrderLoaderData {
  customerOrder: CustomerOrder;
  billingAccounts: BillingAccountsResponse;
  additionalInfo?: CustomerOrderAdditionalInfo;
  companyInfo?: CompanyInfoResponse;
  contacts?: ContactsResponse;
}

export interface CatalogProducts {
  phones: OnlineModelsResponse;
  accessories: OnlineModelsResponse;
  tablets: OnlineModelsResponse;
  computers: OnlineModelsResponse;
  networkEquipment: OnlineModelsResponse;
}

export interface CatalogProductLoaderData {
  onlineModels: CatalogProducts;
  discountedPrices: DiscountedPricesResponse;
}

export const customerOrderLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<CustomerOrderLoaderData> => {
  if (params.orderId === undefined) {
    throw new Error('missing customerOrderId');
  }
  const mdmId = requireMdmIdFromRequest(request);
  const customerOrder = (await fetchCustomerOrder(params.orderId, mdmId)).customerOrders?.[0] as CustomerOrder;
  if (!customerOrder) {
    throw new Response('Customer order not found', { status: 404 });
  }
  const pending = customerOrder.status === CustomerOrderStatus.PENDING_APPROVAL;
  return {
    customerOrder,
    additionalInfo: pending ? await fetchCustomerOrderAdditionalInfo(params.orderId, mdmId) : undefined,
    companyInfo: pending ? await fetchCompanyInfo(mdmId) : undefined,
    billingAccounts: await fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
    contacts: await fetchContacts({ useSearchService: true, offset: 0 }, { mdmId }),
  };
};

export interface DeliveryOrderLoaderData {
  customerOrder: CustomerOrder;
}

export const deliveryOrderLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<DeliveryOrderLoaderData> => {
  if (params.orderId === undefined) {
    throw new Error('missing orderId');
  }
  if (params.subscriptionId === undefined) {
    throw new Error('missing subscriptionId');
  }
  const mdmId = requireMdmIdFromRequest(request);
  const customerOrder = (await fetchCustomerOrder(params.orderId, mdmId)).customerOrders?.[0] as CustomerOrder;
  if (!customerOrder) {
    throw new Response('Customer order not found', { status: 404 });
  }
  return { customerOrder };
};

export const dnsRecordHistoryLoader = ({ params }: LoaderFunctionArgs) =>
  fetchDnsRecordHistory(params.subscriptionId!, Number(params.historyId!));

export const dnsRecordsHistoryLoader = createDnsRecordsLoader(fetchDnsRecordsHistory, {
  [TableUrlParams.SORT]: 'id',
  [TableUrlParams.ORDER]: TableSortOrder.DESC,
  [TableUrlParams.LIMIT]: '30',
});

export const getDnsRecords = createDnsRecordsLoader(fetchDnsRecords, {
  [TableUrlParams.SORT]: 'type',
  [TableUrlParams.ORDER]: TableSortOrder.ASC,
  [TableUrlParams.LIMIT]: '30',
});

export interface SubscriptionsLoaderData {
  subscriptions: SubscriptionsResponse;
  aggregations: SubscriptionAggregationsResponse;
}

export const broadbandSubscriptionsLoader = async ({ request }: LoaderFunctionArgs) => {
  const query = buildSubscriptionsQuery(request, SubscriptionCategory.BROADBAND);
  const mdmId = getMdmIdFromRequest(request);
  return {
    subscriptions: await fetchSubscriptions(query, mdmId),
    aggregations: await fetchSubscriptionAggregates(SubscriptionCategory.BROADBAND, request, mdmId),
  };
};

export const serviceSubscriptionsLoader = ({ request }: LoaderFunctionArgs) => {
  const query = buildSubscriptionsQuery(request, SubscriptionCategory.SERVICE);
  const mdmId = getMdmIdFromRequest(request);
  return fetchSubscriptions(query, mdmId);
};

export interface ContactLoaderData {
  contact: Contact;
  deviceSubscriptions: SubscriptionSearchResponse[];
  voiceSubscriptions: SubscriptionSearchResponse[];
  broadbandSubscriptions: SubscriptionSearchResponse[];
  serviceSubscriptions: SubscriptionSearchResponse[];
  billingAccounts?: BillingAccountSearchResponse[];
  pendingActionsResponse: MoveContactPendingActionsResponse;
}

export const contactLoader = async ({ params, request }: LoaderFunctionArgs): Promise<ContactLoaderData> => {
  const contactMasterId = params.contactMasterId;
  if (!contactMasterId) {
    throw new Error('missing contactMasterId');
  }
  const mdmId = requireMdmIdFromRequest(request);
  const contact = await fetchContact({
    contactMasterId: contactMasterId!,
    mdmId,
  });
  const contactId = contact.contactId;
  if (!contactId) {
    throw new Error('missing contactId');
  }
  const [
    deviceResponse,
    voiceResponse,
    broadbandResponse,
    serviceResponse,
    billingAccountResponse,
    pendingActionsResponse,
  ] = await Promise.all([
    fetchSubscriptions(buildSubscriptionsQuery(request, SubscriptionCategory.DEVICE, undefined, contactId), mdmId),
    fetchSubscriptions(buildSubscriptionsQuery(request, SubscriptionCategory.VOICE, undefined, contactId), mdmId),
    fetchSubscriptions(buildSubscriptionsQuery(request, SubscriptionCategory.BROADBAND, undefined, contactId), mdmId),
    fetchSubscriptions(buildSubscriptionsQuery(request, SubscriptionCategory.SERVICE, undefined, contactId), mdmId),
    fetchBillingAccounts(
      { billingContactId: contactId, useSearchService: true, sourceSystem: SourceSystem.SFDC },
      { mdmId }
    ),
    hasPendingActionsForContact(contactMasterId),
  ]);
  return {
    contact,
    deviceSubscriptions: deviceResponse.searchResults || [],
    voiceSubscriptions: voiceResponse.searchResults || [],
    broadbandSubscriptions: broadbandResponse.searchResults || [],
    serviceSubscriptions: serviceResponse.searchResults || [],
    billingAccounts: billingAccountResponse?.searchResults,
    pendingActionsResponse,
  };
};

export type MoveContactLoaderData = Omit<ContactLoaderData, 'billingAccounts' | 'serviceSubscriptions'>;

export const moveContactLoader = async ({ params, request }: LoaderFunctionArgs): Promise<MoveContactLoaderData> => {
  const contactMasterId = params.contactMasterId;
  if (!contactMasterId) {
    throw new Error('missing contactMasterId');
  }
  const mdmId = requireMdmIdFromRequest(request);
  const contact = await fetchContact({
    contactMasterId: contactMasterId!,
    mdmId,
  });
  const contactId = contact.contactId;
  if (!contactId) {
    throw new Error('missing contactId');
  }
  const [eppDeviceResponse, monthlyFeeDeviceResponse, voiceResponse, broadbandResponse, pendingActionsResponse] =
    await Promise.all([
      fetchSubscriptions(
        buildSubscriptionsQuery(request, undefined, [SubscriptionType.DEVICE], contactId, { eppFilter: 'All' }),
        mdmId
      ),
      fetchSubscriptions(
        buildSubscriptionsQuery(request, undefined, [SubscriptionType.DEVICE], contactId, { paymentType: 'Monthly' }),
        mdmId
      ),
      fetchSubscriptions(buildSubscriptionsQuery(request, undefined, [SubscriptionType.MOBILE], contactId), mdmId),
      fetchSubscriptions(
        buildSubscriptionsQuery(
          request,
          undefined,
          [SubscriptionType.BROADBAND, SubscriptionType.MOBILE_BROADBAND, SubscriptionType.MOBILE_M2M],
          contactId
        ),
        mdmId
      ),
      hasPendingActionsForContact(contactMasterId),
    ]);
  return {
    contact,
    deviceSubscriptions: [
      ...(eppDeviceResponse.searchResults || []),
      ...(monthlyFeeDeviceResponse.searchResults || []),
    ],
    voiceSubscriptions: voiceResponse.searchResults || [],
    broadbandSubscriptions: broadbandResponse.searchResults || [],
    pendingActionsResponse,
  };
};

export const deviceSubscriptionsLoader = async ({ request }: LoaderFunctionArgs) => {
  const query = buildSubscriptionsQuery(request, SubscriptionCategory.DEVICE);
  const mdmId = getMdmIdFromRequest(request);
  return {
    subscriptions: await fetchSubscriptions(query, mdmId),
    aggregations: await fetchSubscriptionAggregates(SubscriptionCategory.DEVICE, request, mdmId),
  };
};

export const deviceSubscriptionLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<DeviceSubscriptionLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [billingAccountsResponse, companyInfo, contactsResponse, pendingSubscriptionActions, subscription] =
    await Promise.all([
      fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
      fetchCompanyInfo(mdmId),
      fetchContacts({ offset: 0 }, { mdmId }),
      fetchPendingSubscriptionActions(mdmId),
      fetchSubscription(SubscriptionCategory.DEVICE, params.subscriptionId, mdmId),
    ]);

  return {
    billingAccounts: billingAccountsResponse,
    companyInfo,
    contacts: contactsResponse.contacts || [],
    pendingSubscriptionActions,
    subscription,
  };
};

export const deviceSubPostChangeRequestLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<DeviceSubPostChangeRequestLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchCompanyInfo(mdmId),
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.DEVICE, params.subscriptionId, mdmId),
  ]);
  return { companyInfo, pendingSubscriptionActions, subscription };
};

export const voiceSubscriptionsLoader = async ({ request }: LoaderFunctionArgs) => {
  const query = buildSubscriptionsQuery(request, SubscriptionCategory.VOICE);
  const mdmId = getMdmIdFromRequest(request);
  return {
    subscriptions: await fetchSubscriptions(query, mdmId),
    aggregations: await fetchSubscriptionAggregates(SubscriptionCategory.VOICE, request, mdmId),
  };
};

export const deviceAddOnLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<SubscriptionAddOnLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [pendingSubscriptionActions, subscription] = await Promise.all([
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.DEVICE, params.subscriptionId, mdmId),
  ]);
  const addOn = (subscription?.details?.selectedAddOns || []).find(it => it.addOnCode === params.addonId);
  if (!addOn) {
    throw new Response('Subscription addon not found', { status: 404 });
  }
  return { addOn, pendingSubscriptionActions, subscription };
};

export const mobileSubAddOnLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<SubscriptionAddOnLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [pendingSubscriptionActions, subscription] = await Promise.all([
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, mdmId),
  ]);
  const addOn = (subscription?.details?.selectedAddOns || []).find(it => it.addOnCode === params.addonId);
  if (!addOn) {
    throw new Response('Subscription addon not found', { status: 404 });
  }
  return { addOn, pendingSubscriptionActions, subscription };
};

export interface ServiceSubscriptionLoaderResponse {
  companyInfo: CompanyInfoResponse;
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
  billingAccounts: BillingAccountsResponse;
}

export const serviceSubscriptionLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<ServiceSubscriptionLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription, billingAccounts] = await Promise.all([
    fetchCompanyInfo(mdmId),
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.SERVICE, params.subscriptionId, mdmId),
    fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
  ]);
  return {
    companyInfo,
    pendingSubscriptionActions,
    subscription,
    billingAccounts,
  };
};

export const dnsSubscriptionsLoader = async ({ request }: LoaderFunctionArgs) => {
  const query = buildSubscriptionsQuery(request, SubscriptionCategory.DOMAIN);
  return fetchSubscriptions(query);
};

export const contactsLoader = ({ request }: LoaderFunctionArgs) => {
  const { sort, offset, itemsPerPage, ...rest } = getSearchParams(request);
  const mdmId = getMdmIdFromRequest(request);
  return fetchContacts(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      sort: getContactSort(sort),
      ...rest,
    }),
    { mdmId }
  );
};

export const subscriptionActionLoader = async ({
  params,
  request,
}: LoaderFunctionArgs): Promise<SubscriptionActionsResponse> => {
  if (params.requestId === undefined) {
    throw new Error('missing requestId');
  }
  const mdmId = requireMdmIdFromRequest(request);
  return fetchSubscriptionAction(params.requestId, mdmId);
};

export const subscriptionActionsLoader = ({ request }: LoaderFunctionArgs) => {
  const { offset, itemsPerPage, ...rest } = getSearchParams(request);
  const mdmId = getMdmIdFromRequest(request);
  return fetchSubscriptionActions(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      offset: Number(offset),
      limit: itemsPerPage,
      ...rest,
    }),
    mdmId
  );
};

export const onlineModelsForBasketItemsLoader = async () => {
  const cartJson: ShoppingBasketType = parseBasketJson(getShoppingBasketFromLocalStorage());

  if (cartJson.items && cartJson.items.length > 0) {
    const onlineModelCodeGuids = [...new Set(cartJson.items.map(cartItem => cartItem.guid))];
    return await fetchOnlineModels(onlineModelCodeGuids);
  }
  // no need to load anything if cart is empty..
  return {};
};

export interface PublicM2MBroadbandLoaderData {
  accessoriesHeaders: OnlineModelsResponse;
  m2mSubscriptions: OnlineModel[];
}

export const publicM2MBroadbandPageLoader = async (): Promise<PublicM2MBroadbandLoaderData> => {
  const m2mOnlineModelCodes = [...mobileM2MSubscriptionCards4G, ...mobileM2MSubscriptionCards5G].map(
    sc => sc.salesProduct.onlineModelCode
  );
  const [accessoriesHeaders, m2mSubscriptions] = await Promise.all([
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.ACCESSORIES),
    fetchOnlineModels(m2mOnlineModelCodes),
  ]);
  return {
    accessoriesHeaders,
    m2mSubscriptions,
  };
};

export const catalogProductsLoader = async ({ request }: LoaderFunctionArgs) => {
  const mdmId = requireMdmIdFromRequest(request);
  const [phones, accessories, tablets, computers, networkEquipment, discountedPrices] = await Promise.all([
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.PHONE),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.ACCESSORIES),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.TABLET),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.COMPUTERS),
    fetchCatalogOnlineModelHeaders(OnlineModelCategory.NETWORK_EQUIPMENT),
    fetchDiscountedPrices(mdmId),
  ]);
  return {
    onlineModels: {
      phones,
      accessories,
      tablets,
      computers,
      networkEquipment,
    },
    discountedPrices,
  };
};

export const virtualCatalogsLoader = ({ request }: LoaderFunctionArgs) => {
  const { params, offset, sort, itemsPerPage, ...rest } = getSearchParams(request);
  const mdmId = getMdmIdFromRequest(request);
  return fetchVirtualCatalogs(
    mergeObjects(defaultRequest, {
      useSearchService: true,
      productType: params.get('productType'),
      contractPeriod: params.get('contractPeriod'),
      status: params.get('status'),
      limit: itemsPerPage,
      offset: Number(offset),
      sort: sort || 'publishedOrDraftLastModified',
      ...rest,
    }),
    mdmId
  );
};

export interface VirtualCatalogDetailsLoaderData {
  billingAccounts: BillingAccountsResponse;
  catalog: VirtualCatalog;
  catalogHeaders: CatalogSearchResponse[];
  discountedPrices: DiscountedPricesResponse;
  onlineModelHeaders: OnlineModelHeadersState;
  secondaryAccounts: SecondaryAccount[];
}

export const virtualCatalogDetailsLoader = async ({
  request,
  params,
}: LoaderFunctionArgs): Promise<VirtualCatalogDetailsLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  if (params.catalogCode === undefined) {
    throw new Error('missing params.catalogCode');
  }
  const [billingAccounts, catalogResponse, catalogHeadersResponse, discountedPrices, secondaryAccounts] =
    await Promise.all([
      fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
      fetchVirtualCatalog(params.catalogCode!, mdmId),
      fetchVirtualCatalogs(
        mergeObjects(defaultRequest, {
          useSearchService: true,
        })
      ),
      fetchDiscountedPrices(mdmId),
      fetchSecondaryAccounts(mdmId),
    ]);
  const catalog = catalogResponse.items[0];
  const catalogHeaders = catalogHeadersResponse.searchResults || [];
  const allNeededProductCategories = collectProductCategoriesOfVirtualCatalog(catalog);
  const onlineModels = await Promise.all(
    allNeededProductCategories.map(category => fetchCatalogOnlineModelHeaders(category))
  );
  const onlineModelsState: OnlineModelHeadersState = { items: {} };
  allNeededProductCategories.map((category, i) => {
    onlineModelsState.items![category] = onlineModels[i].headers || [];
  });
  return {
    billingAccounts,
    catalog,
    catalogHeaders,
    discountedPrices,
    onlineModelHeaders: onlineModelsState,
    secondaryAccounts,
  };
};

export const chatHistoryLoader = () => {
  const sessionId = getAiChatSessionId();
  return sessionId ? getMessagesFromChatHistory(sessionId) : Promise.resolve({ sessionId: '', messages: [] });
};

export const invoiceLoader = async ({ params, request }: LoaderFunctionArgs): Promise<InvoiceLoaderResponse> => {
  if (params.invoiceId === undefined) {
    throw new Error('missing invoiceId');
  }
  // Use getInvoicePaymentMdmId() as fallback because after returning from a payment the redirect URL temporarily
  // lacks mdmId. The mdmId is added in InvoicePath after handling the payment return flow.
  const mdmId = getMdmIdFromRequest(request) || getInvoicePaymentMdmId();
  if (!mdmId) {
    throw new Error('missing mdmId');
  }

  const [invoicesResponse, billChannels, billingAccountsResponse] = await Promise.all([
    fetchInvoice(params.invoiceId, mdmId),
    fetchBillChannels(),
    fetchBillingAccounts({ offset: 0, useSearchService: true }, { mdmId }),
  ]);
  const invoice = invoicesResponse?.invoices?.[0] as Invoice;
  if (!invoice) {
    throw new Response('Invoice not found', { status: 404 });
  }
  const openSupportCases = await fetchOpenSupportCases({ search: invoice.invoiceDisplayId }, mdmId);
  const mappedSupportCases =
    openSupportCases?.searchResults?.map((res: SupportCasesSearchResponse) => res.result) || [];
  const billingAccount =
    billingAccountsResponse?.billingAccounts?.find(ba => ba.billingAccountId === invoice.billingAccountId) ??
    invoicesResponse.billingAccounts?.find(ba => ba.billingAccountId === invoice.billingAccountId);
  return {
    invoice,
    billChannels: billChannels || [],
    openSupportCases: mappedSupportCases,
    billingAccount: billingAccount,
  };
};

export interface InvoicesLoaderData {
  invoices: InvoicesResponse;
  supportCases: SupportCasesResponse;
}

export const invoicesLoader = async ({ request }: LoaderFunctionArgs): Promise<InvoicesLoaderData> => {
  const { itemsPerPage, offset = 0, sort = 'due', order = 'desc', search } = getSearchParams(request);
  const mdmId = getMdmIdFromRequest(request);
  return {
    invoices: await fetchInvoices(
      {
        useSearchService: true,
        search,
        order,
        limit: itemsPerPage,
        offset: Number(offset),
        sort,
      },
      mdmId
    ),
    supportCases: await fetchOpenSupportCases({ search, order: 'desc', offset: 0 }, mdmId),
  };
};

export interface DocumentsLoaderData {
  documents: InvoiceDocumentsResponse;
}

export const documentsLoader = async ({ request }: LoaderFunctionArgs): Promise<DocumentsLoaderData> => {
  const { itemsPerPage, offset = 0, sort = 'created', order = 'desc', search } = getSearchParams(request);
  const mdmId = getMdmIdFromRequest(request);
  return {
    documents: await fetchInvoiceDocuments(
      {
        useSearchService: true,
        offset: Number(offset),
        sort,
        order,
        search,
        limit: itemsPerPage,
      },
      mdmId
    ),
  };
};

export interface FixedBBRootLoaderData {
  onlineModels?: OnlineModel[];
  errors?: CommonError[];
}

export const fixedBBRootLoader = async () => {
  try {
    const onlineModels = await fetchOnlineModelsForCategory(OnlineModelCategory.FIXED_BROADBAND, false);
    const broadbandModel = await fetchOnlineModelForCode(ModelType.MobileBroadband);
    // This filtering is the same as in the onlineModelsReducer.ts line 50
    const filteredModel = getOnlineModelFilteringOutNonOfferAddOns(broadbandModel);
    const mergedModels = mergeArrays<OnlineModel>('onlineModelCode', 'lastModified', onlineModels.models, [
      filteredModel,
    ]);

    return {
      onlineModels: mergedModels,
    };
  } catch (error) {
    return {
      errors: convertToCommonErrors(error.message, error.status, error.json()),
    };
  }
};

export interface FixedBBCheckoutLoaderData {
  preLoadedAddOnRules: AddOnRule[];
  addOnRules: AddOnRulesResponse;
  mobilePbxAddOnRules: AddOnRulesResponse;
  nettiAddOnRules: AddOnRulesResponse;
  netti4GAddOnRules: AddOnRulesResponse;
  holidays: Date[];
  billChannels: BillChannel[];
}

export const fixedBBCheckoutLoader = async () => {
  const addOnRules = await fetchAddOnRules();
  const mobilePbxAddOnRules = await fetchAddOnRules(SubscriptionType.MOBILE_PBX);
  const nettiAddOnRules = await fetchAddOnRules(AddOnRulesSubscriptionType.ELISA_NETTI);
  const netti4GAddOnRules = await fetchAddOnRules(AddOnRulesSubscriptionType.ELISA_NETTI_4G);
  const holidays = await fetchHolidays();
  const billChannels = await fetchBillChannels();

  return {
    preLoadedAddOnRules: createPreLoadedAddOnRules(nettiAddOnRules, netti4GAddOnRules),
    addOnRules,
    mobilePbxAddOnRules,
    nettiAddOnRules,
    netti4GAddOnRules,
    holidays: holidays.dates.map(date => new Date(date)),
    billChannels,
  };
};

export interface CheckoutRootLoaderData {
  holidays: Date[];
  models?: OnlineModel[];
}

export const checkoutRootLoader = async () => {
  const holidays = await fetchHolidays();
  const models = await onlineModelsForBasketItemsLoader();
  return {
    holidays: holidays.dates.map(date => new Date(date)),
    models: models,
  };
};

export interface OrderVoiceSubLoaderData {
  voiceModel: OnlineModel;
  addOnRules: AddOnRulesResponse;
}

export const orderVoiceSubLoader = async () => {
  const [addOnRules, onlineModel] = await Promise.all([fetchAddOnRules(), fetchOnlineModelForCode(ModelType.VoiceSME)]);

  return {
    voiceModel: getOnlineModelFilteringOutNonOfferAddOns(onlineModel),
    addOnRules,
  };
};

export interface OrderMobileBBSubLoaderData {
  addOnRules: AddOnRulesResponse;
  mobileBroadbandModel: OnlineModel;
  elisaNettiModel: OnlineModel;
}

export const orderMobileBBSubLoader = async () => {
  const addOnRulesBasic = await fetchAddOnRules();
  // Liikkuva WiFi addon rules are using this subscriptionType
  const addOnRulesNetti4G = await fetchAddOnRules(AddOnRulesSubscriptionType.ELISA_NETTI_4G);

  const onlineModel = await fetchOnlineModelForCode(ModelType.MobileBroadband);
  const nettiOnlineModel = await fetchOnlineModelForCode(ModelType.ElisaNetti);

  const combinedAddOnRules: AddOnRulesResponse = combineAddOnRulesResponses(addOnRulesBasic, addOnRulesNetti4G);

  return {
    addOnRules: combinedAddOnRules,
    mobileBroadbandModel: getOnlineModelFilteringOutNonOfferAddOns(onlineModel),
    elisaNettiModel: getOnlineModelFilteringOutNonOfferAddOns(nettiOnlineModel),
  };
};

export interface OrderM2MLoaderData {
  addOnRules: AddOnRulesResponse;
  m2mModel: OnlineModel;
}

export const orderM2MLoader = async () => {
  const [addOnRules, m2mModel] = await Promise.all([fetchAddOnRules(), fetchOnlineModelForCode(ModelType.MobileM2M)]);

  return {
    addOnRules,
    m2mModel: getOnlineModelFilteringOutNonOfferAddOns(m2mModel),
  };
};

export interface OrderSubscriptionCommonLoaderData {
  companyInfo: CompanyInfoResponse;
  onlineModels: OnlineModel[];
  contacts: ContactsResponse;
}

export const orderSubscriptionCommonLoader = async ({ request }: LoaderFunctionArgs) => {
  const mdmId = getMdmIdFromRequest(request) || getActiveAccountMasterId();
  if (!mdmId) {
    throw new Error('missing mdmId');
  }

  const [companyData, contacts] = await Promise.all([
    fetchCompanyInfoAndEnabledOnlineModels(mdmId),
    fetchContacts({ useSearchService: true, offset: 0 }, { mdmId }),
  ]);
  return {
    companyInfo: companyData.companyInfo,
    onlineModels: companyData.onlineModels.map(getOnlineModelFilteringOutNonOfferAddOns),
    contacts,
  };
};

export interface OrderSubscriptionConfigLoaderData {
  holidays: Date[];
  onlineModel: OnlineModel;
}

export const orderSubscriptionConfigLoader = async (_: LoaderFunctionArgs, modelType: string) => {
  const [holidays, onlineModel] = await Promise.all([fetchHolidays(), fetchOnlineModelForCode(modelType)]);

  return {
    holidays: holidays.dates.map(date => new Date(date)),
    onlineModel: getOnlineModelFilteringOutNonOfferAddOns(onlineModel),
  };
};

export interface OrderSubscriptionDeliveryOptionsLoaderData {
  billingAccounts: BillingAccountsResponse;
  billChannels: BillChannel[];
}

export const orderSubscriptionDeliveryOptionsLoader = async ({ request }: LoaderFunctionArgs) => {
  const mdmId = getMdmIdFromRequest(request) || getActiveAccountMasterId();
  if (!mdmId) {
    throw new Error('missing mdmId');
  }

  const [billingAccounts, billChannels] = await Promise.all([
    fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
    fetchBillChannels(),
  ]);

  return {
    billingAccounts,
    billChannels,
  };
};

export interface MobileSubscriptionLoaderResponse {
  addOnRules: AddOnRulesResponse;
  addOnRulesMobilePbx: AddOnRulesResponse;
  billingAccounts: BillingAccountsResponse;
  companyInfo: CompanyInfoResponse;
  contacts: Contact[];
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
  mobileIdContracts?: Contract[];
  onlineModels: OnlineModel[];
}

export const mobileSubscriptionLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<MobileSubscriptionLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [
    addOnRules,
    addOnRulesMobilePbx,
    billingAccountsResponse,
    companyInfo,
    contactsResponse,
    pendingSubscriptionActions,
    subscription,
    ringModel,
  ] = await Promise.all([
    fetchAddOnRules(),
    fetchAddOnRules(SubscriptionType.MOBILE_PBX),
    fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
    fetchCompanyInfo(mdmId),
    fetchContacts({ offset: 0 }, { mdmId }),
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, mdmId),
    fetchOnlineModelEffectivePriceForCode(ModelType.Ring, mdmId),
  ]);

  return {
    addOnRules,
    addOnRulesMobilePbx,
    billingAccounts: billingAccountsResponse,
    companyInfo,
    contacts: contactsResponse.contacts || [],
    pendingSubscriptionActions,
    subscription,
    onlineModels: [ringModel],
  };
};

export interface MobileSubAttachRingLoaderData {
  addOnRules: AddOnRulesResponse;
  companyInfo?: CompanyInfoState;
  onlineModel: OnlineModel;
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
}

export interface NumberRangeData {
  rangeId: string;
  allNumbers?: string[];
  displayedNumbers?: string[];
  numberCategory?: NumberRangeResponse.NumberCategoryEnum;
  errors?: CommonError[];
}

export interface MobileSubAttachVakioLoaderData {
  companyInfo?: CompanyInfoState;
  pendingSubscriptionActions: SubscriptionAction[];
  subscription: Subscription;
  numberRanges: NumberRangeData[];
}

export type MobileSubPostChangeRequestLoaderResponse = Pick<
  MobileSubscriptionLoaderResponse,
  'companyInfo' | 'pendingSubscriptionActions' | 'subscription'
>;

export const mobileSubPostChangeRequestLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<MobileSubPostChangeRequestLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchCompanyInfo(mdmId),
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, mdmId),
  ]);
  return {
    companyInfo,
    pendingSubscriptionActions,
    subscription,
  };
};

export const mobileSubAttachVakioLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<MobileSubAttachVakioLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchCompanyInfo(mdmId),
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, mdmId),
  ]);
  const numberRangeIds = (companyInfo.pbxSolutions ?? [])
    .filter(solution => solution.subscriptionType === SubscriptionType.MOBILE_PBX_LITE)
    .flatMap(solution => solution.details?.mobilePbx?.numberRanges)
    .filter(numberRange => numberRange !== undefined)
    .map(numberRange => numberRange.rangeId);

  const numberRanges = await Promise.all(numberRangeIds.map(async range => fetchNumberRange(range, mdmId)));
  return {
    companyInfo,
    pendingSubscriptionActions,
    subscription,
    numberRanges: numberRanges,
  };
};

export interface SubscriptionUpdateLoaderData {
  addOnRules: AddOnRulesResponse;
  companyInfo: CompanyInfoResponse;
  onlineModels: OnlineModel[];
  subscription: Subscription;
}

export const mobileSubUpdateLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<SubscriptionUpdateLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [addOnRules, companyInfo, subscription, voiceSMEModel, voiceModel, pakettiModel] = await Promise.all([
    fetchAddOnRules(),
    fetchCompanyInfo(mdmId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, mdmId),
    fetchOnlineModelForCode(ModelType.VoiceSME),
    fetchOnlineModelForCode(ModelType.Voice),
    fetchOnlineModelForCode(ModelType.Yrityspaketti),
  ]);
  return {
    addOnRules,
    companyInfo,
    onlineModels: [
      getOnlineModelFilteringOutNonOfferAddOns(voiceSMEModel),
      getOnlineModelFilteringOutNonOfferAddOns(voiceModel),
      getOnlineModelFilteringOutNonOfferAddOns(pakettiModel),
    ],
    subscription,
  };
};

export const broadbandSubUpdateLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<SubscriptionUpdateLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [addOnRules, companyInfo, subscription, mobileBroadbandModel] = await Promise.all([
    fetchAddOnRules(),
    fetchCompanyInfo(mdmId),
    fetchSubscription(SubscriptionCategory.BROADBAND, params.subscriptionId, mdmId),
    fetchOnlineModelForCode(ModelType.MobileBroadband),
  ]);
  return {
    addOnRules,
    companyInfo,
    onlineModels: [getOnlineModelFilteringOutNonOfferAddOns(mobileBroadbandModel)],
    subscription,
  };
};

export const broadbandSubUpdateM2MLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<SubscriptionUpdateLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [addOnRules, companyInfo, subscription, mobileBroadbandModel] = await Promise.all([
    fetchAddOnRules(),
    fetchCompanyInfo(mdmId),
    fetchSubscription(SubscriptionCategory.BROADBAND, params.subscriptionId, mdmId),
    fetchOnlineModelForCode(ModelType.MobileM2M),
  ]);
  return {
    addOnRules,
    companyInfo,
    onlineModels: [getOnlineModelFilteringOutNonOfferAddOns(mobileBroadbandModel)],
    subscription,
  };
};

export interface MobileSubUpdateConfirmationLoaderData {
  addOnRules: AddOnRulesResponse;
  addOnRulesMobilePbx: AddOnRulesResponse;
  pbxSolutions?: Subscription[];
}

export const mobileSubUpdateConfirmationLoader = async ({
  request,
}: LoaderFunctionArgs): Promise<MobileSubUpdateConfirmationLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [addOnRules, addOnRulesMobilePbx, companyInfo] = await Promise.all([
    fetchAddOnRules(),
    fetchAddOnRules(SubscriptionType.MOBILE_PBX),
    fetchCompanyInfo(mdmId),
  ]);
  return {
    addOnRules,
    addOnRulesMobilePbx,
    pbxSolutions: companyInfo.pbxSolutions,
  };
};

export const mobileSubAttachRingLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<MobileSubAttachRingLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [addOnRules, companyInfo, onlineModel, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchAddOnRules(SubscriptionType.MOBILE_PBX),
    fetchCompanyInfo(mdmId),
    fetchOnlineModelEffectivePriceForCode(ModelType.Ring, mdmId),
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.VOICE, params.subscriptionId, mdmId),
  ]);
  return {
    addOnRules,
    companyInfo,
    onlineModel,
    pendingSubscriptionActions,
    subscription,
  };
};

export const mfaDetailsLoader = async (): Promise<MfaDetailsResponse> => {
  return await fetchMfaDetails();
};

export interface CatalogSummaryLoaderData {
  billingAccounts: BillingAccountsResponse;
}

export const catalogSummaryLoader = async ({ request }: LoaderFunctionArgs): Promise<CatalogSummaryLoaderData> => {
  const mdmId = requireMdmIdFromRequest(request);
  return {
    billingAccounts: await fetchBillingAccounts({ sourceSystem: SourceSystem.SFDC, useSearchService: true }, { mdmId }),
  };
};

export type CompanyInfoLoaderData = CompanyInfoResponse & { mdmId: string };

export const companyInfoHomeLoader = async (): Promise<CompanyInfoLoaderData[]> => {
  const primaryMdmId = getPrimaryMdmId();
  if (!primaryMdmId) {
    throw new Response('Unauthorized', { status: 401 });
  }
  const primaryAccount = await fetchCompanyInfo(primaryMdmId);
  const secondaryAccounts: SecondaryAccount[] = await fetchSecondaryAccounts(primaryMdmId);
  if (secondaryAccounts.length === 0) {
    return [{ ...primaryAccount, mdmId: primaryMdmId }];
  }
  const secondaryAccountMdmIds = secondaryAccounts.map(sa => sa.accountMasterId);
  const otherAccounts = await Promise.all(secondaryAccountMdmIds.map(mdmId => fetchCompanyInfo(mdmId)));

  const mdmIds = [...secondaryAccountMdmIds, primaryMdmId];
  const allAccounts = [...otherAccounts, primaryAccount];
  return allAccounts.map((a, i) => ({ ...a, mdmId: mdmIds[i] }));
};

export type BroadbandSubPostChangeRequestLoaderResponse = MobileSubPostChangeRequestLoaderResponse;

export const broadbandSubPostChangeRequestLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<BroadbandSubPostChangeRequestLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [companyInfo, pendingSubscriptionActions, subscription] = await Promise.all([
    fetchCompanyInfo(mdmId),
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.BROADBAND, params.subscriptionId, mdmId),
  ]);
  return {
    companyInfo,
    pendingSubscriptionActions,
    subscription,
  };
};

export const broadbandSubAddOnLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<SubscriptionAddOnLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [pendingSubscriptionActions, subscription] = await Promise.all([
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.BROADBAND, params.subscriptionId, mdmId),
  ]);
  const addOn = (subscription?.details?.selectedAddOns || []).find(it => it.addOnCode === params.addonId);
  if (!addOn) {
    throw new Response('Subscription addon not found', { status: 404 });
  }
  return { addOn, pendingSubscriptionActions, subscription };
};

export type BroadbandSubscriptionLoaderResponse = Omit<MobileSubscriptionLoaderResponse, 'onlineModels'>;

export const broadbandSubscriptionLoader = async ({
  params,
  request,
}: SubscriptionLoaderArgs): Promise<BroadbandSubscriptionLoaderResponse> => {
  const mdmId = requireMdmIdFromRequest(request);
  const [
    addOnRules,
    addOnRulesMobilePbx,
    billingAccountsResponse,
    companyInfo,
    contactsResponse,
    pendingSubscriptionActions,
    subscription,
  ] = await Promise.all([
    fetchAddOnRules(),
    fetchAddOnRules(SubscriptionType.MOBILE_PBX),
    fetchBillingAccounts({ useSearchService: true, sourceSystem: SourceSystem.SFDC }, { mdmId }),
    fetchCompanyInfo(mdmId),
    fetchContacts({ offset: 0 }, { mdmId }),
    fetchPendingSubscriptionActions(mdmId),
    fetchSubscription(SubscriptionCategory.BROADBAND, params.subscriptionId, mdmId),
  ]);

  return {
    addOnRules,
    addOnRulesMobilePbx,
    billingAccounts: billingAccountsResponse,
    companyInfo,
    contacts: contactsResponse.contacts || [],
    pendingSubscriptionActions,
    subscription,
  };
};

export const mobileIdContractsLoader = async (): Promise<Contract[]> => {
  const res = await fetchMobileIdContracts();
  return res.contracts || [];
};

export const newMobileIdContractLoader = async ({ request }: LoaderFunctionArgs): Promise<Contract[]> => {
  const mdmId = getMdmIdFromRequest(request) || getPrimaryMdmId();
  if (!mdmId) {
    throw new Error('missing mdmId');
  }
  const res = await fetchMobileIdContractsByMdmId(mdmId);
  return res.contracts || [];
};

export const mobileIdContractLoader = async ({ params }: LoaderFunctionArgs): Promise<Contract> => {
  if (params.contractDisplayId === undefined) {
    throw new Error('missing contractDisplayId');
  }
  return await fetchMobileIdContract(params.contractDisplayId);
};

export interface ReportsLoaderData {
  reports: ReportItemResponse[];
  companyInfo: CompanyInfoResponse;
  secondaryAccounts: SecondaryAccount[];
}

export const reportsLoader = async (): Promise<ReportsLoaderData> => {
  const primaryMdmId = getPrimaryMdmId();
  if (!primaryMdmId) {
    throw new Response('Unauthorized', { status: 401 });
  }
  const [reports, companyInfo, secondaryAccounts] = await Promise.all([
    fetchReports(),
    fetchCompanyInfo(primaryMdmId),
    fetchSecondaryAccounts(primaryMdmId),
  ]);
  return {
    reports,
    companyInfo,
    secondaryAccounts,
  };
};

export const companyInvitesLoader = async (): Promise<OnboardingRequestsResponse> => {
  return await fetchOnboardingRequests();
};
