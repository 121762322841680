import { AddOnPurpose, SalesType } from '../../generated/api/models.js';
import { CampaignProductCardGrid } from './CampaignProductCardGrid.js';
import { CardSelectionHeader, getIconDataByOnlineModelType } from './CardSelectionHeader.js';
import { Disclaimer } from './Disclaimer.js';
import { Grid } from '../Grid/Grid.js';
import { LegacyOffersSelection } from './LegacyOffersSelection.js';
import { Loading } from '../Loading';
import { MinuteBasedSubscriptions } from './MinuteBasedSubscriptions.js';
import { MinuteOfferType } from './minuteBasedSubscriptionUtil.js';
import { MobileBroadbandAlternativesSummary } from './MobileBroadbandAlternativesSummary';
import { ModelType } from '../../common/enums.js';
import { OffersNotAvailable } from './OffersNotAvailable.js';
import { SubscriptionCardsByModelType } from './SubscriptionCardsByModelType.js';
import { SubscriptionSelectionFooter } from './SubscriptionSelectionFooter.js';
import { SubscriptionSelectionHeader } from './SubscriptionSelectionHeader';
import { Voucher } from './Voucher.js';
import {
  commercialProductContent,
  mobileBroadbandFiveGContent,
  mobileVoiceContent,
} from './content/CommercialProductContent.js';
import { createRef, useEffect, useState } from 'react';
import { deepEqual, isDefined } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { eurosToCents } from '../../common/utils/priceUtils.js';
import { findActiveAddonAssociation, newAddon } from '../../common/utils/addOnUtils.js';
import { getAddToCartAnalyticsEvent, getBeginCheckoutAnalyticsEvent } from '../../common/utils/analyticsUtils';
import { getAddedAddOns } from '../SubscriptionDetails/addOnDependencyUtils.js';
import { getCampaignPromotion, getOfferCampaignAssociationAndPromotion } from '../../common/utils/campaignUtils.js';
import {
  getLegacyOffersForDropdownDisplay,
  getSubscriptionCardByLegacyOffer,
  getSubscriptionCardStaticContent,
  hideNewSubscriptionCards,
  isFixedPeriodActive,
  isMbbModel,
  isVoiceModel,
} from './OrderSubscriptionSelectionUtils';
import { getMobileM2MCardContents4G, getMobileM2MCardContents5G } from './content/LaitenettiSubscriptionCardContent';
import { getOfferCardContent } from './CampaignProductCard.js';
import { getSubscriptionCardContent } from './subscriptionProductCardUtils.js';
import { googleEcommerceImpressionsPushed, googleEcommerceProductClick } from '../../selfservice/actions';
import { paths } from '../../common/constants/pathVariables.js';
import { pushToDataLayer } from '../../common/analytics.js';
import { setHistoryState } from '../../common/utils/stateUtils.js';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import type {
  AddOnRulesResponse,
  CampaignAssociation,
  CampaignPromotion,
  CommercialProduct,
  Offer,
  OnlineModel,
  Subscription,
} from '../../generated/api/models.js';
import type { AddedAddon } from '../../common/types/addon.js';
import type { AssociationRecord } from '@onlinefirst/cloudsense-add-on-dependency-engine';
import type { EcommerceItem } from '../../common/analytics.js';
import type { MinuteSubscription } from './minuteBasedSubscriptionUtil.js';
import type { OfferPerGroup } from './subscriptionProductCardUtils.js';
import type { ProductContent, ProductContentWithAddonCode } from './content/CommercialProductContent.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubCardProps } from '../SubscriptionCard/SubscriptionCard.js';
import type {
  SubCardWithAnalyticsV4Props,
  SubCardWithImpressionProps,
} from '../SubscriptionCards/subscriptionCardsAnalyticsUtils.js';

import './OrderSubscriptionSelection.scss';

export interface OfferCard {
  campaignAssociation: CampaignAssociation | undefined;
  campaignPromotion: CampaignPromotion | undefined;
  content: ProductContent | ProductContentWithAddonCode;
  offer: Offer;
  onlineModel: OnlineModel;
  voucherInUse: boolean;
  voucher?: string;
}

export interface OrderSubscriptionSelectionProps {
  minuteModels?: OnlineModel[];
  onlineModelType: ModelType;
  isChangeOffer?: boolean;
  subscription?: Subscription;
  onlineModels?: OnlineModel[];
  addOnRules?: AddOnRulesResponse;
  toggles?: string[];
}

export const getOnlineModel = (
  modelType: ModelType,
  addonAssociations: ReadonlyArray<AssociationRecord> = [],
  isChangeOffer: boolean,
  onlineModels?: Readonly<OnlineModel[]>
): OnlineModel | undefined => {
  const onlineModel = onlineModels?.find(item => item.onlineModelCode === modelType);
  if (modelType === ModelType.MobileM2M && onlineModel) {
    // Laitenetti has 2 online-models, 1st for 4G where 1 offer is used to create 3, and 2nd for 5G that's used as 1. 2 to 4 in total.
    const content = [getMobileM2MCardContents4G(isChangeOffer), getMobileM2MCardContents5G(isChangeOffer)];
    const offers = content.flatMap((item, index) =>
      item
        .map(product => {
          const addonAssoc = findActiveAddonAssociation(addonAssociations, product.selectedAddon!, 'Datapaketit');
          if (!addonAssoc) {
            return undefined;
          }

          const addon = newAddon(addonAssoc, 'Internet');
          const offer = onlineModel!.offers[index];
          const cp = offer?.commercialProducts[0];
          const newOffer: Offer = {
            ...offer,
            offerName: addon.addOnProductName,
            commercialProducts: [
              {
                ...cp,
                commercialProductName: addon.addOnProductName,
                associatedAddOns: [...(cp?.associatedAddOns || []), addon],
                addOnAssociations: [
                  ...(cp?.addOnAssociations || []),
                  {
                    addOnAssociationId: addonAssoc.id,
                    addOnCode: addonAssoc.cspmb__add_on_price_item__r.guid__c,
                    addOnAssociationCode: addonAssoc.guid__c,
                    addOnPurpose: AddOnPurpose.MANDATORY,
                    display: false,
                  },
                ],
              },
            ],
          };
          return newOffer;
        })
        .filter(isDefined)
    );

    // The mangled OnlineModel we return below contains original `addOns` field, even though we've added new offers,
    // which may contain new addons as well. We don't bother to add those as they serve no purpose.
    return {
      ...onlineModel!,
      offers,
    };
  } else {
    return onlineModel;
  }
};

export const getProductInfoPath = (modelType: ModelType) => {
  switch (modelType) {
    case ModelType.MobileBroadband:
      return paths.PS_BROADBAND_SUBSCRIPTION_PRODUCT_INFO;
    case ModelType.MobileM2M:
      return paths.PS_BROADBAND_SUBSCRIPTION_NEW_M2M_PRODUCT_INFO;
    case ModelType.Voice:
    case ModelType.VoiceSME:
      return paths.PS_MOBILE_SUBSCRIPTION_NEW_PRODUCT_INFO;
    default:
      throw new Error(`Unknown model type: ${modelType}, cannot determine product info path`);
  }
};

const getAddOns = (
  addOnCodes: string[],
  selectedOffer: Readonly<Offer>,
  addOnAssociations?: AssociationRecord[]
): AddedAddon[] => {
  // the add-on-rules object fields, since it is not typed (yet)...
  const addOnPriceItem = 'cspmb__add_on_price_item__r';
  const guid = 'guid__c';
  const priceItem = 'cspmb__price_item__r';
  const priceItemDescription = 'cspmb__add_on_price_item_description__c';
  const recurringCharge = 'cspmb__recurring_charge__c';
  const oneOffCharge = 'cspmb__one_off_charge__c';
  const theAddOns: AddedAddon[] = [];
  if (addOnAssociations) {
    addOnCodes.forEach(addOnCode => {
      if (addOnCode) {
        const addOn = addOnAssociations?.find(
          aos =>
            aos[addOnPriceItem] &&
            aos[addOnPriceItem][guid] === addOnCode &&
            aos[priceItem] &&
            aos[priceItem][guid] === selectedOffer.commercialProducts[0].commercialProductCode
        );

        if (addOn) {
          const addOnObj: AddedAddon = {
            addOnAssociationCode: addOn[guid],
            addOnCode: addOn[addOnPriceItem][guid],
            displayName: addOn[addOnPriceItem][priceItemDescription],
            monthlyPrice: eurosToCents(addOn[addOnPriceItem][recurringCharge]),
            oneTimePrice: addOn[addOnPriceItem][oneOffCharge]
              ? eurosToCents(addOn[addOnPriceItem][oneOffCharge])
              : undefined,
          };
          theAddOns.push(addOnObj);
        }
      }
    });
  }
  return theAddOns;
};

const createEcommerceItemFromProduct = (
  commercialProduct: Readonly<CommercialProduct>,
  offer: Offer,
  isVoice: boolean,
  isMinuteBased: boolean,
  index?: number
): EcommerceItem => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_brand: 'Elisa',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_category: commercialProduct.productSubType || commercialProduct.productType,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_id: offer.offerCode,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_list_name: `${isVoice ? 'Voice' : 'Mobile Broadband'}: ${
    isMinuteBased ? 'Minute Based ' : ''
  }Subscription Selection`,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_name: commercialProduct.commercialProductName,
  // position might change as the models are loaded separately, so let's not send that
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_variant: commercialProduct.commercialProductCode,
  index: index,
});

const groupSubsByGroupHeader = (subs: (SubCardProps | SubCardWithImpressionProps | SubCardWithAnalyticsV4Props)[]) => {
  return Array.from(
    subs.reduce((map, item) => {
      const group = item.groupHeader || '';
      if (!map.has(group)) {
        map.set(group, []);
      }
      map.get(group)?.push(item);
      return map;
    }, new Map<string, typeof subs>())
  ).map(([_, value]) => value);
};

// TODO move pure functions to outside the component?
export const OrderSubscriptionSelection = (props: Readonly<OrderSubscriptionSelectionProps>) => {
  const {
    onlineModelType,
    minuteModels,
    onlineModels,
    addOnRules,
    subscription,
    toggles,
    isChangeOffer = false,
  } = props;
  const { state, search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore<State>();
  const imagesBaseUrl = store.getState().config.imagesBaseUrl;
  const containerDivRef = createRef<HTMLDivElement>();
  const {
    campaignsFromVoucher = [],
    campaignContextsFromVoucher = [],
    voucher,
  } = useSelector((reduxState: State) => {
    const campaigns = reduxState.campaigns;
    return {
      campaignsFromVoucher: campaigns?.campaigns,
      campaignContextsFromVoucher: campaigns?.campaignContexts,
      voucher: campaigns?.voucher,
    };
  }, deepEqual);
  const addOnAssociations = addOnRules?.associations?.[0] as AssociationRecord[];
  const foundOnlineModel = getOnlineModel(onlineModelType, addOnAssociations, isChangeOffer, onlineModels);
  const [showAllProducts, setShowAllProducts] = useState(true);

  // TODO see if it's feasible to not use useState at all, i.e. move all of this to location.state
  const [amount, setAmount] = useState<number>(state?.amount ?? 1);
  const [minuteSubObj, setMinuteSubObj] = useState<MinuteSubscription>(state?.minuteSubObj ?? {});
  const [selectedAddOns, setSelectedAddOns] = useState<AddedAddon[]>(state?.selectedAddOns ?? []);
  const [selectedCampaignAssociation, setSelectedCampaignAssociation] = useState<CampaignAssociation | undefined>(
    state?.selectedCampaignAssociation
  );
  const [selectedProduct, setSelectedProduct] = useState<Offer | undefined>(state?.selectedProduct);
  const [selectedVoucher, setSelectedVoucher] = useState<string | undefined>(state?.selectedVoucher);
  const [selectedAddonCode, setSelectedAddonCode] = useState<string | undefined>();

  const nettiModel = onlineModels?.find(omo => omo.onlineModelCode === ModelType.ElisaNetti);

  const campaignPromotions = [
    ...campaignsFromVoucher,
    ...(foundOnlineModel?.campaigns || []),
    ...(nettiModel?.campaigns || []),
  ];
  const offerCampaignAssociationAndPromotion = selectedProduct
    ? getOfferCampaignAssociationAndPromotion(
        selectedProduct,
        isChangeOffer ? SalesType.CHANGE_OFFER : SalesType.NEW_SALE,
        campaignContextsFromVoucher,
        voucher,
        campaignPromotions
      )
    : undefined;

  useEffect(() => {
    if (!selectedProduct && foundOnlineModel) {
      const productFromOnlineModel = foundOnlineModel.offers.find(
        offer =>
          commercialProductContent().find(
            content => content.isDefaultSelected === true && content.offerCodes.includes(offer.offerCode)
          ) !== undefined
      );
      if (productFromOnlineModel) {
        setSelectedProduct(productFromOnlineModel);
      }
    }
  }, [selectedProduct, foundOnlineModel]);

  // Uses dispatch, so can't be moved outside the component as-is
  const handleProductSeen = (offers: ReadonlyArray<Offer>, isMinuteBased: boolean) => {
    if (!offers.length) {
      return;
    }
    const isVoice = onlineModelType === ModelType.Voice;
    dispatch(
      googleEcommerceImpressionsPushed(
        offers.map(offer => {
          const commercialProduct = offer.commercialProducts[0];
          return createEcommerceItemFromProduct(commercialProduct, offer, isVoice, isMinuteBased);
        })
      )
    );
  };

  // Uses onlineModelType, so can't be moved outside the component as-is
  const sendOnSelectProduct = (offer: Readonly<Offer>, minuteBased: boolean, index: number) => {
    const commercialProduct = offer.commercialProducts[0];
    const isVoice = isVoiceModel(onlineModelType);
    // at its cleanest this could be ('Elisa', model, product, listName, index)
    const productData = createEcommerceItemFromProduct(commercialProduct, offer, isVoice, minuteBased, index);
    dispatch(googleEcommerceProductClick([productData]));
  };

  const handleSelectProduct = (offerCard: Readonly<OfferCard>) => {
    setMinuteSubObj({});
    setSelectedAddOns(
      getAddOns(
        'addOnCode' in offerCard.content ? [offerCard.content.addOnCode] : [],
        offerCard.offer,
        addOnAssociations
      )
    );
    setSelectedProduct(offerCard.offer);
    setSelectedCampaignAssociation(offerCard.campaignAssociation);
    setSelectedVoucher(offerCard.voucher);

    const isVoice = onlineModelType === ModelType.Voice;
    sendOnSelectProduct(
      offerCard.offer,
      false,
      (isVoice ? mobileVoiceContent() : mobileBroadbandFiveGContent()).findIndex(pc =>
        pc.offerCodes.includes(offerCard.offer.offerCode)
      )
    );
  };

  const handleSubscriptionCardSelection = (subCard: SubCardWithAnalyticsV4Props) => {
    setMinuteSubObj({});
    const includedAddOns: string[] =
      // The 'as string' type assertion is safe here because we filtered out undefined guids
      subCard.services?.filter(s => s.isIncluded && s.guid !== undefined).map(s => s.guid as string) ?? [];
    setSelectedAddOns(getAddedAddOns(includedAddOns, subCard.offer, addOnAssociations));
    setSelectedProduct(subCard.offer);
    setSelectedAddonCode(subCard.selectedAddon);
    setSelectedCampaignAssociation(subCard.campaignAssociation);
    setSelectedVoucher(subCard.voucher);
  };

  const handleLegacyOfferSelection = (offer: Offer, campaign?: CampaignAssociation) => {
    setMinuteSubObj({});
    // Some legacy voice subscriptions should use the same default addons as new same speed subscriptions using cards.
    // The default addons are defined in the hard coded card data. Check if there is a card mapped, then get and set the needed addons.
    const subscriptionCard = getSubscriptionCardByLegacyOffer(offer.offerCode);
    if (subscriptionCard) {
      const includedAddOns: string[] =
        // The 'as string' type assertion is safe here because we filtered out undefined guids
        subscriptionCard.services?.filter(s => s.isIncluded && s.guid !== undefined).map(s => s.guid as string) ?? [];
      setSelectedAddOns(getAddedAddOns(includedAddOns, offer, addOnAssociations));
    } else {
      setSelectedAddOns([]);
    }
    setSelectedProduct(offer);
    setSelectedCampaignAssociation(campaign);
    setSelectedVoucher(undefined);
  };

  // This mutates minuteSubObj, but that's ok as it's only in our state.
  const handleSelectMinuteBasedProduct = (
    product: MinuteSubscription,
    models: ReadonlyArray<OnlineModel> | undefined
  ) => {
    // TODO flow-wise, it's possible that we clear these
    // without calling setMinuteSubObj. Not sure what that does.
    if (product.offer !== selectedProduct?.offerCode) {
      // clear previous selections when switching offer
      product.dataPackage = undefined;
      product.smsPackage = undefined;
    }
    if (models && addOnRules) {
      // find model in all offers
      const allOffers = models.reduce((arr, elem) => [...arr, ...elem.offers], []);
      const selectedOffer = allOffers.find(offer => offer.offerCode === product.offer);

      if (selectedOffer) {
        setMinuteSubObj(product);
        setSelectedProduct(selectedOffer);
        sendOnSelectProduct(selectedOffer, true, allOffers.indexOf(selectedOffer));
      }
      // handle user selected addons
      if (selectedOffer) {
        const addOnIds: string[] = [
          ...(product.dataPackage ? product.dataPackage.split(' ') : []),
          ...(product.smsPackage ? product.smsPackage.split(' ') : []),
        ];

        if (addOnIds && addOnIds.length > 0) {
          const addOnsToState: AddedAddon[] = getAddOns(addOnIds, selectedOffer, addOnAssociations);
          setSelectedAddOns(addOnsToState);
        } else {
          setSelectedAddOns([]);
        }
      } else {
        setSelectedProduct(undefined);
      }
    }
  };

  const handleAmountChanged = (value: number) => {
    setAmount(value);
  };

  const handleSubmit = () => {
    if (amount > 0 && selectedProduct) {
      const offerCampaignAssociation = offerCampaignAssociationAndPromotion?.campaignAssociation;
      let campaignDescription: string | undefined;
      let campaignName: string | undefined;
      if (offerCampaignAssociation) {
        const campaignPromotion = getCampaignPromotion(
          campaignPromotions,
          offerCampaignAssociation.campaignPromotionCode
        );
        campaignDescription = campaignPromotion?.description;
        campaignName = campaignPromotion?.name;
      }
      pushToDataLayer(
        // All products are the same, we can just pick the first in the array
        getAddToCartAnalyticsEvent(
          foundOnlineModel!.onlineModelName,
          selectedProduct,
          amount,
          selectedAddOns,
          voucher,
          offerCampaignAssociation
        )
      );

      // In this case the events are basically duplicates
      pushToDataLayer(
        getBeginCheckoutAnalyticsEvent(
          foundOnlineModel!.onlineModelName,
          selectedProduct,
          amount,
          selectedAddOns,
          voucher,
          offerCampaignAssociation
        )
      );

      setHistoryState({
        amount,
        minuteSubObj,
        selectedAddOns,
        selectedProduct,
        selectedCampaignAssociation,
        selectedVoucher,
      });
      navigate(getProductInfoPath(props.onlineModelType) + search, {
        state: {
          selectedOffer: {
            offer: selectedProduct,
            onlineModelCode: foundOnlineModel?.onlineModelCode,
            onlineModelName: foundOnlineModel?.onlineModelName,
            // Basically creates <amount> copies of the same CP
            selectedCommercialProducts: Array.from(Array(amount).keys()).map(() => ({
              // one cp per offer assumed for now
              addedAddOns: selectedAddOns || undefined, // user selected addon(s)
              commercialProduct: selectedProduct.commercialProducts[0],
              purposeOfUseOrContact: {}, // These are set empty if returning from order flow...
              campaignDescription,
              campaignName,
              selectedCampaignAssociation: offerCampaignAssociation,
            })),
            selectedCampaignAssociation: offerCampaignAssociation,
            voucher: selectedVoucher,
          },
          addOns: foundOnlineModel?.addOns,
        },
      });
    }
  };

  const isProductSelected = (): boolean => {
    if (
      selectedProduct?.offerCode === MinuteOfferType.VoiceYrityspuhe ||
      selectedProduct?.offerCode === MinuteOfferType.VoiceYrityspuheEU
    ) {
      // both packages must be selected to continue...
      return Boolean(minuteSubObj.dataPackage) && Boolean(minuteSubObj.smsPackage);
    }
    return Boolean(selectedProduct);
  };

  const selectedCommercialProduct: CommercialProduct | undefined = selectedProduct?.commercialProducts[0];

  // Get content based on the onlineModelType
  const subscriptionCardContent = getSubscriptionCardStaticContent(onlineModelType, isChangeOffer);

  const [selectedOffersPerGroup, setSelectedOffersPerGroup] = useState<OfferPerGroup[]>(
    subscriptionCardContent.map(subscriptionCard => ({
      groupName: subscriptionCard.cardName ?? '',
      offerCode: subscriptionCard.selectedOffer ?? '',
      addonCode: subscriptionCard.selectedAddon ?? '',
    }))
  );

  const hideOldCards = onlineModelType === ModelType.MobileBroadband;
  const hideNewCards = hideNewSubscriptionCards(toggles, onlineModelType);

  // Cards with old design
  const offerCardContents =
    !hideOldCards && foundOnlineModel
      ? getOfferCardContent(
          foundOnlineModel,
          subscription,
          isChangeOffer,
          {
            voucher,
            campaignContexts: campaignContextsFromVoucher,
          },
          showAllProducts,
          campaignPromotions,
          commercialProductContent()
        )
      : [];

  // Cards with new design
  const subscriptionCardContentAll: SubCardWithAnalyticsV4Props[] = hideNewCards
    ? []
    : getSubscriptionCardContent(
        isChangeOffer,
        {
          voucher,
          campaignContexts: campaignContextsFromVoucher,
        },
        campaignPromotions,
        subscriptionCardContent,
        selectedOffersPerGroup,
        setSelectedOffersPerGroup,
        selectedProduct,
        selectedAddonCode,
        foundOnlineModel,
        subscription,
        nettiModel?.offers
      );

  const subscriptionCardContents = hideNewCards ? [] : subscriptionCardContentAll;
  const group = groupSubsByGroupHeader(subscriptionCardContents);
  const groupedSubscriptions = group.length > 0 ? group : [[]];

  const fixedPeriodActiveFlag = isFixedPeriodActive(isChangeOffer, offerCardContents, subscriptionCardContentAll);

  const legacyOffers: Offer[] = getLegacyOffersForDropdownDisplay(
    fixedPeriodActiveFlag,
    onlineModelType,
    onlineModels || [],
    subscription?.commercialProductCode,
    toggles
  );

  return (
    <div ref={containerDivRef} id="of-order-subscription-selection" className="of-order-subscription-selection">
      <Grid>
        <div className={`${dsClass.MARGIN_TOP_6} of-product-selector-container ${dsClass.PADDING_BOTTOM_7}`}>
          <CardSelectionHeader data={getIconDataByOnlineModelType(onlineModelType)} />
          {foundOnlineModel ? (
            <>
              <Voucher
                onlineModelCode={foundOnlineModel.onlineModelCode}
                salesType={isChangeOffer ? SalesType.CHANGE_OFFER : SalesType.NEW_SALE}
              />
              {isMbbModel(onlineModelType) && !isChangeOffer && <MobileBroadbandAlternativesSummary />}
              {groupedSubscriptions.map((subs, index) => (
                <>
                  <SubscriptionSelectionHeader modelType={onlineModelType} groupHeader={subs[0]?.groupHeader} />
                  {index === 0 && (
                    <>
                      {fixedPeriodActiveFlag && <OffersNotAvailable />}
                      {offerCardContents.length > 0 && (
                        <CampaignProductCardGrid
                          offerCardContents={offerCardContents}
                          isChangeOffer={isChangeOffer}
                          handleProductSeen={handleProductSeen}
                          handleSelectOffer={handleSelectProduct}
                          selectedProduct={selectedProduct}
                          campaignPromotions={campaignPromotions}
                          imagesBaseUrl={imagesBaseUrl}
                        />
                      )}
                    </>
                  )}
                  {subs.length > 0 && (
                    <SubscriptionCardsByModelType
                      subscriptionCards={subs}
                      onlineModelType={onlineModelType}
                      handleSubscriptionCardSelection={handleSubscriptionCardSelection}
                      isChangeOffer={isChangeOffer}
                    />
                  )}
                </>
              ))}
            </>
          ) : (
            !hideNewCards && <Loading />
          )}

          <MinuteBasedSubscriptions
            handleSelectMinuteBasedProduct={handleSelectMinuteBasedProduct}
            minuteSubObj={minuteSubObj}
            addOnAssociations={addOnAssociations}
            minuteModels={minuteModels}
          />
          {legacyOffers.length > 0 && (
            <LegacyOffersSelection
              offers={legacyOffers}
              selectedOffer={selectedProduct}
              setSelectedOffer={handleLegacyOfferSelection}
              salesType={isChangeOffer ? SalesType.CHANGE_OFFER : SalesType.NEW_SALE}
              campaignContexts={campaignContextsFromVoucher}
              voucher={voucher}
              modelType={onlineModelType}
            />
          )}

          {(offerCardContents.length > 0 || subscriptionCardContents.length > 0) && (
            <Disclaimer handleShowAllProducts={() => setShowAllProducts(true)} showAllProducts={showAllProducts} />
          )}
        </div>
      </Grid>

      <SubscriptionSelectionFooter
        isProductSelected={isProductSelected()}
        isChangeOffer={isChangeOffer}
        selectedCommercialProduct={selectedCommercialProduct}
        amount={amount}
        selectedAddOns={selectedAddOns}
        selectedCampaignAssociation={offerCampaignAssociationAndPromotion?.campaignAssociation}
        handleAmountChanged={handleAmountChanged}
        handleSubmit={handleSubmit}
        subscription={subscription}
        campaignDescription={offerCampaignAssociationAndPromotion?.campaignPromotion?.description}
      />
    </div>
  );
};
