import * as CL from '@design-system/component-library';
import { type Context } from '../../OpenFormAnswers.js';
import { Input } from '../../../Input/Input.js';
import { OF } from '../../OpenForm.js';
import { OpenFormCheckbox } from '../../OpenFormComponents/OpenFormCheckbox.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { Table } from '../../OpenFormHooks/useTable.js';
import { concat, merge, onBlurPrice, onChangePrice } from '../../OpenFormUtils.js';
import { setValueOptions } from '../../OpenFormHooks/useOpenFormMethods.js';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import type { OpenFormChoice } from '../../../../generated/api/openFormChoice.js';
import type { OpenFormListColumn } from '../../../../generated/api/openFormListColumn.js';

export const OpenFormQuestionListOfObjects = ({
  disabled,
  required,
  label,
  name,
  headers,
  multiselect,
  choices,
  context,
  available,
}: {
  disabled: boolean;
  required: boolean;
  label: string;
  name: string;
  headers: OpenFormListColumn[];
  multiselect: boolean;
  choices: OpenFormChoice[];
  context: Context | undefined;
  available: Record<string, boolean>;
}) => {
  const { setValue } = useFormContext();
  const inactive = useCallback((guid: string) => disabled || !available[guid], [available, disabled]);
  const row = useMemo(() => context?.row ?? {}, [context?.row]);
  const set = useMemo(() => new Set(context?.choices ?? []), [context?.choices]);
  const sorted = useMemo(
    () =>
      choices
        .map(({ guid, values }) => ({ availability: available[guid], guid, values }))
        .sort(Table.compare('availability', 'desc')),
    [choices, available]
  );

  // If there are both `name` and `description__c` columns, we'll render them in a special way.
  const rowCursor = useCallback<
    (values: string[]) => {
      cells: [OpenFormListColumn, string][];
      description: (header: OpenFormListColumn) => JSX.Element | null;
    }
  >(
    values => {
      const index = headers.findIndex(header => header.name === OF.description__c);
      const filter = index > -1 && headers.some(header => header.name === 'name');
      return {
        cells: headers.flatMap((header, i) => (filter && i === index ? [] : [[header, values[i]]])),
        description: header => (filter && header.name === 'name' ? <div>{values[index]}</div> : null),
      };
    },
    [headers]
  );

  const rowClick = useCallback<(guid: string, values: string[]) => void>(
    (guid, values) => {
      if (!headers?.length || inactive(guid)) {
        return;
      } else if (set.has(guid)) {
        set.delete(guid) && delete row[guid];
      } else {
        !multiselect && set.forEach(item => set.delete(item) && delete row[item]);
        row[guid] = Object.assign(
          merge((header, i) => ({ [header.name]: values[i] }), ...headers),
          row[guid]
        );
        set.add(guid);
      }
      setValue(concat(name, 'row').join('.'), row);
      setValue(name, Array.from(set), setValueOptions);
    },
    [headers, multiselect, name, setValue, inactive, row, set]
  );

  const rowCells = useCallback<(guid: string, values: string[]) => JSX.Element[]>(
    (guid, values) => {
      const { cells, description } = rowCursor(values);
      return cells.map(([header, value]) =>
        (header.isReadOnly ?? true) ? (
          <div
            key={guid}
            className={classNames({
              ['of-openform__view__list-of-objects-table__cell-disabled']: inactive(guid),
              ['of-openform__view__list-of-objects-table__cell-readonly']: true,
            })}
          >
            <div>{value}</div>
            {description(header)}
          </div>
        ) : (
          <div
            key={guid}
            className={classNames({
              ['of-openform__view__list-of-objects-table__cell-disabled']: inactive(guid),
              ['of-openform__view__list-of-objects-table__cell-editable']: true,
            })}
          >
            {header.prefix || null}
            <Input
              disabled={inactive(guid)}
              required={required}
              value={row[guid]?.[header.name] ?? value}
              size={8}
              type="text"
              autoComplete="off"
              inputMode="decimal"
              maxLength={8}
              onBlur={e => {
                if (!set.has(guid)) {
                  return;
                }
                row[guid][header.name] = onBlurPrice(e.target.value);
                setValue(concat(name, 'row').join('.'), row);
              }}
              onChange={e => {
                if (!set.has(guid)) {
                  rowClick(guid, values);
                }
                row[guid][header.name] = onChangePrice(e.target.value);
                setValue(concat(name, 'row').join('.'), row);
              }}
              onClick={e => {
                if (!set.has(guid)) {
                  return;
                }
                e.stopPropagation();
              }}
            />
            {header.suffix || null}
          </div>
        )
      );
    },
    [required, name, setValue, inactive, row, set, rowCursor, rowClick]
  );

  return !sorted.length ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9} className={classNames({ ['label--mandatory']: required })}>
        <div className="of-openform__view__list-of-objects-labelarea">
          <label>{label}</label>
        </div>
        <CL.Table
          showHeaders={true}
          valignText="middle"
          className="of-openform__view__list-of-objects-table"
          tableType="bordered"
          hover={true}
          columns={[{ align: 'left' as 'left' | 'right', key: 'checkbox', label: '' }].concat(
            headers
              .filter(header => header.name !== OF.description__c)
              .map((header, i) => ({
                align: (!i ? 'left' : 'right') as 'left' | 'right',
                key: String(i),
                label: header.label ?? '',
              }))
          )}
          rowClicks={sorted.map(item => () => rowClick(item.guid, item.values))}
          rows={sorted.map(item =>
            Object.assign(
              merge((cell, i) => ({ [i]: cell }), ...rowCells(item.guid, item.values)),
              {
                active: set.has(item.guid),
                availability: item.availability,
                checkbox: (
                  <OpenFormCheckbox
                    checked={set.has(item.guid)}
                    disabled={inactive(item.guid)}
                    value={item.guid}
                    readOnly
                  />
                ),
              }
            )
          )}
        />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
