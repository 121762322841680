import { OpenFormAnswers } from '../OpenFormAnswers.js';
import { errorLoadingMsg, loadingFormMsg, t } from '../../../common/i18n/index.js';
import { getValue } from '../OpenFormUtils.js';
import { setValueOptions } from './useOpenFormMethods.js';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { OpenFormDraft } from '../../../generated/api/openFormDraft.js';
import type { OpenFormDraftItem } from '../../../generated/api/openFormDraftItem.js';
import type { OpenFormQuestion } from '../../../generated/api/openFormQuestion.js';
import type { OpenFormResponse } from '../../../generated/api/openFormResponse.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export class OpenForm {
  constructor(
    private readonly response: OpenFormResponse,
    readonly reference = response.formId
  ) {}

  get formId(): string {
    return this.response.formId;
  }

  get name(): string {
    return this.response.name;
  }

  get questions(): OpenFormQuestion[] {
    return this.response.sections.flatMap(s => s.questions);
  }

  get referenceNumber(): string | undefined {
    return this.response.formId !== this.reference ? this.reference : undefined;
  }

  get sections(): OpenFormSection[] {
    return this.response.sections;
  }

  linkedValues(q: OpenFormQuestion, value: string | string[] | undefined): string[] {
    return value?.length ? q.choices.flatMap(c => (value.includes(c.guid) && c.linkedValue) || []) : [];
  }
}

export const useOpenForm = (preview: boolean, formId: string, referenceNumber?: string) => {
  const [data, setData] = useState<OpenForm>();
  const { reset, setValue, trigger } = useFormContext();
  const async = useOpenFormAsync();

  useEffect(() => {
    const { controller } = async
      .fetchOpenForm({ formId, referenceNumber }, preview)
      .resolved(([response, draft]) => {
        if (!draft) {
          async.flags.delete('draft') && async.draft.clear();
          if (referenceNumber) {
            throw t.LOV4(errorLoadingMsg, referenceNumber);
          }
        } else if (!referenceNumber) {
          async.draft.set(draft.referenceNumber, { ...draft, formId } as OpenFormDraftItem);
        } else {
          const { draftState, ...item } = draft as OpenFormDraft;
          async.draft.set(draft.referenceNumber, item);
          async.storage.setItem(draft.referenceNumber, draftState);
        }

        const form = new OpenForm(response, draft?.referenceNumber);
        const state = async.storage.getItem(form.reference);
        const answers = new OpenFormAnswers(state?.answers && Object.entries(state.answers));
        answers.forEachOf('choices', ({ choices }, guid) => setValue(guid, getValue(choices), setValueOptions));
        trigger()
          .then(() => async.actions.answers(answers).page(state?.page).form(form))
          .then(() => setData(form));
      })
      .rejected(text => async.actions.notification({ text, type: 'error' }))
      .cache(formId)
      .execute(t.DR5T(loadingFormMsg));

    return () => {
      controller.abort();
      async.cache.clear();
      async.actions.form(undefined).answers(undefined).page(1);
      reset({});
    };
  }, [preview, formId, referenceNumber, reset, setValue, trigger, async]);

  return data;
};
