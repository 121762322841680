import { type MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { OpenFormCheckbox } from '../OpenFormComponents/OpenFormCheckbox.js';
import { OpenFormListTable, OpenFormListTableLabel } from './OpenFormListTable.js';
import { categoryMsg, nameMsg, subscriptionMsg, t, typeMsg } from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import { useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { useTable } from '../OpenFormHooks/useTable.js';

export const OpenFormListTableForm = ({
  disabled,
  selected,
  select,
  set,
  sizes = [25, 50, 100],
}: {
  disabled: boolean;
  selected: Set<string>;
  select: (set?: Set<string>) => void;
  set: MutableRefObject<Set<string>>;
  sizes?: readonly number[];
}) => {
  const async = useOpenFormAsync();
  const goto = useOpenFormGoto();
  const items = useMemo(() => Array.from(async.forms.values()), [async]);
  const list = useRef(items);
  const [cols, rows, view, sort] = useTable({
    cols: () => {
      const length = list.current.reduce((sum, form) => sum + Number(async.storage.hasItem(form.id)), 0);
      return [
        {
          visible: !async.flags.has('draft'),
          key: 'checkbox',
          label: (
            <OpenFormCheckbox
              disabled={async.state.disabled || !length}
              indeterminate={set.current.size > 0 && set.current.size < length}
              checked={set.current.size > 0 && set.current.size === length}
              onChange={() => {
                if (rows.current.every(form => !async.storage.hasItem(form.id) || set.current.has(form.id))) {
                  rows.current.forEach(form => !async.storage.hasItem(form.id) || set.current.delete(form.id));
                } else {
                  rows.current.forEach(form => !async.storage.hasItem(form.id) || set.current.add(form.id));
                }
                select(set.current);
              }}
            />
          ),
          sortable: false,
        },
        { visible: true, key: 'form', label: t.VGFI(nameMsg), sorted: 'asc' },
        { visible: true, key: 'type', label: t.VGFF(typeMsg) },
        { visible: true, key: 'productCategory', label: t.CV5H(categoryMsg) },
        { visible: true, key: 'subscriptionType', label: t.P674(subscriptionMsg) },
        { visible: false, key: 'name', label: null },
        { visible: false, key: 'id', label: null },
      ];
    },
    rows: () =>
      list.current.map(({ id, name, productCategoryDesc, subscriptionType, typeDesc }) => ({
        checkbox: (
          <OpenFormCheckbox
            checked={set.current.has(id)}
            disabled={async.state.disabled || !async.storage.hasItem(id)}
            onChange={() => {
              set.current.has(id) ? set.current.delete(id) : set.current.add(id);
              select(set.current);
            }}
          />
        ),
        form: (
          <OpenFormListTableLabel
            disabled={async.state.disabled}
            label={name}
            onClick={() => goto({ formId: id }, { list: paths.OPENFORM_LIST_FORM })}
          />
        ),
        type: typeDesc ?? '',
        productCategory: productCategoryDesc ?? '',
        subscriptionType: subscriptionType ?? '',
        name: name,
        id: id,
      })),
    view: () => ({ size: sizes[0], total: list.current.length }),
    order: 'name',
  });

  useEffect(() => sort.current(), [disabled, selected, sort]);

  return (
    <OpenFormListTable
      cols={cols}
      disabled={disabled}
      rows={rows}
      sizes={sizes}
      sort={sort}
      view={view}
      onPageChange={select}
      onSizeChange={select}
      onSortChange={select}
    />
  );
};
