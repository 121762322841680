import { useEffect } from 'react';

export const useEventListener = <K extends keyof WindowEventMap, T extends WindowEventMap[K]>(
  type: K,
  listener: (event: T) => void,
  options: AddEventListenerOptions = {}
) =>
  useEffect(() => {
    const controller = new AbortController();
    options.signal = options.signal ? AbortSignal.any([options.signal, controller.signal]) : controller.signal;
    self.addEventListener(type, listener, options);
    return () => controller.abort();
  }, [type, listener, options]);
