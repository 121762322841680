import * as CL from '@design-system/component-library';
import {
  DEFAULT_ITEMS_PER_PAGE,
  ListPagination,
  ListSearch,
  Table,
  TableDescription,
} from '../../../../../components/Table';
import { Icon } from '../../../../../components/Icon/Icon';
import { Link, useLoaderData, useLocation } from 'react-router-dom';
import { dsClass } from '../../../../../common/constants/dsClasses';
import { formatSumWithSignum } from '../../../../../common/utils/priceUtils';
import { formatTimestampToDDMMYYYY } from '../../../../../common/utils/dateUtils';
import { getInvoiceDocumentPdfPrivateMethod } from '../../../../../generated/api/uiApiMethods';
import { getTranslation } from '../../../../../components/InvoiceDocuments/InvoiceDocumentList';
import { isBefore, subWeeks } from 'date-fns';
import { setDefaultItemsPerPage } from '../../../../../components/Table/tableUtils';
import { t, typeMsg } from '../../../../../common/i18n';
import type { DocumentsLoaderData } from '../../../../../common/loaders';

import './BillingAccountInvoiceDocumentsList.scss';

export const BillingAccountInvoiceDocumentsPath = () => {
  const { documents } = useLoaderData() as DocumentsLoaderData;
  const { search } = useLocation();
  const twoWeeksAgo = subWeeks(Date.now(), 2).getTime();

  const columns = [
    { key: 'documentType', label: t.VGFF(typeMsg), sortable: true },
    { key: 'sum', label: t.P4RQ('Amount'), sortable: true },
    { key: 'created', label: t.T3N9('Date'), sortable: true },
  ];

  const rows =
    documents.searchResults?.map(documentSearchResult => {
      const document = documentSearchResult.result;
      const isNew = isBefore(twoWeeksAgo, document.created);
      return {
        documentType: (
          <Link
            rel="noopener noreferrer"
            target="_blank"
            to={getInvoiceDocumentPdfPrivateMethod(document.invoiceDocumentId).path}
          >
            <Icon color="blue-600" icon="file-pdf" size="l" type="filled" />
            {getTranslation(document.documentType)}
            <span className={dsClass.SR_ONLY}>&nbsp;PDF</span>
          </Link>
        ),
        sum: <TableDescription title={document.sum ? formatSumWithSignum(document.sum) : '-'} />,
        created: (
          <div className="badge-col">
            <TableDescription title={formatTimestampToDDMMYYYY(document.created)} />
            {isNew && (
              <div className="badge">
                <CL.Badge color="blue-light" text={t.SX1L('New')} />
              </div>
            )}
          </div>
        ),
      };
    }) ?? [];

  return (
    <div className="of-billing-account-invoice-documents-list">
      <ListSearch onSearch={() => setDefaultItemsPerPage(new URLSearchParams(search), DEFAULT_ITEMS_PER_PAGE)} />
      <Table columns={columns} rows={rows} noItemsText={t.BOIN('No invoice documents')} />
      <ListPagination totalItems={documents.total} />
    </div>
  );
};
