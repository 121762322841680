import * as DL from '../common/analyticsDataLayerEvent.js';
import { CreditCheckDecision } from '../../generated/api/models.js';
import { CreditDecision } from '../../common/enums.js';
import { EMPTY, of } from 'rxjs';
import { PaymentTypeEnum, getProductPriceData, isGoogleEcommerceAction } from '../common/googleEcommerceEvent.js';
import { TypeKeys, googleEcommercePurchase } from '../actions/index.js';
import { combineEpics, ofType } from 'redux-observable';
import { concatMap, filter, flatMap, take, takeUntil } from 'rxjs/operators';
import { getOrderItems } from '../../common/utils/checkoutUtils.js';
import { handleOrderPurchaseAnalytics } from '../../components/Checkout/checkoutAnalyticsUtils';
import { pushToDataLayer } from '../../common/analytics.js';
import type { Action } from 'redux';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import type {
  AddCartUpdateEventToAnalyticsAction,
  FinalizeOnlineOrderCardPaymentFulfilledAction,
  GoogleEcommerceActionTypes,
  PollForCreditCheckFulfilledAction,
  PollForCreditCheckV2FulfilledAction,
  SelfServiceActionTypes,
  SubmitOnlineOrderAction,
  SubmitOrderFulfilledAction,
} from '../actions/index.js';
import type { EpicDependencies } from './epicUtils.js';
import type { State } from '../common/store.js';

const isPollForCreditCheckFulfilledAction = (
  action: PollForCreditCheckFulfilledAction | PollForCreditCheckV2FulfilledAction
): action is PollForCreditCheckFulfilledAction => {
  return (action as PollForCreditCheckFulfilledAction).creditDecision !== undefined;
};

const isPassableDecision = (action: PollForCreditCheckFulfilledAction | PollForCreditCheckV2FulfilledAction) => {
  if (isPollForCreditCheckFulfilledAction(action)) {
    return [
      CreditDecision.Accepted,
      CreditDecision.AcceptedDeposit,
      CreditDecision.AcceptedInternal,
      CreditDecision.Error,
    ].includes(action.creditDecision);
  }
  return action.response.decision === CreditCheckDecision.PASS;
};

export const createGoogleEcommercePurchaseActionFromCardPaymentEpic: Epic<
  SelfServiceActionTypes,
  Action,
  State,
  EpicDependencies
> = (action$: ActionsObservable<SelfServiceActionTypes>, state$: StateObservable<State>) => {
  return action$.pipe(
    ofType(TypeKeys.FINALIZE_ONLINE_ORDER_CARD_PAYMENT_FULFILLED),
    concatMap((action: FinalizeOnlineOrderCardPaymentFulfilledAction) => {
      const configuredOffer = getOrderItems(state$.value.deviceCheckout?.cartItems);
      if (configuredOffer) {
        const { totalTax, totalRevenue, totalShipping, itemInfo } = getProductPriceData(configuredOffer);
        return of(
          googleEcommercePurchase(
            action.response.onlineRequestId,
            totalRevenue,
            totalTax,
            totalShipping,
            itemInfo,
            PaymentTypeEnum.CREDIT_CARD
          )
        );
      }
      return EMPTY;
    })
  );
};

/*
 * This chains:
 * - submit online order (Used for order items)
 * - submit online order fulfilled (Used for order ID)
 * - credit check fulfilled (Used for checking that order is OK)
 */
export const createGoogleEcommercePurchaseActionFromEndWizardActionEpic: Epic<
  SelfServiceActionTypes,
  Action,
  State,
  EpicDependencies
> = (action$: ActionsObservable<SelfServiceActionTypes>) => {
  return action$.pipe(
    // Wait for a Submit online order
    ofType(TypeKeys.SUBMIT_ONLINE_ORDER),
    concatMap((submitOrderAction: SubmitOnlineOrderAction) => {
      return action$.pipe(
        // Wait for a Submit online order fulfilled
        ofType(TypeKeys.SUBMIT_ONLINE_ORDER_FULFILLED),
        take(1),
        concatMap((submitOrderFulfilledAction: SubmitOrderFulfilledAction) => {
          return action$.pipe(
            // Wait for a Credit check fulfilled
            ofType(TypeKeys.POLL_FOR_CREDIT_CHECK_FULFILLED, TypeKeys.POLL_FOR_CREDIT_CHECK_V2_FULFILLED),
            take(1),
            concatMap(
              (creditCheckFulfilledAction: PollForCreditCheckFulfilledAction | PollForCreditCheckV2FulfilledAction) => {
                // If credit check passed, send ecommerce purchase action
                if (isPassableDecision(creditCheckFulfilledAction)) {
                  handleOrderPurchaseAnalytics(submitOrderAction.items, submitOrderFulfilledAction.orderId);
                }
                return EMPTY;
              }
            ),
            // 'Reset' on credit check failure and logout
            takeUntil(action$.ofType(TypeKeys.POLL_FOR_CREDIT_CHECK_FAILED, TypeKeys.LOGOUT_FULFILLED))
          );
        }),
        // 'Reset' on submit failure
        takeUntil(action$.ofType(TypeKeys.SUBMIT_ONLINE_ORDER_FAILED))
      );
    })
  );
};

export const pushGoogleEcommerceEventToDataLayerEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) => {
  return action$.pipe(
    filter((action: SelfServiceActionTypes) => isGoogleEcommerceAction(action)),
    flatMap((action: GoogleEcommerceActionTypes) => {
      pushToDataLayer(action.event);
      return EMPTY;
    })
  );
};

export const pushAnalyticsEventToDataLayerEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>,
  state$: StateObservable<State>
) => {
  return action$.pipe(
    filter((action: SelfServiceActionTypes) => DL.isAnalyticsDataLayerAction(action, state$)),
    flatMap((action: SelfServiceActionTypes) => {
      pushToDataLayer(DL.getAnalyticsDataLayerEvent(action));
      return EMPTY;
    })
  );
};

export const pushUpdateCartEventToDataLayerEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = (
  action$: ActionsObservable<SelfServiceActionTypes>
) => {
  return action$.pipe(
    ofType(TypeKeys.ADD_CART_EVENT_UPDATE_TO_ANALYTICS),
    concatMap((action: AddCartUpdateEventToAnalyticsAction) => {
      if (action.cartUpdateEventData && action.cartUpdateEventData.length > 0) {
        action.cartUpdateEventData.forEach(item => {
          if (item.deltaQuantity !== 0) {
            pushToDataLayer({
              action: item.analyticsEventType,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'delta-quantity': item.deltaQuantity,
              event: item.event,
              target: item.target,
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'target-properties': item.onlineModelCode,
            });
          }
        });
      }
      return EMPTY;
    })
  );
};

export const analyticsEpic: Epic<SelfServiceActionTypes, Action, State, EpicDependencies> = combineEpics(
  createGoogleEcommercePurchaseActionFromCardPaymentEpic,
  createGoogleEcommercePurchaseActionFromEndWizardActionEpic,
  pushGoogleEcommerceEventToDataLayerEpic,
  pushAnalyticsEventToDataLayerEpic,
  pushUpdateCartEventToDataLayerEpic
);
