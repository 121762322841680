import { type Context, OpenFormAnswers } from './OpenFormAnswers.js';
import { type Dispatch } from 'react';
import { type OpenForm } from './OpenFormHooks/useOpenForm.js';
import { type OpenFormNotification } from './OpenFormComponents/OpenFormNotifications.js';
import { OpenFormSnapshotEvent } from './OpenFormSnapshotEvent.js';
import { type OpenFormStorage } from './OpenFormStorage.js';

export type OpenFormAction = ReturnType<(typeof actions)[keyof typeof actions]>;

export type OpenFormActions = {
  [K in keyof typeof actions]: (
    ...args: (typeof actions)[K] extends (...args: infer P) => unknown ? P : never
  ) => OpenFormActions;
};

export type OpenFormState = Required<ReturnType<typeof OpenFormReducer.state>>;

const type: unique symbol = Symbol('OpenFormAction');

const actions = {
  answers: (payload: OpenFormAnswers | undefined) => ({
    [type]: 'answers' as const,
    payload,
  }),

  choices: (payload: { guid: string; value: string | string[] | undefined }) => ({
    [type]: 'choices' as const,
    payload,
  }),

  confirm: (payload: boolean) => ({
    [type]: 'confirm' as const,
    payload,
  }),

  context: <K extends keyof Context>(payload: { guid: string; key: K; patch?: Context[K] }) => ({
    [type]: 'context' as const,
    payload,
  }),

  form: (payload: OpenForm | undefined) => ({
    [type]: 'form' as const,
    payload,
  }),

  indicator: (payload: { id: string; load: string | true | undefined }) => ({
    [type]: 'indicator' as const,
    payload,
  }),

  notification: (payload: OpenFormNotification | undefined) => ({
    [type]: 'notification' as const,
    payload,
  }),

  page: (payload: number | undefined) => ({
    [type]: 'page' as const,
    payload,
  }),
} as const;

export class OpenFormReducer {
  private constructor(
    readonly answers: OpenFormAnswers = new OpenFormAnswers(),
    readonly confirm: boolean = false,
    readonly disabled: boolean = false,
    readonly form: OpenForm | undefined = undefined,
    readonly indicators: Map<string, string | true> = new Map(),
    readonly notifications: Set<OpenFormNotification> = new Set(),
    readonly page = 1
  ) {}

  static actions(dispatch: Dispatch<OpenFormAction>, target = {}, fns = Object.entries(actions)): OpenFormActions {
    return Object.assign(
      target,
      ...fns.map(([name, action]) => ({ [name]: (payload: never) => (dispatch(action(payload)) as unknown) || target }))
    );
  }

  static reducer(store: OpenFormStorage) {
    return (state: OpenFormState, action: OpenFormAction): OpenFormState => {
      switch (action[type]) {
        case 'choices':
        case 'context':
          return this.#snapshot(this.#reduced(this.#reduce(state, action), action), store);
        case 'answers':
        case 'form':
        case 'page':
          return this.#snapshot(this.#reduce(state, action), store);
        case 'confirm':
        case 'indicator':
        case 'notification':
          return this.#disabled(this.#reduce(state, action));
      }
    };
  }

  static state() {
    return { ...new OpenFormReducer() } as const;
  }

  static #disabled(state: OpenFormState): OpenFormState {
    const { confirm, indicators, notifications } = state;
    const disabled = Boolean(confirm || typeof indicators.values().next().value === 'string' || notifications.size);
    return state.disabled !== disabled ? { ...state, disabled } : state;
  }

  static #reduce(state: OpenFormState, action: OpenFormAction): OpenFormState {
    switch (action[type]) {
      case 'answers': {
        const answers = action.payload;
        return { ...state, answers: new OpenFormAnswers(answers) };
      }
      case 'choices': {
        const { guid, value } = action.payload;
        return value?.length
          ? typeof value === 'string'
            ? { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, 'choices', [value])) }
            : { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, 'choices', value)) }
          : state.answers.delete(guid)
            ? { ...state, answers: new OpenFormAnswers(state.answers) }
            : state;
      }
      case 'confirm': {
        const confirm = action.payload;
        return state.confirm !== confirm ? { ...state, confirm } : state;
      }
      case 'context': {
        const { guid, key, patch } = action.payload;
        return { ...state, answers: new OpenFormAnswers(state.answers.assign(guid, key, patch)) };
      }
      case 'form': {
        const form = action.payload;
        return { ...state, form };
      }
      case 'indicator': {
        const { id, load } = action.payload;
        return load
          ? { ...state, indicators: new Map(state.indicators.set(id, load)) }
          : state.indicators.delete(id)
            ? { ...state, indicators: new Map(state.indicators) }
            : state;
      }
      case 'notification': {
        const notification = action.payload;
        return notification
          ? { ...state, notifications: new Set(state.notifications.add(notification)) }
          : state.notifications.delete(state.notifications.values().next().value)
            ? { ...state, notifications: new Set(state.notifications) }
            : state;
      }
      case 'page': {
        const page = (action.payload && Math.max(Math.floor(action.payload), 1)) || 1;
        return state.page !== page ? { ...state, page } : state;
      }
    }
  }

  static #reduced(state: OpenFormState, action: OpenFormAction): OpenFormState {
    if (state.form) {
      const { answers, form } = state;
      answers.forEachOf(
        'choices',
        (_, guid) => answers.visible(form.questions.find(q => q.guid === guid)) || answers.delete(guid)
      );
      switch (action[type]) {
        case 'choices': {
          const section = form.sections[state.page - 1];
          switch (section?.pageType) {
            case 'QUESTION_SUBSCRIPTION_TYPE': {
              const { guid, value } = action.payload;
              const patch = section.questions.flatMap(q => (q.guid === guid ? form.linkedValues(q, value) : []));
              patch.length && answers.assign(guid, 'subscriptionTypes', patch);
            }
          }
        }
      }
    }
    return state;
  }

  static #snapshot(state: OpenFormState, store: OpenFormStorage): OpenFormState {
    if (state.form) {
      const { answers, form, page } = state;
      answers.size
        ? store.setItem(form.reference, { answers: Object.fromEntries(answers), page })
        : store.removeItem(form.reference);
      self.dispatchEvent(new OpenFormSnapshotEvent(store.storage, store.namespace, form.reference));
    }
    return state;
  }
}
