import { type AddressSearchMatch, type AddressSearchResult } from '../AddressSearch/AddressSearch.js';
import { type AvailabilityAddress } from './OpenFormAnswers.js';
import { format, parse } from 'date-fns';
import type { Address } from '../../generated/api/address.js';

const LOCALE_DATE = 'dd.MM.yyyy';
const LOCALE_DATETIME = 'dd.MM.yyyy HH:mm:ss';

export const concat = <T>(...input: (T | undefined)[]): T[] => input.filter((n): n is T => Boolean(n) || n === 0);

export const formatLocaleDate = (date: string | number | Date) => format(date, LOCALE_DATE);

export const formatLocaleDateTime = (date: string | number | Date) => format(date, LOCALE_DATETIME);

export const fromLocaleDate = (date: string) => parse(date, LOCALE_DATE, new Date());

export const fromLocaleDateTime = (date: string) => parse(date, LOCALE_DATETIME, new Date());

export const getAddressSearchResult = (match?: AddressSearchMatch): AddressSearchResult | undefined =>
  match ? { postalCode: match.postalCode, match } : undefined;

export const getAddressText = (addr: Address | undefined) =>
  addr ? concat(addr.line1, addr.line2, addr.postalCode, addr.postOffice).join(' ') : undefined;

// `date` format is `LOCALE_DATE`, but sent to salesforce as `yyyy-MM-dd`
export const getDate = (date: string | undefined) => (date ? format(fromLocaleDate(date), 'yyyy-MM-dd') : undefined);

export const getValue = <T>(input?: T | T[]) =>
  !input ? input : Array.isArray(input) ? String(input) : typeof input === 'object' ? undefined : input;

export const isAvailabilityAddress = (addr: AvailabilityAddress | undefined) =>
  Boolean(addr && addr.addressId && addr.line1 && addr.postalCode && addr.postOffice && addr.products);

export const isIframe = () => self !== (self.top ?? self.parent);

export const merge: {
  <
    Field extends string | number | symbol,
    Input,
    Merge extends Record<Field, unknown> extends Record<Field, infer U> ? U : never,
  >(
    callback: (value: Input, index: number, array: Input[]) => Merge,
    ...input: Input[]
  ): Merge;
} = (callback, ...input) => Object.assign({}, ...input.map(callback));

export const onBlurPrice = (value: string) => Math.max(0, Math.min(Number(value), 99999.99)).toFixed(2);

export const onChangePrice = (value: string) => value.replace('-', '').replace(',', '.');
