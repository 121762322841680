import * as CL from '@design-system/component-library';
import { BreadCrumbsWithTitle } from '../../../../../components/BreadCrumbsWithTitle/BreadCrumbsWithTitle';
import { DataLoader } from '../../../siteUtils';
import { DetailsWrapper } from '../../../../../components/DetailsWrapper';
import { Outlet, generatePath, useLoaderData, useNavigate } from 'react-router-dom';
import {
  billingAccountMsg,
  billingAccountsMsg,
  invoiceLettersMsg,
  invoicesMsg,
  omaElisaForCompaniesMsg,
  t,
} from '../../../../../common/i18n';
import { getCompanyName } from '../../../../../common/utils/accountUtils';
import { paths } from '../../../../../common/constants/pathVariables';
import { useAuth } from '../../../AuthProvider';
import { useSearchParams } from '../../../../../common/hooks/useSearchParams';
import type { BillingAccountLayoutLoaderData } from '../../../../../common/loaders';

import './BillingAccountLayout.scss';

const isSelectedPage = (path: string) => {
  return location.pathname.endsWith(path.split('/').at(-1) ?? '');
};

export const BillingAccountLayout = () => {
  const { billingAccount } = useLoaderData() as BillingAccountLayoutLoaderData;
  const navigate = useNavigate();
  const { authenticatedUser } = useAuth();
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const companyName = getCompanyName(authenticatedUser, mdmId);

  return (
    <DetailsWrapper
      classes={['of-billing-account-details']}
      detailsTop={
        <BreadCrumbsWithTitle
          breadCrumbPaths={[
            { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
            { name: t.ZVMK(billingAccountsMsg), path: paths.BILLING_ACCOUNTS },
            {
              name: billingAccount.billingAccountDisplayId!,
            },
          ]}
        />
      }
      id={`billing-account-details-${billingAccount.billingAccountId}`}
      heading={billingAccount.billingAccountDisplayId!}
      headingBottom={companyName}
      headingTop={t.IFT9(billingAccountMsg)}
      heroPicto="billing-account"
    >
      <CL.TabNavigation>
        <CL.TabNavigation.Tab
          title={t.KJ47('Billing account details')}
          selected={isSelectedPage(billingAccount.billingAccountDisplayId!)}
          onClick={() =>
            navigate(
              `${generatePath(paths.BILLING_ACCOUNT, { billingAccountId: billingAccount.billingAccountDisplayId! })}?mdmId=${mdmId}`
            )
          }
        />
        <CL.TabNavigation.Tab
          title={t.W691('Products in account')}
          selected={isSelectedPage(paths.BILLING_ACCOUNT_SUBSCRIPTIONS)}
          onClick={() =>
            navigate(
              `${generatePath(paths.BILLING_ACCOUNT_SUBSCRIPTIONS, { billingAccountId: billingAccount.billingAccountDisplayId! })}?mdmId=${mdmId}`
            )
          }
        />
        <CL.TabNavigation.Tab
          title={t.Y7C0(invoicesMsg)}
          selected={isSelectedPage(paths.BILLING_ACCOUNT_INVOICES)}
          onClick={() => {
            navigate(
              `${generatePath(paths.BILLING_ACCOUNT_INVOICES, { billingAccountId: billingAccount.billingAccountDisplayId! })}?mdmId=${mdmId}`
            );
          }}
        />
        <CL.TabNavigation.Tab
          title={t.OMMT(invoiceLettersMsg)}
          selected={isSelectedPage(paths.BILLING_ACCOUNT_INVOICE_DOCUMENTS)}
          onClick={() => {
            navigate(
              `${generatePath(paths.BILLING_ACCOUNT_INVOICE_DOCUMENTS, { billingAccountId: billingAccount.billingAccountDisplayId! })}?mdmId=${mdmId}`
            );
          }}
        />
      </CL.TabNavigation>
      <DataLoader>
        <Outlet />
      </DataLoader>
    </DetailsWrapper>
  );
};
