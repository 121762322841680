declare global {
  type OpenFormSnapshotEvent = InstanceType<typeof OpenFormSnapshotEvent>;

  interface WindowEventMap {
    snapshot: OpenFormSnapshotEvent;
  }
}

export class OpenFormSnapshotEvent extends Event {
  constructor(
    readonly storage: Storage,
    readonly namespace: (key?: string) => string,
    readonly key: string,
    readonly value = storage.getItem(namespace(key)),
    readonly url = self.location.href
  ) {
    super('snapshot', { bubbles: false, cancelable: false, composed: false });
  }
}
