import { loadingFormListMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';

export const useOpenFormList = () => {
  const [ready, setReady] = useState(false);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { controller } = async
      .fetchOpenFormList()
      .resolved(([forms, draft]) => {
        if (!draft) {
          async.flags.delete('draft') && async.draft.clear();
        } else if (Array.isArray(draft)) {
          async.draft.clear();
          draft.forEach(item => async.draft.set(item.referenceNumber, item));
        }
        async.forms.clear();
        forms.forEach(form => async.forms.set(form.id, form));
      })
      .rejected(text => async.actions.notification({ text, type: 'error' }))
      .settled(() => setReady(true))
      .cache()
      .execute(t.ZN5B(loadingFormListMsg));

    return () => {
      controller.abort();
      async.cache.clear();
    };
  }, [async]);

  return ready;
};
