import * as CL from '@design-system/component-library';
import { type OpenFormGotoProps, isPreview, useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { OpenFormIcon } from './OpenFormIcon.js';
import { type OpenFormOutlet } from '../OpenFormLayout/OpenFormLayoutOutlet.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useEffect } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';

export type OpenFormNotification = {
  hide?: () => void;
  icon?: string;
  show?: () => void;
  text: string | Error;
  timer?: number;
  type?: CL.NotificationProps['type'];
};

export const OpenFormNotice = (notification: OpenFormNotification) => {
  const async = useOpenFormAsync();
  useEffect(() => {
    async.actions.notification(notification);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  return null;
};

export const OpenFormNotifications = () => {
  const { pathname, state } = useLocation();
  const { list = paths.OPENFORM_LIST_FORM } = (state ?? {}) as OpenFormGotoProps;
  const { preview = isPreview(pathname), reload } = useOutletContext<OpenFormOutlet>() ?? {};
  const { notifications } = useOpenFormState();
  const async = useOpenFormAsync();
  const goto = useOpenFormGoto();

  const clear = useCallback(() => {
    async.cache.clear();
    async.state.form?.reference && async.storage.removeItem(async.state.form.reference);
    goto(preview ? { page: 1 } : { list });
  }, [preview, list, goto, async]);

  const notification = notifications.values().next().value as OpenFormNotification | undefined;
  if (!notification) {
    return null;
  }
  const { hide, show, text, timer, type = 'info', icon = type } = notification;
  return (
    <CL.Notification
      className="of-openform__title__content__notifications"
      icon={icon && <OpenFormIcon icon={icon} size="m" />}
      key={String([text, type, icon])}
      text={String(text)}
      timer={timer}
      type={type}
      visible={true}
      hasCloseButton={true}
      onNotificationShow={show}
      onNotificationHide={() =>
        (type === 'error' ? (hide ?? clear) : type === 'warning' ? (hide ?? reload) : hide)?.() ||
        async.actions.notification(undefined)
      }
    />
  );
};
