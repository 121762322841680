import { type AuthProps } from '../../../public/site/AuthProvider.js';
import { AuthenticatedUserRole } from '../../../generated/api/authenticatedUserRole.js';
import { IdentityProvider } from '../../../generated/api/identityProvider.js';
import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormLayoutOutlet } from './OpenFormLayoutOutlet.js';
import { OpenFormNotice } from '../OpenFormComponents/OpenFormNotifications.js';
import { authenticatingMsg, authenticationFailedMsg, t } from '../../../common/i18n/index.js';
import { useMemo } from 'react';

export const OpenFormLayoutCanvas = ({ auth }: { auth: AuthProps }) => {
  const authenticated = useMemo(
    () =>
      IdentityProvider.SALESFORCE === auth.authenticatedUser?.identityProvider &&
      [AuthenticatedUserRole.ADMIN, AuthenticatedUserRole.KEY_USER].includes(auth.authenticatedUser.userRole!),
    [auth]
  );
  switch (true) {
    case auth.isLoading:
      return <OpenFormBar indicator={t.TA3J(authenticatingMsg)} />;
    case authenticated:
      return <OpenFormLayoutOutlet auth={auth} />;
    case self.history.state && 'retry' in self.history.state:
      return (
        <OpenFormBar>
          <OpenFormNotice
            text={t.J9TT(authenticationFailedMsg)}
            type="error"
            hide={() => {
              delete self.history.state.retry;
              self.history.replaceState(self.history.state, '', self.location.href);
              self.location.reload();
            }}
          />
        </OpenFormBar>
      );
    default:
      self.history.replaceState(Object.assign(self.history.state ?? {}, { retry: true }), '', self.location.href);
      self.location.reload();
      return null;
  }
};
