import { SourceSystem } from '../../../generated/api/sourceSystem.js';
import { loadingBillingAccountsMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { BillingAccountHeader } from '../../../generated/api/billingAccountHeader.js';

export const useOpenFormBillingAccounts = () => {
  const [data, setData] = useState<(BillingAccount | BillingAccountHeader)[]>([]);
  const async = useOpenFormAsync();

  useEffect(() => {
    const { controller, failure } = async
      .fetchBillingAccounts({ sourceSystem: SourceSystem.MIPA })
      .resolved(([accounts, headers]) => {
        switch (true) {
          case accounts.status === 'fulfilled' && !!accounts.value.billingAccounts?.length:
            return setData(accounts.value.billingAccounts);
          case headers.status === 'fulfilled' && !!headers.value.searchResults?.length:
            return setData(headers.value.searchResults.map(({ result }) => result));
          case accounts.status === 'rejected':
            return failure(accounts, text => async.actions.notification({ text, type: 'warning' }));
          case headers.status === 'rejected':
            return failure(headers, text => async.actions.notification({ text, type: 'warning' }));
        }
      })
      .cache(SourceSystem.MIPA)
      .execute(t.RCPW(loadingBillingAccountsMsg));

    return () => controller.abort();
  }, [async]);

  return data;
};
