import * as CL from '@design-system/component-library';
import { OpenFormButton } from './OpenFormButton.js';
import { OpenFormIcon } from './OpenFormIcon.js';
import { type OpenFormOutlet } from '../OpenFormLayout/OpenFormLayoutOutlet.js';
import { useOpenFormState } from '../OpenFormProvider.js';
import { useOutletContext } from 'react-router-dom';

export const OpenFormLoadingIndicator = ({ indicator }: { indicator?: string }) => {
  const { account, reload, title } = useOutletContext<OpenFormOutlet>() ?? {};
  const { disabled, indicators } = useOpenFormState();
  const load = indicator || (indicators?.values().next().value as string | true | undefined);
  return (
    <div className="of-openform__title__content__center">
      {load === undefined ? (
        <div className="of-openform__title__content__center__logo">
          <CL.Logo aria-disabled={disabled} />
          {!self.location.hostname.endsWith('yrityksille.elisa.fi') && <pre>{self.location.hostname}</pre>}
        </div>
      ) : (
        <div className="of-openform__title__content__center__load">
          {load !== true && <span className="of-openform__title__content__center__load__text">{load}</span>}
          <CL.LoadingSpinner className="of-openform__title__content__center__load__spinner" size="l" />
        </div>
      )}
      <div className="of-openform__title__content__center__name" aria-disabled={disabled}>
        {!account ? null : (
          <>
            <span className="of-openform__title__content__center__name__account">
              {account.header.accountName}
              <OpenFormButton
                icon={<OpenFormIcon icon="update" />}
                color="link"
                size="s"
                disabled={disabled || !reload}
                onClick={reload}
              />
            </span>
            <span className="of-openform__title__content__center__name__title">{title?.() ?? <>&nbsp;</>}</span>
          </>
        )}
      </div>
    </div>
  );
};
