import { AnalyticsListNames, getEcommerceItemFromOffer } from '../../common/utils/analyticsUtils';
import { EcommerceEventTypeV4, pushToDataLayer } from '../../common/analytics.js';
import { ModelType } from '../../common/enums.js';
import type { CampaignAssociation } from '../../generated/api/campaignAssociation.js';
import type {
  EcommerceItem,
  EcommerceSelectItemEventV4,
  EcommerceViewItemListEventV4,
} from '../../common/analytics.js';
import type { Offer } from '../../generated/api/offer.js';
import type { OnlineModel } from '../../generated/api/onlineModel.js';
import type { SubCardProps } from '../SubscriptionCard/SubscriptionCard.js';

export type SubCardWithAnalyticsV4Props = SubCardProps & {
  offer: Offer;
  onlineModel: OnlineModel;
  voucher?: string;
  campaignAssociation?: CampaignAssociation;
};
export type SubCardWithImpressionProps = SubCardWithAnalyticsV4Props & {
  listName: string;
  listPosition: number;
  productName: string;
};

const isEcommerceItemList = (subs: SubCardWithAnalyticsV4Props[] | EcommerceItem[]): subs is EcommerceItem[] =>
  !subs.length || (subs[0] as EcommerceItem).item_id != null;

export const getViewItemListAnalyticsEvent = (
  subscriptions: SubCardWithAnalyticsV4Props[] | EcommerceItem[],
  listName?: string
): EcommerceViewItemListEventV4 => ({
  event: EcommerceEventTypeV4.VIEW_ITEM_LIST,
  ecommerce: {
    items: isEcommerceItemList(subscriptions)
      ? subscriptions
      : subscriptions.map((sub, index) =>
          getEcommerceItemFromOffer(
            sub.onlineModel.onlineModelName,
            sub.offer,
            1,
            index,
            sub.voucher,
            sub.campaignAssociation,
            listName
          )
        ),
  },
});

export const getSelectItemAnalyticsEvent = (
  { voucher, campaignAssociation, offer, onlineModel }: SubCardWithAnalyticsV4Props,
  index: number,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  item_list_name?: string
): EcommerceSelectItemEventV4 => {
  return {
    event: EcommerceEventTypeV4.SELECT_ITEM,
    ecommerce: {
      items: [
        getEcommerceItemFromOffer(
          onlineModel.onlineModelName,
          offer,
          1,
          index,
          voucher,
          campaignAssociation,
          item_list_name
        ),
      ],
    },
  };
};

export const getListName = (onlineModelType: ModelType) => {
  if (onlineModelType === ModelType.VoiceSME) {
    return AnalyticsListNames.VOICE_LIST_NAME;
  } else if (onlineModelType === ModelType.MobileM2M) {
    return AnalyticsListNames.LAITE_NETTI_LIST_NAME;
  }

  return AnalyticsListNames.MBB_LIST_NAME;
};

// We can fire the impression only when we have an onlineModel & offer.
export const isSubCardWithImpression = (
  subscription: SubCardProps | SubCardWithImpressionProps
): subscription is SubCardWithImpressionProps => !!(subscription as SubCardWithImpressionProps).offer;

export const sendSelectionAnalyticsEvent = (
  subscription: SubCardWithImpressionProps | SubCardWithAnalyticsV4Props,
  index: number,
  listName?: string
): void => {
  pushToDataLayer(getSelectItemAnalyticsEvent(subscription, index, listName));
};
