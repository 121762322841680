import { type AuthProps } from '../../../public/site/AuthProvider.js';
import { Navigate, generatePath } from 'react-router-dom';
import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormLayoutOutlet } from './OpenFormLayoutOutlet.js';
import { authenticatingMsg, t } from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';

export const OpenFormLayoutOnline = ({ auth }: { auth: AuthProps }) => {
  switch (true) {
    case auth.isLoading:
      return <OpenFormBar indicator={t.TA3J(authenticatingMsg)} />;
    case auth.isAuthenticated:
      return <OpenFormLayoutOutlet auth={auth} />;
    default:
      return <Navigate replace to={generatePath(paths.SELF_SERVICE_HOME)} />;
  }
};
