import { OpenFormButton } from './OpenFormButton.js';
import { OpenFormGrid, OpenFormGridCol, OpenFormGridRow } from './OpenFormGrid.js';
import { OpenFormIcon } from './OpenFormIcon.js';
import { OpenFormLoadingIndicator } from './OpenFormLoadingIndicator.js';
import { OpenFormNotifications } from './OpenFormNotifications.js';
import { type ReactNode, useMemo } from 'react';
import { cancelMsg, confirmMsg, t } from '../../../common/i18n/index.js';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import classNames from 'classnames';

export const OpenFormBar = ({
  children,
  indicator,
  leftFunc,
  leftIcon,
  leftText,
  rightFunc,
  rightIcon,
  rightText,
}: {
  children?: ReactNode;
  indicator?: string;
  leftFunc?: () => void;
  leftIcon?: string;
  leftText?: ReactNode;
  rightFunc?: () => void;
  rightIcon?: string;
  rightText?: ReactNode;
}) => {
  const { confirm, disabled, notifications } = useOpenFormState();
  const async = useOpenFormAsync();
  const enabled = useMemo(() => !disabled && !indicator, [disabled, indicator]);
  return (
    <OpenFormGrid className="of-openform">
      <OpenFormGridRow className="of-openform__header">
        <OpenFormGridCol className="of-openform__header__content">
          {notifications.size ? null : confirm ? (
            <>
              <OpenFormButton
                className="of-openform__header__content__button"
                icon={<OpenFormIcon className="baseline--right" icon="close" />}
                iconPosition="left"
                text={t.B2V1(cancelMsg)}
                color="link"
                size="m"
                disabled={false}
                onClick={() => async.actions.confirm(false)}
              />
              <OpenFormButton
                className="of-openform__header__content__button color-danger"
                icon={<OpenFormIcon className="baseline--left" icon="check" />}
                iconPosition="right"
                text={t.QVYK(confirmMsg)}
                color="link"
                size="m"
                disabled={false}
                onClick={() => rightFunc?.() || async.actions.confirm(false)}
              />
            </>
          ) : (
            <>
              <OpenFormButton
                className="of-openform__header__content__button"
                icon={!leftIcon ? null : <OpenFormIcon className="baseline--right" icon={leftIcon} />}
                iconPosition="left"
                text={leftText || <>&nbsp;</>}
                color="link"
                size="m"
                disabled={!leftText || !leftFunc || !enabled}
                onClick={() => leftFunc?.()}
              />
              <OpenFormButton
                className="of-openform__header__content__button"
                icon={!rightIcon ? null : <OpenFormIcon className="baseline--left" icon={rightIcon} type="regular" />}
                iconPosition="right"
                text={rightText || <>&nbsp;</>}
                color="link"
                size="m"
                disabled={!rightText || !rightFunc || !enabled}
                onClick={() => async.actions.confirm(true)}
              />
            </>
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
      <OpenFormGridRow className={classNames('of-openform__title', { ['reset-top-margin']: notifications.size })}>
        <OpenFormGridCol className="of-openform__title__content">
          {notifications.size ? <OpenFormNotifications /> : <OpenFormLoadingIndicator indicator={indicator} />}
        </OpenFormGridCol>
      </OpenFormGridRow>
      {children}
    </OpenFormGrid>
  );
};
