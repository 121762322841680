import {
  AnalyticsItemCategory,
  CURRENCY_CODE_EUR,
  EcommerceEventTypeV4,
  pushToDataLayer,
} from '../../common/analytics';
import {
  PaymentTypeEnum,
  createEcommerceItemFromCommercialProducts,
  createEcommerceItemFromShoppingCartItem,
  getProductPriceData,
} from '../../selfservice/common/googleEcommerceEvent';
import {
  getAddToCartAnalyticsEvent,
  getEcommerceItems,
  getItemVariant,
  getRemoveFromCartAnalyticsEvent,
  getSubscriptionPurchaseAnalyticsEvent,
  isSubscriptionProduct,
} from '../../common/utils/analyticsUtils';
import {
  getCommercialProductForGuid,
  getOnlineModelForBasketItem,
  isSalesProductModel,
} from '../ShoppingBasket/shoppingBasketUtils';
import type {
  CheckoutEcommerceEvent,
  EcommerceAddToCartEventV4,
  EcommerceCheckoutStepEvent,
  EcommerceItem,
  EcommerceRemoveFromCartEventV4,
} from '../../common/analytics';

import { fetchOnlineModelForCode } from '../../common/fetch';
import { getManufacturer } from '../../common/utils/cartProductUtils';
import { googleEcommercePurchase } from '../../selfservice/actions';
import type { AddedAddon } from '../../common/types/addon';
import type { BasketItem } from '../../common/types/shoppingBasket';
import type { ConfiguredOffer } from '../../common/types/commercialProduct';
import type { OnlineModel } from '../../generated/api/onlineModel';
import type { ShoppingCartAddOn, ShoppingCartItemForCheckout } from '../../common/types/checkout';

export const mapShoppingCartAddonsToAddedAddons = (shoppingCardAddons?: Array<ShoppingCartAddOn>): AddedAddon[] =>
  shoppingCardAddons?.map(shoppingCartAddon => ({
    addOnCode: shoppingCartAddon.addOnCode,
    addOnAssociationCode: shoppingCartAddon.addOnAssociationCode,
    displayName: shoppingCartAddon.addOnProductName,
    oneTimePrice: shoppingCartAddon.addOnOneTimeCharge,
    monthlyPrice: shoppingCartAddon.addOnMonthlyRecurringCharge,
  })) || [];

const getCheckoutEvent = async (
  shoppingCartItems: ShoppingCartItemForCheckout[],
  eventType: EcommerceCheckoutStepEvent,
  onlineModels?: OnlineModel[]
): Promise<CheckoutEcommerceEvent> => {
  const itemPromises = shoppingCartItems.map(async item => {
    const onlineModel =
      onlineModels?.find(model => model.onlineModelCode === item.onlineModelCode) ??
      // TODO device checkout uses currently Redux based online models and Redux store might not include
      // some sales product models. After de-reduxation, there should be no more issues.
      (await fetchOnlineModelForCode(item.onlineModelCode));

    if (!onlineModel) {
      return [];
    }

    const { onlineModelName, offers } = onlineModel;
    const offer = offers.find(o => o.offerCode === item.offerCode);
    if (!offer) {
      return [];
    }

    return getEcommerceItems(
      onlineModelName,
      offer,
      item.quantity,
      eventType,
      mapShoppingCartAddonsToAddedAddons(item.selectedAddOns)
    );
  });

  // Wait for all promises to resolve and flatten the results
  const resolvedItems = (await Promise.all(itemPromises)).flat();

  if (eventType === EcommerceEventTypeV4.ADD_TO_CART || eventType === EcommerceEventTypeV4.REMOVE_FROM_CART) {
    return {
      event: eventType,
      ecommerce: {
        currency: CURRENCY_CODE_EUR,
        items: resolvedItems,
      },
    };
  }

  return {
    event: eventType,
    ecommerce: {
      items: resolvedItems,
    },
  };
};

export const handleCheckoutAnalytics = async (
  cartItems: ShoppingCartItemForCheckout[],
  eventType: EcommerceCheckoutStepEvent,
  models?: OnlineModel[]
): Promise<void> => {
  if (!cartItems.length) {
    return;
  }

  const salesProducts = cartItems.filter(item => item.category === AnalyticsItemCategory.SALES_PRODUCT);
  const otherProducts = cartItems.filter(item => item.category !== AnalyticsItemCategory.SALES_PRODUCT);

  if (salesProducts.length > 0) {
    pushToDataLayer(await getCheckoutEvent(salesProducts, eventType, models));
  }

  if (otherProducts.length > 0) {
    const ecommerceItems = otherProducts.map(item => createEcommerceItemFromShoppingCartItem(item));
    const ecommerceEvent = {
      event: eventType,
      ecommerce: {
        items: ecommerceItems,
      },
    };
    pushToDataLayer(ecommerceEvent);
  }
};

export const handleOrderPurchaseAnalytics = (items: ConfiguredOffer[], orderId: string): void => {
  const subscriptionProducts = items.filter(item => isSubscriptionProduct(item?.onlineModelName));
  const otherProducts = items.filter(item => !isSubscriptionProduct(item?.onlineModelName));

  if (subscriptionProducts.length) {
    subscriptionProducts.forEach(product => pushToDataLayer(getSubscriptionPurchaseAnalyticsEvent(product, orderId)));
  }

  if (otherProducts.length) {
    const priceData = getProductPriceData(otherProducts);
    pushToDataLayer(
      googleEcommercePurchase(
        orderId,
        priceData.totalRevenue,
        priceData.totalTax,
        priceData.totalShipping,
        priceData.itemInfo,
        PaymentTypeEnum.INVOICE
      ).event
    );
  }
};

const createAddToCartEvent = (ecommerceItem: EcommerceItem): EcommerceAddToCartEventV4 => ({
  event: EcommerceEventTypeV4.ADD_TO_CART,
  ecommerce: {
    currency: CURRENCY_CODE_EUR,
    items: [ecommerceItem],
  },
});

const createRemoveFromCartEvent = (ecommerceItem: EcommerceItem): EcommerceRemoveFromCartEventV4 => ({
  event: EcommerceEventTypeV4.REMOVE_FROM_CART,
  ecommerce: {
    currency: CURRENCY_CODE_EUR,
    items: [ecommerceItem],
  },
});

export const handleAnalyticsEventForAddOrRemoveItem = (
  basketItem: BasketItem | undefined,
  newQuantity: number,
  onlineModels: OnlineModel[]
): void => {
  if (!basketItem) {
    return;
  }
  const onlineModelForItem = getOnlineModelForBasketItem(onlineModels, basketItem);
  if (!onlineModelForItem) {
    return;
  }
  const offer = onlineModelForItem?.offers.find(o => o.offerCode === basketItem.offer.guid);
  if (!offer) {
    return;
  }

  const delta = newQuantity - basketItem.quantity;
  if (isSalesProductModel(onlineModelForItem)) {
    const salesProductEvent =
      delta > 0
        ? getAddToCartAnalyticsEvent(onlineModelForItem.onlineModelName, offer, Math.abs(delta))
        : getRemoveFromCartAnalyticsEvent(onlineModelForItem.onlineModelName, offer, Math.abs(delta));
    pushToDataLayer(salesProductEvent);
  } else {
    const commercialProduct = getCommercialProductForGuid(basketItem.offer.commercialProductGuid, onlineModelForItem);
    if (!commercialProduct) {
      return;
    }

    const ecommerceItem = createEcommerceItemFromCommercialProducts(
      onlineModelForItem.onlineModelCode,
      onlineModelForItem.category ?? commercialProduct.productSubType ?? commercialProduct.productType,
      onlineModelForItem.onlineModelName,
      getItemVariant(EcommerceEventTypeV4.ADD_TO_CART, commercialProduct.commercialProductName) ??
        commercialProduct.commercialProductName,
      [commercialProduct],
      Math.abs(delta),
      getManufacturer(onlineModelForItem, commercialProduct.commercialProductName)
    );

    const deviceEvent = delta > 0 ? createAddToCartEvent(ecommerceItem) : createRemoveFromCartEvent(ecommerceItem);

    if (deviceEvent) {
      pushToDataLayer(deviceEvent);
    }
  }
};
