import { type FieldErrors } from 'react-hook-form';
import { OFPageType } from '../../../generated/api/oFPageType.js';
import { type OpenForm } from '../OpenFormHooks/useOpenForm.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { type OpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { OpenFormPageType } from './OpenFormPage/OpenFormPageType.js';
import { nextMsg, previousMsg, t } from '../../../common/i18n/index.js';
import { useMemo } from 'react';

export const OpenFormViewSection = ({
  form,
  answers,
  disabled,
  errors,
  page,
  goto,
}: {
  form: OpenForm;
  answers: OpenFormAnswers;
  disabled: boolean;
  errors: FieldErrors;
  page: number;
  goto: OpenFormGoto;
}) => {
  const section = useMemo(() => form?.sections.at(page - 1), [form, page]);
  const errored = answers.nextErrored(section, errors);
  return !section ? null : (
    <div id={section.guid} className="of-openform__view__section">
      <OpenFormPageType section={section} answers={answers} disabled={disabled} />
      <OpenFormGridRow className="of-openform__view__footer">
        <OpenFormGridCol className="of-openform__view__footer__content">
          <OpenFormButton
            iconPosition="left"
            icon={<OpenFormIcon icon="arrow-left" />}
            text={t.CACI(previousMsg)}
            color="link"
            size="m"
            disabled={disabled || page === 1}
            onClick={() => goto({ page: 'prev' })}
          />
          {section.pageType === OFPageType.AVAILABILITY_CHECK ? null : errored ? (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="arrow-right" />}
              text={t.F0MY(nextMsg)}
              color="link"
              size="m"
              disabled={disabled}
              onClick={() => document.getElementById(errored.guid)?.scrollIntoView(true)}
            />
          ) : (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="arrow-right" />}
              text={t.F0MY(nextMsg)}
              color="primary"
              size="m"
              disabled={disabled || !form.sections.slice(0, page).every(answers.isCompleted)}
              onClick={() => goto({ page: 'next' })}
            />
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
    </div>
  );
};
