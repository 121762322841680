import { type AuthProps } from '../../../public/site/AuthProvider.js';
import { type OpenFormAccount, useOpenFormAccount } from '../OpenFormHooks/useOpenFormAccount.js';
import { Outlet, useLocation } from 'react-router-dom';
import { type RealAuthenticatedUserState } from '../../../common/types/states.js';
import { incompleteOrdersMsg, salesFormsMsg, t } from '../../../common/i18n/index.js';
import { isPreview, useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useMemo, useRef } from 'react';
import { useEventListener } from '../OpenFormHooks/useEventListener.js';
import { useOpenFormAsync, useOpenFormCanvas, useOpenFormState } from '../OpenFormProvider.js';
import { useReload } from '../OpenFormHooks/useReload.js';

export type OpenFormOutlet = {
  account?: OpenFormAccount;
  preview?: boolean;
  reload?: () => void;
  title?: (path?: string) => string | undefined;
  user?: RealAuthenticatedUserState;
};

export const OpenFormLayoutOutlet = ({ auth }: { auth: AuthProps }) => {
  const { pathname } = useLocation();
  const { form } = useOpenFormState();
  const async = useOpenFormAsync();
  const canvas = useOpenFormCanvas();
  const goto = useOpenFormGoto();
  const account = useOpenFormAccount();
  const preview = useMemo(() => isPreview(pathname), [pathname]);
  const cooldown = useRef(0);
  const debounce = useRef<ReturnType<typeof setTimeout>>();
  const inflight = useRef<Promise<unknown>>();
  const [reloading, reload] = useReload();

  const title = useCallback<(path?: string) => string | undefined>(
    (path = pathname) => {
      switch (path) {
        case paths.OPENFORM_LIST_DRAFT:
          return t.E99N(incompleteOrdersMsg);
        case paths.OPENFORM_LIST_FORM:
          return t.QF2O(salesFormsMsg);
        default:
          return form?.name;
      }
    },
    [pathname, form]
  );

  useEventListener(
    'message',
    useCallback<(event: MessageEvent) => void>(
      event => {
        switch (event.data?.action) {
          case 'billingAccountCreated':
          case 'contactCreated':
            canvas.postMessageAcknowledge(event.data.action); // Placeholder
            break;
          case 'goto':
            goto(event.data);
            break;
        }
      },
      [goto, canvas]
    )
  );

  useEventListener(
    'snapshot',
    useCallback<(event: OpenFormSnapshotEvent) => Promise<void>>(
      async event => {
        if (!preview && async.flags.has('draft') && event.namespace === async.storage.namespace) {
          await inflight.current;
          clearTimeout(debounce.current);
          debounce.current = setTimeout(
            () => {
              const draft = async.flags.has('draft') && event.url === self.location.href && async.draft.get(event.key);
              if (draft) {
                const { formId, referenceNumber } = draft;
                inflight.current = async
                  .postOpenFormDraft({ draftState: event.value!, formId, referenceNumber })
                  .settled(() => (cooldown.current = Date.now() + 5000))
                  .promise();
              }
            },
            Math.max(cooldown.current - Date.now(), 2000)
          );
        }
      },
      [async, preview]
    )
  );

  return reloading ? null : <Outlet context={{ account, preview, reload, title, user: auth.authenticatedUser }} />;
};
