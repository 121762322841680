import { type OpenFormOutlet } from '../OpenFormLayout/OpenFormLayoutOutlet.js';
import { generatePath, matchPath, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback } from 'react';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import type { OpenFormSection } from '../../../generated/api/openFormSection.js';

export type OpenFormGoto = (
  path?: { formId?: string; list?: string; page?: 'completed' | 'next' | 'prev' | 'todo' | number },
  props?: OpenFormGotoProps
) => void;

export type OpenFormGotoProps = { list?: string; referenceNumber?: string };

export const isPreview = (pathname: string) => !!matchPath(paths.OPENFORM_PREVIEW + '?', pathname);

export const useOpenFormGoto = () => {
  const { pathname, search, state } = useLocation();
  const { preview = isPreview(pathname) } = useOutletContext<OpenFormOutlet>() ?? {};
  const { answers, form, page } = useOpenFormState();
  const navigate = useNavigate();
  const async = useOpenFormAsync();

  const step = useCallback<(slice?: OpenFormSection[]) => number>(
    (slice = []) => Math.abs(slice.findIndex((section, i) => i && answers.isActive(section) && i)),
    [answers]
  );

  return useCallback<OpenFormGoto>(
    (path = {}, props = state, options = { replace: true, state: props }) => {
      switch (true) {
        case 'list' in path && !!(path.list ??= state?.list) && !!(options.state = { ...props, list: path.list }):
          return navigate(generatePath(path.list) + search, options);
        case 'formId' in path && !!(path.formId ??= form?.formId):
          return navigate(generatePath(preview ? paths.OPENFORM_PREVIEW : paths.OPENFORM_VIEW, path) + search, options);
        case 'page' in path && !!(path.formId ??= form?.formId):
          switch (path.page) {
            case 'completed':
              break;
            case 'next':
              path.page = Number(page + step(form?.sections.slice(page - 1)));
              break;
            case 'prev':
              path.page = Number(page - step(form?.sections.slice(0, page).reverse()));
              break;
            case 'todo':
              path.page = Number(form && form.sections.findIndex(answers.isIncomplete) + 1);
              break;
            default:
              path.page = Number(path.page);
              break;
          }
          typeof path.page === 'number' && (path.page ||= 1) && form?.reference && async.actions.page(path.page);
          return navigate(generatePath(preview ? paths.OPENFORM_PREVIEW : paths.OPENFORM_VIEW, path) + search, options);
      }
    },
    [state, preview, search, answers, form, navigate, async, page, step]
  );
};
