import { ConsolidatedListView } from '../../components/ConsolidatedListView/ConsolidatedListView.js';
import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch } from '../Table/index.js';
import { Grid } from '../Grid/Grid.js';
import { InvoiceTable } from '../Invoices/Invoices.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { setDefaultItemsPerPage } from '../Table/tableUtils.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';
import type { DefaultListSearchParams } from '../Table/index.js';
import type { InvoicesSearchResponse } from '../../generated/api/models.js';

import './InvoiceList.scss';

export interface InvoiceListProps {
  category: 'open' | 'paid' | 'all';
  invoices?: InvoicesSearchResponse[];
  total: number;
}

export const InvoiceList = ({ category, invoices, total }: InvoiceListProps) => {
  const { authenticatedUser } = useAuth();
  const searchParams = useSearchParams<DefaultListSearchParams>();
  const { search } = searchParams;

  return (
    <Grid colWidthL={12}>
      <div className="of-invoice-list">
        <ConsolidatedListView
          authenticatedUser={authenticatedUser}
          listSearch={
            <ListSearch
              className={dsClass.MARGIN_TOP_4}
              onSearch={() => setDefaultItemsPerPage(new URLSearchParams(searchParams), DEFAULT_ITEMS_PER_PAGE)}
            />
          }
          listElement={
            <InvoiceTable
              authenticatedUser={authenticatedUser}
              category={category}
              invoices={invoices || []}
              search={search || ''}
            />
          }
          listPagination={<ListPagination totalItems={total} />}
          wideLayout={true}
        />
      </div>
      <div className={`${dsClass.TEXT_SMALL} ${dsClass.MARGIN_TOP_4}`}>
        * {t.YM3W('Showing the current information of the billing account.')}
      </div>
    </Grid>
  );
};
