import { Locale, getLocale, setSiteLocale } from '../../../common/i18n/index.js';
import { OpenFormLayoutCanvas } from './OpenFormLayoutCanvas.js';
import { OpenFormLayoutOnline } from './OpenFormLayoutOnline.js';
import { OpenFormProvider } from '../OpenFormProvider.js';
import { isIframe } from '../OpenFormUtils.js';
import { setFooterVisibility } from '../../../selfservice/actions/index.js';
import { useAuth } from '../../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const OpenFormLayout = () => {
  const auth = useAuth();
  const dispatch = useDispatch();
  const [search] = useSearchParams();

  useEffect(() => {
    switch (search.get('locale')) {
      default: // Only Finnish supported in the first version
        getLocale() !== Locale.FI && setSiteLocale(Locale.FI);
    }
    dispatch(setFooterVisibility(false));
    return () => {
      dispatch(setFooterVisibility(true));
    };
  }, [dispatch, search]);

  return (
    <OpenFormProvider accountMasterId={auth?.authenticatedUser?.activeAccountMasterId}>
      {isIframe() ? <OpenFormLayoutCanvas auth={auth} /> : <OpenFormLayoutOnline auth={auth} />}
    </OpenFormProvider>
  );
};
