import { AddressSearch } from '../../../AddressSearch/AddressSearch.js';
import { type OpenFormAnswers } from '../../OpenFormAnswers.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { concat, getAddressSearchResult } from '../../OpenFormUtils.js';
import { loadingProductAvailabilityMsg, noSubscriptionsErrorMsg, t } from '../../../../common/i18n/index.js';
import { useAbortController } from '../../OpenFormHooks/useAbortController.js';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOpenFormAsync } from '../../OpenFormProvider.js';
import { useOpenFormGoto } from '../../OpenFormHooks/useOpenFormGoto.js';
import type { OpenFormSection } from '../../../../generated/api/openFormSection.js';

export const OpenFormPageAvailability = ({
  section,
  answers,
  disabled,
}: {
  section: OpenFormSection;
  answers: OpenFormAnswers;
  disabled: boolean;
}) => {
  const { setValue } = useFormContext();
  const async = useOpenFormAsync();
  const goto = useOpenFormGoto();
  const [result, setResult] = useState(getAddressSearchResult(answers.get(section)?.address));
  const [_, setAbortController] = useAbortController();

  const onSearch = useCallback(
    (query: string, postalCode: string) => {
      const { controller } = async
        .fetchAddressSearch({ postalCode, query, filter: false })
        .resolved(setResult)
        .cache(query, postalCode)
        .execute(true);
      setAbortController(controller);
    },
    [async, setAbortController]
  );

  const onSubmit = useCallback(() => {
    const { addressId, addressText } = result!.match!;
    const { formId } = async.state.form!;
    const subscriptionTypes = answers.valuesOf('subscriptionTypes').flat();
    const { controller, failure } = async
      .fetchOpenFormProductAvailability({ addressId, formId, subscriptionTypes })
      .resolved(([address, products]) => {
        switch (true) {
          case address.status === 'rejected':
            return failure(address, text => async.actions.notification({ text, type: 'warning' }));
          case products.status === 'rejected':
            return failure(products, () =>
              async.actions.notification({ text: t.SBUB(noSubscriptionsErrorMsg), type: 'warning' })
            );
          default:
            setValue(concat(section.guid, 'address').join('.'), {
              addressId: address.value.addressId,
              addressText: addressText,
              countryCode: address.value.countryCode,
              line1: concat(address.value.streetName, address.value.streetNumber).join(' '),
              line2: concat<string | number>(address.value.stairLetter, address.value.apartmentNumber).join(' '),
              postalCode: address.value.postalCode,
              postOffice: address.value.postalOfficeName,
              products: products.value,
            });
            goto({ page: 'next' });
        }
      })
      .execute(t.UR0I(loadingProductAvailabilityMsg));
    setAbortController(controller);
  }, [answers, section, setValue, async, result, setAbortController, goto]);

  return (
    <OpenFormGridRow className="of-openform__address-check">
      <OpenFormGridCol colWidth={5} className="of-openform__address-check__field-container label--mandatory">
        <AddressSearch
          addressSearchResult={result}
          disabled={disabled}
          heading={<h3>{section.description}</h3>}
          initialPostalCodeValue={result?.postalCode}
          initialStreetAddressValue={result?.match?.addressText}
          onReset={useCallback(() => setResult(undefined), [])}
          onSearch={onSearch}
          onSubmit={onSubmit}
        />
      </OpenFormGridCol>
      <OpenFormGridCol colWidth={4} className="of-openform__address-check__image-container">
        <div className="of-openform__address-check__image-container--picture" aria-disabled={disabled} />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
