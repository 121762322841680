import { AuthenticatedUserRole, ChangeRequestStatus, SubscriptionType } from '../generated/api/models.js';
import { ModelType, SfToggles } from './enums.js';
import {
  activateOmalaskuPrivateMethod,
  cancelOmalaskuPrivateMethod,
  changeOwnerAccountPrivateMethod,
  changeSubscriptionBillingAccountPrivateMethod,
  changeSubscriptionMobilePbxPrivateMethod,
  checkDuplicateContactsPrivateMethod,
  createAdminUserPrivateMethod,
  createAnonymousLeadPublicMethod,
  createAnonymousSupportCasePublicMethod,
  createBillingAccountPrivateMethod,
  createContactPrivateMethod,
  createDnsRecordPrivateMethod,
  createShoppingCartPrivateMethod,
  createSupportCasePrivateMethod,
  deleteAdminUserPrivateMethod,
  deleteDnsRecordPrivateMethod,
  deleteDraftCatalogPrivateMethod,
  deleteOpenFormDraftsPrivateMethod,
  deprecateVirtualCatalogPrivateMethod,
  domainGetPublicMethod,
  elisaIdV2LogOutPrivateMethod,
  enableMfaPrivateMethod,
  generateNumbersPrivateMethod,
  generateReportPrivateMethod,
  getAccountKeyUsersByServiceAgreementIdPrivateMethod,
  getAddOnRulesPublicMethod,
  getAddOnVisibilityPrivateMethod,
  getAddOnVisibilityPublicMethod,
  getBillChannelsPublicMethod,
  getBillingAccountScheduledChangePrivateMethod,
  getBillingAccountsPrivateMethod,
  getChatHistoryPublicMethod,
  getContactsPrivateMethod,
  getContractsPrivateMethod,
  getCustomerOrderAdditionalInfoPrivateMethod,
  getCustomerOrdersPrivateMethod,
  getDiscountedPricesPrivateMethod,
  getDnsRecordHistoryPrivateMethod,
  getDnsRecordsHistoryPrivateMethod,
  getDnsRecordsPrivateMethod,
  getDocumentsPrivateMethod,
  getExternalAuthenticationMethodsPublicMethod,
  getGeneratedReportPrivateMethod,
  getHolidaysPublicMethod,
  getInvoicesPrivateMethod,
  getLicenseManagementAccountsPrivateMethod,
  getMfaDetailsPrivateMethod,
  getMonthlyUsageDetailsPrivateMethod,
  getMyselfAccountPrivateMethod,
  getMyselfSecondaryAccountsPrivateMethod,
  getNumberRangePrivateMethod,
  getOmalaskuStatusPrivateMethod,
  getOnboardingRequestsPrivateMethod,
  getOnlineModelPublicMethod,
  getOnlineModelWithEffectivePricesPrivateMethod,
  getOnlineModelsPrivateMethod,
  getOnlineModelsPublicMethod,
  getOpenFormAccountPrivateMethod,
  getOpenFormAddressPrivateMethod,
  getOpenFormDraftItemListPrivateMethod,
  getOpenFormDraftPrivateMethod,
  getOpenFormItemListPrivateMethod,
  getOpenFormPrivateMethod,
  getOpenFormProductAvailabilityPrivateMethod,
  getOpenFormSummaryPrivateMethod,
  getOpenSupportCasesPrivateMethod,
  getPagePublicMethod,
  getReportsPrivateMethod,
  getSubscriptionActionsPrivateMethod,
  getSubscriptionAggregationsPrivateMethod,
  getSubscriptionPrivateMethod,
  getSubscriptionsPrivateMethod,
  getSupportCaseHistoryPrivateMethod,
  getSupportCasePrivateMethod,
  getSupportCasesPrivateMethod,
  getTrainingPublicMethod,
  getVirtualCatalogPrivateMethod,
  getVirtualCatalogsPrivateMethod,
  globalSearchPrivateMethod,
  hasPendingActionsForContactPrivateMethod,
  licenseManagementUrlPrivateMethod,
  parameterizedSearchContactsPrivateMethod,
  postChatConversationPublicMethod,
  postCreateInterworksAccountPrivateMethod,
  postFilePrivateMethod,
  postOpenFormCustomerNeedPrivateMethod,
  postOpenFormDraftPrivateMethod,
  postScheduledBillingAccountChangePrivateMethod,
  prepareElisaIdV2LoginPublicMethod,
  publishDraftCatalogPrivateMethod,
  putScheduledBillingAccountChangePrivateMethod,
  raiseAuthenticationLevelPrivateMethod,
  raiseAuthenticationLevelValidateOtpPrivateMethod,
  replaceVirtualCatalogsPrivateMethod,
  searchAddressPublicMethod,
  searchCompaniesPublicMethod,
  submitMoveContactPrivateMethod,
  updateAccountKeyUserPrivateMethod,
  updateBillingAccountPrivateMethod,
  updateContactPrivateMethod,
  updateDnsRecordPrivateMethod,
  updateDraftCatalogPrivateMethod,
  updateMyselfAccountPrivateMethod,
  updateShoppingCartPrivateMethod,
  validateAvailabilityOfNumbersPrivateMethod,
  validateMoveContactPrivateMethod,
  validatePublicMethod,
} from '../generated/api/uiApiMethods.js';
import { convertToCommonErrors } from './utils/errorUtils';
import { createRedirectURL } from './utils/urlUtils.js';
import { emptyAddOnRulesResponse } from './utils/addOnRulesUtils.js';
import { getActiveAccountMasterId, getAiChatSessionId } from '../selfservice/common/localStorageUtils.js';
import { getNumberRangeData } from '../components/AttachPbxContent/attachPbxUtils';
import { getSubscriptionTypes } from '../public/common/util/category';
import type {
  AccountKeyUsersResponse,
  AddOnRulesResponse,
  AddOnVisibilityResponse,
  AiChatResponse,
  AuthenticationMethod,
  BillingAccount,
  BillingAccountScheduledChangeResponse,
  BillingAccountScheduledChangeState,
  BillingAccountsResponse,
  Catalog,
  CompanyInfoResponse,
  Contact,
  ContactsDuplicateCheckRequest,
  ContactsDuplicateCheckResponse,
  ContactsResponse,
  Contract,
  ContractsResponse,
  CreateInterworksAccountRequest,
  CustomerOrderAdditionalInfo,
  CustomerOrdersResponse,
  DiscountedPricesResponse,
  DnsRecordRequest,
  DnsRecordsHistoryResponse,
  DnsRecordsResponse,
  EasyAddress,
  ElisaIdV2LogoutResponse,
  HolidaysResponse,
  InvoiceDocumentsResponse,
  InvoicesResponse,
  LicenseManagementAccount,
  MfaDetailsResponse,
  MonthlyUsageDetailsResponse,
  MoveContactRequest,
  MoveContactResponse,
  MoveContactValidateRequest,
  MoveContactValidateResponse,
  NewNumbersResponse,
  OFProductAvailability,
  OFReferenceNumber,
  OFReferenceNumbers,
  OmalaskuActivationRequest,
  OnboardingRequestsResponse,
  OnlineModel,
  OnlineModelsResponse,
  OpenFormAccountResponse,
  OpenFormCustomerNeedRequest,
  OpenFormDraft,
  OpenFormDraftItem,
  OpenFormDraftUpsert,
  OpenFormListItem,
  OpenFormResponse,
  OpenFormSummaryResponse,
  PageResponse,
  ParameterizedSearchContactResults,
  PostAnonymousLeadRequest,
  PostFileResponse,
  PrepareElisaIdV2LoginResponse,
  PutContactResponse,
  PutSupportCaseResponse,
  ReportItemResponse,
  ReportType,
  SecondaryAccount,
  ShoppingCart,
  ShoppingCartResponse,
  Subscription,
  SubscriptionAction,
  SubscriptionActionsResponse,
  SubscriptionAggregationsResponse,
  SubscriptionChangeOwnerAccountRequest,
  SubscriptionPbxConfiguration,
  SubscriptionsResponse,
  SupportCase,
  SupportCaseDetails,
  SupportCasesResponse,
  TrainingResponse,
  UpdateAccountKeyUserRequest,
  UpdateAccountRequest,
  ValidateAvailabilityOfNumbersRequest,
  ValidateAvailabilityOfNumbersResponse,
  VirtualCatalogReplaceRequest,
  VirtualCatalogReplaceResponse,
  VirtualCatalogResponse,
} from '../generated/api/models.js';
import type { AddOnVisibilityOperationType, SubscriptionCategory } from './enums.js';
import type { AddressSearchResult } from '../components/AddressSearch/AddressSearch.js';
import type { ApiMethod, SortableLimitableQuery } from '../generated/api/uiApiMethods.js';
import type { ContactSupportInfo } from '../components/ContactSupportForm/index.js';
import type { DnsRecordsRequestQuery } from '../components/DnsManagement/dnsManagementUtils.js';
import type { LoaderFunctionArgs } from 'react-router-dom';
import type { NumberRangeData } from './loaders';
import type { OmalaskuStatusWithMsisdnResponse } from '../components/Omalasku/omalaskuUtils';
import type { SubscriptionsDeviceMultiFilter } from '../components/SubscriptionLists/SubscriptionTable.js';

export interface CustomerOrdersQuery extends SortableLimitableQuery {
  search?: string;
  status?: string;
  useSearchService?: boolean;
}

export interface SupportCasesQuery extends SortableLimitableQuery {
  search?: string;
  feature?: string;
  status?: string;
}

export interface VirtualCatalogsQuery extends SortableLimitableQuery {
  search?: string;
  searchAllAccounts?: boolean;
}

export interface BillingAccountsQuery extends SortableLimitableQuery {
  useSearchService?: boolean;
  search?: string;
  sourceSystem?: string;
  billingContactId?: string;
}

export interface SubscriptionActionsQuery extends SortableLimitableQuery {
  search?: string;
}

export interface InvoicesQuery extends SortableLimitableQuery {
  balanceRange?: string;
  search?: string;
  useSearchService?: boolean;
  category?: string;
  openInvoicesOnly?: boolean;
}

export interface InvoiceDocumentsQuery extends SortableLimitableQuery {
  useSearchService?: boolean;
  search?: string;
}

interface CallUiApiOpts {
  body?: BodyInit;
  headers?: HeadersInit;
  signal?: AbortSignal;
  mdmId?: string;
  useDefaultMdmId?: boolean;
  noThrow?: boolean;
  jsonContent?: boolean;
}

const callUiApi = async (apiCall: ApiMethod, opts?: CallUiApiOpts) => {
  const { body, signal, useDefaultMdmId = true, noThrow = false, jsonContent = true } = opts ?? {};
  const { path, verb: method } = apiCall;
  const mdmId = opts?.mdmId ?? (useDefaultMdmId ? getActiveAccountMasterId() : undefined);
  const headers = {
    ...opts?.headers,
    ...(jsonContent ? { 'Content-Type': 'application/json' } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    ...(mdmId ? { 'X-API-Account-Master-ID': mdmId } : {}), // eslint-disable-line @typescript-eslint/naming-convention
    ...(mdmId ? { 'X-Elisa-Company-MDM-ID': mdmId } : {}), // eslint-disable-line @typescript-eslint/naming-convention
  };

  const init = { body, method, headers, signal };
  const res = await fetch(path, init);

  if (noThrow || res.ok) {
    return res;
  } else {
    throw res;
  }
};

const callUiApiForEmployee = async (apiCall: ApiMethod, opts?: CallUiApiOpts) => {
  const headers = {
    ...opts?.headers,
    ...{ 'X-API-User-Role': AuthenticatedUserRole.EMPLOYEE }, // eslint-disable-line @typescript-eslint/naming-convention
  };
  return await callUiApi(apiCall, { ...opts, headers });
};

/**
 * Create only functions in this file that call fetch and use functions from uiApiMethods
 * Do not call these functions inside Epics
 * Do not mix fetch usages with redux usages
 */

export const fetchCompanyList = async (query: string) => {
  return (await (await callUiApi(searchCompaniesPublicMethod({ query }))).json()).result;
};

export const fetchTrainings = async (query: string): Promise<TrainingResponse> => {
  return (await callUiApi(getTrainingPublicMethod(query))).json();
};

export const fetchAddressSearch = async (
  query: { query: string; postalCode: string; filter: boolean },
  opts?: CallUiApiOpts
): Promise<AddressSearchResult> => {
  return (await callUiApi(searchAddressPublicMethod(query), opts)).json();
};

export const fetchOpenFormAccount = async (opts?: CallUiApiOpts): Promise<OpenFormAccountResponse> => {
  return (await callUiApi(getOpenFormAccountPrivateMethod(), opts)).json();
};

export const fetchOpenFormAddress = async (
  query: { addressId: string },
  opts?: CallUiApiOpts
): Promise<EasyAddress> => {
  return (await callUiApi(getOpenFormAddressPrivateMethod(query), opts)).json();
};

export const fetchOpenFormProductAvailability = async (
  query: { addressId: string; formId: string; subscriptionTypes: string[] },
  opts?: CallUiApiOpts
): Promise<OFProductAvailability[]> => {
  return (await callUiApi(getOpenFormProductAvailabilityPrivateMethod(query), opts)).json();
};

export const fetchOpenFormDraft = async (
  query: { referenceNumber: string },
  opts?: CallUiApiOpts
): Promise<OpenFormDraft> => {
  return (await callUiApi(getOpenFormDraftPrivateMethod(query.referenceNumber), opts)).json();
};

export const fetchOpenFormDraftItemList = async (opts?: CallUiApiOpts): Promise<OpenFormDraftItem[]> => {
  return (await callUiApi(getOpenFormDraftItemListPrivateMethod(), opts)).json();
};

export const fetchOpenFormItemList = async (opts?: CallUiApiOpts): Promise<OpenFormListItem[]> => {
  return (await callUiApi(getOpenFormItemListPrivateMethod(), opts)).json();
};

export const fetchOpenFormSummary = async (
  query: { choices: string[] },
  opts?: CallUiApiOpts
): Promise<OpenFormSummaryResponse> => {
  return (await callUiApi(getOpenFormSummaryPrivateMethod(query), opts)).json();
};

export const fetchOpenForm = async (query: { formId: string }, opts?: CallUiApiOpts): Promise<OpenFormResponse> => {
  return (await callUiApi(getOpenFormPrivateMethod(query), opts)).json();
};

export const postOpenForm = async (
  query: OpenFormCustomerNeedRequest,
  opts?: CallUiApiOpts
): Promise<OFReferenceNumber> => {
  return (await callUiApi(postOpenFormCustomerNeedPrivateMethod(), { ...opts, body: JSON.stringify(query) })).json();
};

export const postOpenFormDraft = async (
  query: OpenFormDraftUpsert,
  opts?: CallUiApiOpts
): Promise<OFReferenceNumber> => {
  return (await callUiApi(postOpenFormDraftPrivateMethod(), { ...opts, body: JSON.stringify(query) })).json();
};

export const deleteOpenFormDrafts = async (
  query: { referenceNumbers: string[] },
  opts?: CallUiApiOpts
): Promise<OFReferenceNumbers> => {
  return (await callUiApi(deleteOpenFormDraftsPrivateMethod(query), opts)).json();
};

export const fetchPublicPage = async (path: string): Promise<PageResponse> => {
  return (await callUiApi(getPagePublicMethod({ path }), { headers: { redirect: 'manual' } })).json();
};

export const fetchPublicPageLoader = async (args: LoaderFunctionArgs): Promise<PageResponse> => {
  const { origin, pathname } = new URL(args.request.url);
  // Decode scandic letters; otherwise, getPagePublicMethod double encodes it
  const apiCall = getPagePublicMethod({ path: decodeURIComponent(pathname) });
  apiCall.path = origin + apiCall.path;
  const res = await callUiApi(apiCall, {
    headers: {
      redirect: 'manual',
      ['Cookie']: args.request.headers?.get('Cookie') ?? '', // Pass-on cookies which are received by this loader either from koa-server during SSR rendering or otherwise. When the api is invoked, cookies are then sent to gateway (varnish -> nginx) -> ui-api.
    },
    useDefaultMdmId: false,
    noThrow: true,
  });

  if (res.status === 204) {
    throw new Response(res.body, {
      status: 301,
      headers: new Headers({ location: createRedirectURL(args, res) }),
    });
  }
  if (res.ok) {
    return res.json();
  }
  const status = res.status === 503 ? 503 : 404;
  throw new Response(res.body, { status, statusText: res.statusText });
};

export const createAdminUser = (contact: Contact, mdmId?: string) => {
  return callUiApi(createAdminUserPrivateMethod(contact.contactId!), {
    body: JSON.stringify(contact),
    mdmId,
  });
};

export const upsertContactUser = (
  contact: Contact,
  forceUpsert: boolean,
  bothNamesChanged?: boolean,
  noThrow?: boolean,
  mdmId?: string
) => {
  if (contact.contactId) {
    const apiCall = updateContactPrivateMethod(contact.contactId, { forceUpsert, bothNamesChanged });
    return callUiApi(apiCall, { body: JSON.stringify(contact), noThrow: noThrow, mdmId });
  } else {
    return callUiApi(createContactPrivateMethod({ forceUpsert }), {
      body: JSON.stringify(contact),
      noThrow: noThrow,
      mdmId,
    });
  }
};

export const postContact = async (contact: Contact, mdmId?: string): Promise<PutContactResponse> => {
  return (
    await callUiApi(createContactPrivateMethod(), {
      body: JSON.stringify(contact),
      mdmId,
    })
  ).json();
};

export const deleteAdminUser = (contact: Contact, mdmId?: string) => {
  return callUiApi(deleteAdminUserPrivateMethod(contact.contactId!), { body: JSON.stringify(contact), mdmId });
};

export const fetchOnlineModelHeaders = async (productsWithTags: string[]): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPublicMethod({ headersOnly: true, tags: productsWithTags }))).json();
};

export const fetchCatalogOnlineModelHeaders = async (category: string): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPublicMethod({ headersOnly: true, category: category }))).json();
};

export const fetchOnlineModelsForCategory = async (
  category: string,
  headersOnly: boolean
): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPublicMethod({ category, headersOnly }))).json();
};

export const fetchOnlineModelForCode = async (modelCode: string): Promise<OnlineModel> => {
  return (await callUiApi(getOnlineModelPublicMethod(modelCode))).json();
};

export const fetchOnlineModelEffectivePriceForCode = async (modelCode: string, mdmId: string): Promise<OnlineModel> => {
  return (await callUiApi(getOnlineModelWithEffectivePricesPrivateMethod(modelCode), { mdmId })).json();
};

export const fetchOnlineModels = async (guids: string[]): Promise<OnlineModel[]> => {
  const responses = await Promise.all(
    guids.map(guid => callUiApi(getOnlineModelPublicMethod(guid), { noThrow: true }))
  );

  const result = await Promise.all(responses.map(r => (r.ok ? r.json() : undefined)));
  return result.filter(r => !!r);
};

export const fetchPrivateOnlineModels = async (query: {
  onlineModelCodes?: string[];
  category?: string;
  tags?: string[];
  voucherCode?: string;
}): Promise<OnlineModelsResponse> => {
  return (await callUiApi(getOnlineModelsPrivateMethod(query))).json();
};

export const fetchDomains = async (query: string) => {
  return (await callUiApi(domainGetPublicMethod({ query }))).json();
};

export const fetchDnsRecords = async (subId: string, query: DnsRecordsRequestQuery): Promise<DnsRecordsResponse> => {
  return (await callUiApi(getDnsRecordsPrivateMethod(subId, query))).json();
};

export const fetchDnsRecordsHistory = async (
  subId: string,
  query: DnsRecordsRequestQuery
): Promise<DnsRecordsHistoryResponse> => {
  return (await callUiApi(getDnsRecordsHistoryPrivateMethod(subId, query))).json();
};

export const fetchDnsRecordHistory = async (subId: string, historyId: number): Promise<DnsRecordsHistoryResponse> => {
  return (await callUiApi(getDnsRecordHistoryPrivateMethod(historyId, subId))).json();
};

export const deleteDnsRecord = async (subscriptionId: string, recordId: number) => {
  return callUiApi(deleteDnsRecordPrivateMethod(recordId, subscriptionId));
};

export const updateDnsRecord = async (subscriptionId: string, recordId: number, payload: DnsRecordRequest) => {
  return callUiApi(updateDnsRecordPrivateMethod(recordId, subscriptionId), { body: JSON.stringify(payload) });
};

export const createDnsRecord = async (subscriptionId: string, payload: DnsRecordRequest) => {
  return callUiApi(createDnsRecordPrivateMethod(subscriptionId), { body: JSON.stringify(payload) });
};

export const postAnonymousLead = (body: PostAnonymousLeadRequest) => {
  return callUiApi(createAnonymousLeadPublicMethod(), {
    body: JSON.stringify(body),
    useDefaultMdmId: false,
    noThrow: true,
  });
};

export const fetchAddOnRules = async (subscriptionType?: string): Promise<AddOnRulesResponse> => {
  const apiCall = getAddOnRulesPublicMethod({ subscriptionType });
  const res = await callUiApi(apiCall, { useDefaultMdmId: false, noThrow: true });

  if (res.ok) {
    return res.json();
  }

  // If add-on rules cannot be fetched, the add-on cannot be sold but the main article is still potentially available for purchase. By returning an empty response, buying the main article is still possible.
  return { ...emptyAddOnRulesResponse };
};

export const fetchNewSalesAddOnsVisibilities = async (cpCode: string): Promise<AddOnVisibilityResponse> => {
  const apiCall = getAddOnVisibilityPublicMethod(cpCode, { operationType: 'NEW_SALES' });
  const res = await callUiApi(apiCall, { useDefaultMdmId: false, noThrow: true });

  if (res.ok) {
    return res.json();
  }

  // If add-on visibilities cannot be fetched, we cannot decide which add-ons are available for selection. The main article is still potentially available for purchase. By returning an empty response, buying the main article is still possible.
  return {
    total: 0,
    addOnVisibilities: [],
  };
};

export const fetchAddOnsVisibilities = async (
  cpCode: string,
  operationType: AddOnVisibilityOperationType,
  mdmId?: string
): Promise<AddOnVisibilityResponse> => {
  return (await callUiApi(getAddOnVisibilityPrivateMethod(cpCode, { operationType: operationType }), { mdmId })).json();
};

export const postFile = async (file: File): Promise<PostFileResponse> => {
  const body = new FormData();
  body.append('fileData', file);
  return (await callUiApi(postFilePrivateMethod(), { body, jsonContent: false })).json();
};

export const fetchReports = async (): Promise<ReportItemResponse[]> => {
  return (await callUiApi(getReportsPrivateMethod(), { useDefaultMdmId: false })).json();
};

export const generateReport = (reportType: ReportType, mdmIds?: string[]) => {
  return callUiApi(generateReportPrivateMethod(reportType), {
    noThrow: true,
    useDefaultMdmId: false,
    body: JSON.stringify({ accountMasterIds: mdmIds }),
  });
};

export const downloadReport = (reportId: string, onlineReportId: string) => {
  return callUiApi(getGeneratedReportPrivateMethod(reportId, { onlineReportId }), { noThrow: true });
};

export const fetchBillingAccount = async (
  billingAccountDisplayId: string,
  mdmId: string
): Promise<BillingAccountsResponse> => {
  const apiCall = getBillingAccountsPrivateMethod({ offset: 0, billingAccountDisplayId });
  return (await callUiApi(apiCall, { mdmId })).json();
};

export const fetchBillingAccounts = async (
  query?: BillingAccountsQuery,
  opts?: CallUiApiOpts
): Promise<BillingAccountsResponse> => {
  const apiCall = getBillingAccountsPrivateMethod(query);
  return (
    await callUiApi(apiCall, {
      ...opts,
      useDefaultMdmId: false,
    })
  ).json();
};

export const replaceCatalogs = async (
  payload: VirtualCatalogReplaceRequest
): Promise<VirtualCatalogReplaceResponse> => {
  return (await callUiApi(replaceVirtualCatalogsPrivateMethod(), { body: JSON.stringify(payload) })).json();
};

export const createBillingAccount = async (billingAccount: BillingAccount, mdmId?: string) => {
  return (await callUiApi(createBillingAccountPrivateMethod(), { body: JSON.stringify(billingAccount), mdmId })).json();
};

export const updateBillingAccount = async (billingAccountId: string, billingAccount: BillingAccount) => {
  return (
    await callUiApi(updateBillingAccountPrivateMethod(billingAccountId), { body: JSON.stringify(billingAccount) })
  ).json();
};

export const fetchBillChannels = async (opts?: CallUiApiOpts) => {
  return (await callUiApi(getBillChannelsPublicMethod(), opts)).json();
};

export interface GlobalSearchQuery {
  query: string;
  searchAllAccounts?: boolean;
  searchType?: string[];
}

export const fetchGlobalSearch = async ({ query, searchAllAccounts, searchType }: GlobalSearchQuery) => {
  const apiCall = globalSearchPrivateMethod({ query, searchType });
  return (await callUiApi(apiCall, { useDefaultMdmId: !searchAllAccounts })).json();
};

export const fetchSubscriptionAggregates = async (
  subscriptionCategory: SubscriptionCategory,
  request: Request,
  mdmId?: string
): Promise<SubscriptionAggregationsResponse> => {
  const params = new URLSearchParams(new URL(request.url).search);
  const search = params.get('search') || undefined;
  const apiCall = getSubscriptionAggregationsPrivateMethod({ subscriptionCategory, search });
  return (
    await callUiApi(apiCall, {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export type SubscriptionsQuery = SortableLimitableQuery &
  SubscriptionsDeviceMultiFilter & {
    details?: boolean;
    search?: string;
    subscriptionType?: string[];
    useSearchService?: boolean;
    subscriptionContactId?: string;
  };

const fetchSubscriptionByDisplayId = async (
  category: SubscriptionCategory,
  subscriptionDisplayId: string,
  mdmId: string
): Promise<Subscription> => {
  const subscriptionTypes = getSubscriptionTypes(category);
  const response = await callUiApi(
    getSubscriptionsPrivateMethod({
      offset: 0,
      details: true,
      subscriptionType: subscriptionTypes,
      subscriptionDisplayId,
    }),
    {
      mdmId,
    }
  );
  const subscriptionsResponse: SubscriptionsResponse = await response.json();
  const subscription = subscriptionsResponse?.subscriptions?.[0];
  if (!subscription) {
    throw new Response('Subscription not found', { status: 404 });
  }
  return subscription;
};

const fetchSubscriptionById = async (subscriptionId: string, mdmId: string): Promise<Subscription> => {
  const response = await callUiApi(getSubscriptionPrivateMethod(subscriptionId), {
    mdmId,
  });
  return await response.json();
};

export const fetchSubscription = async (
  category: SubscriptionCategory,
  subscriptionId: string,
  mdmId: string
): Promise<Subscription> => {
  if (subscriptionId.startsWith('SUB')) {
    return fetchSubscriptionByDisplayId(category, subscriptionId, mdmId);
  }
  return fetchSubscriptionById(subscriptionId, mdmId);
};

export const fetchSubscriptions = async (query: SubscriptionsQuery, mdmId?: string): Promise<SubscriptionsResponse> => {
  return (
    await callUiApi(getSubscriptionsPrivateMethod(query), {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export const fetchEmployeeSubscriptions = async (query: SubscriptionsQuery): Promise<SubscriptionsResponse> => {
  return (await callUiApiForEmployee(getSubscriptionsPrivateMethod(query))).json();
};

export type ContactFetchQuery = SortableLimitableQuery & {
  search?: string;
  useSearchService?: boolean;
};

export const fetchContacts = async (query: ContactFetchQuery, opts?: CallUiApiOpts): Promise<ContactsResponse> => {
  return (
    await callUiApi(getContactsPrivateMethod(query), {
      ...opts,
      useDefaultMdmId: false,
    })
  ).json();
};

export const parameterizedSearchContacts = async (
  query: { searchTerm: string },
  opts?: CallUiApiOpts
): Promise<ParameterizedSearchContactResults> => {
  return (await callUiApi(parameterizedSearchContactsPrivateMethod(query), opts)).json();
};

export const fetchExternalAuthMethods = async (): Promise<AuthenticationMethod[] | 'failed'> => {
  try {
    return (await (await callUiApi(getExternalAuthenticationMethodsPublicMethod())).json()).authenticationMethods;
  } catch {
    // This is a special case, we want to have graceful degraded functionality. The extAuthMethods come from 3rd party, if that system has issues we don't want it to impact online-ui more than it has to.
    return 'failed';
  }
};

export const fetchContact = async ({
  contactMasterId,
  mdmId,
}: {
  contactMasterId: string;
  mdmId: string;
}): Promise<Contact> => {
  const response = await callUiApi(getContactsPrivateMethod({ contactMasterId }), { mdmId });
  const contactsResponse: ContactsResponse = await response.json();
  const contact = contactsResponse?.contacts?.[0];
  if (!contact) {
    throw new Response('Contact not found', { status: 404 });
  }
  return contact;
};

export const requestMfaOtp = async () => {
  return callUiApi(raiseAuthenticationLevelPrivateMethod());
};

export const sendMfaOtp = async (otp: string): Promise<boolean> => {
  const res = await callUiApi(raiseAuthenticationLevelValidateOtpPrivateMethod(otp), { noThrow: true });

  if (res.ok) {
    return true;
  } else if (res.status === 400) {
    return false;
  }

  throw new Error(res.statusText);
};

export const validateNumbers = async (
  payload: ValidateAvailabilityOfNumbersRequest
): Promise<ValidateAvailabilityOfNumbersResponse> => {
  return (await callUiApi(validateAvailabilityOfNumbersPrivateMethod(), { body: JSON.stringify(payload) })).json();
};

export type GenerateNumbersQuery = {
  count: number;
};

export const generateNumbers = async (query: GenerateNumbersQuery): Promise<NewNumbersResponse> => {
  return (await callUiApi(generateNumbersPrivateMethod(query))).json();
};

export const createSupportCaseAnonymous = async (
  supportCase: SupportCase,
  contactDetails: ContactSupportInfo,
  reCaptchaResponse?: string
) => {
  const body = JSON.stringify({ supportCase, contactDetails, reCaptchaResponse });
  return (await callUiApi(createAnonymousSupportCasePublicMethod(), { body: body })).json();
};

export const createSupportCaseAuthenticated = async (
  supportCase: SupportCase,
  mdmId: string
): Promise<PutSupportCaseResponse> =>
  (
    await callUiApi(createSupportCasePrivateMethod(), {
      body: JSON.stringify(supportCase),
      mdmId,
      useDefaultMdmId: false,
    })
  ).json();

export const fetchSupportCase = async (supportCaseDisplayId: string, mdmId: string) => {
  const apiCall = getSupportCasePrivateMethod(supportCaseDisplayId);
  return (await callUiApi(apiCall, { mdmId })).json();
};

export const fetchSupportCases = async (query: SupportCasesQuery, mdmId?: string): Promise<SupportCasesResponse> => {
  return (
    await callUiApi(getSupportCasesPrivateMethod(query), {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export const fetchSupportCaseHistory = async (
  supportCaseDisplayId: string,
  mdmId: string
): Promise<SupportCaseDetails> => {
  const apiCall = getSupportCaseHistoryPrivateMethod(supportCaseDisplayId);
  return (await callUiApi(apiCall, { mdmId })).json();
};

export const fetchOpenSupportCases = async (
  query?: SupportCasesQuery,
  mdmId?: string
): Promise<SupportCasesResponse> => {
  return (
    await callUiApi(getOpenSupportCasesPrivateMethod(query), {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export const fetchCustomerOrder = async (
  customerOrderDisplayId: string,
  mdmId: string
): Promise<CustomerOrdersResponse> => {
  return (
    await callUiApi(getCustomerOrdersPrivateMethod({ offset: 0, customerOrderDisplayId }), {
      mdmId,
    })
  ).json();
};

export const fetchCustomerOrders = async (
  query: CustomerOrdersQuery,
  mdmId?: string
): Promise<CustomerOrdersResponse> => {
  return (
    await callUiApi(getCustomerOrdersPrivateMethod(query), {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export const fetchCustomerOrderAdditionalInfo = async (
  customerOrderId: string,
  mdmId: string
): Promise<CustomerOrderAdditionalInfo> => {
  return (
    await callUiApi(getCustomerOrderAdditionalInfoPrivateMethod(customerOrderId), {
      mdmId,
    })
  ).json();
};

export const fetchBillingAccountScheduledChange = async (
  billingAccountId: string
): Promise<BillingAccountScheduledChangeResponse> => {
  return (await callUiApi(getBillingAccountScheduledChangePrivateMethod(billingAccountId))).json();
};

export const scheduleBillingAccountChange = async (
  billingAccountId: string,
  scheduledChangeTimestamp: number,
  billingAccount: BillingAccount
): Promise<BillingAccountScheduledChangeState> => {
  const apiCall = postScheduledBillingAccountChangePrivateMethod(billingAccountId);
  return (await callUiApi(apiCall, { body: JSON.stringify({ billingAccount, scheduledChangeTimestamp }) })).json();
};

export const reScheduleBillingAccountChange = async (
  billingAccountId: string,
  scheduledChangState: BillingAccountScheduledChangeState
): Promise<BillingAccountScheduledChangeState> => {
  const body: BillingAccountScheduledChangeState = {
    billingAccountId,
    changeRequestId: scheduledChangState.changeRequestId,
    scheduledChangeTimestamp: scheduledChangState.scheduledChangeTimestamp,
  };
  return (
    await callUiApi(putScheduledBillingAccountChangePrivateMethod(billingAccountId), { body: JSON.stringify(body) })
  ).json();
};

export const cancelChangeRequest = async (
  billingAccountId: string,
  changeRequestId: string
): Promise<BillingAccountScheduledChangeState> => {
  const body: BillingAccountScheduledChangeState = {
    billingAccountId,
    changeRequestId,
    changeRequestStatus: ChangeRequestStatus.CANCELLED,
  };
  return (
    await callUiApi(putScheduledBillingAccountChangePrivateMethod(billingAccountId), { body: JSON.stringify(body) })
  ).json();
};

export const fetchSubscriptionAction = async (
  subscriptionActionDisplayId: string,
  mdmId: string
): Promise<SubscriptionActionsResponse> => {
  const apiCall = getSubscriptionActionsPrivateMethod({ subscriptionActionDisplayId });
  return (await callUiApi(apiCall, { mdmId })).json();
};

export const fetchSubscriptionActions = async (
  query: SubscriptionActionsQuery,
  mdmId?: string
): Promise<SubscriptionActionsResponse> => {
  const apiCall = getSubscriptionActionsPrivateMethod(query);
  return (
    await callUiApi(apiCall, {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export const fetchVirtualCatalog = async (catalogCode: string, mdmId: string) => {
  const apiCall = getVirtualCatalogPrivateMethod(catalogCode);
  return (await callUiApi(apiCall, { mdmId })).json();
};

export const fetchVirtualCatalogs = async (
  query: VirtualCatalogsQuery,
  mdmId?: string
): Promise<VirtualCatalogResponse> => {
  const apiCall = getVirtualCatalogsPrivateMethod(query);
  return (
    await callUiApi(apiCall, {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export const deprecateVirtualCatalog = async (virtualCatalogCode: string, mdmId?: string) => {
  return callUiApi(deprecateVirtualCatalogPrivateMethod(virtualCatalogCode), {
    mdmId,
    useDefaultMdmId: false,
  });
};

export const putDraftCatalog = async (virtualCatalogCode: string, catalog: Catalog, mdmId?: string) => {
  return await (
    await callUiApi(updateDraftCatalogPrivateMethod(virtualCatalogCode, catalog.catalogCode!), {
      body: JSON.stringify(catalog),
      mdmId,
    })
  ).json();
};

export const publishDraftCatalog = async (virtualCatalogCode: string, payload: Catalog, mdmId?: string) => {
  return callUiApi(publishDraftCatalogPrivateMethod(virtualCatalogCode), { body: JSON.stringify(payload), mdmId });
};

export const deleteVirtualCatalogDraft = async (virtualCatalogCode: string, catalogCode: string, mdmId?: string) => {
  return callUiApi(deleteDraftCatalogPrivateMethod(virtualCatalogCode, catalogCode), { mdmId });
};

export const fetchCompanyInfo = async (mdmId: string): Promise<CompanyInfoResponse> => {
  return (await callUiApi(getMyselfAccountPrivateMethod(), { mdmId })).json();
};

export const fetchDiscountedPrices = async (mdmId: string): Promise<DiscountedPricesResponse> => {
  return (await callUiApi(getDiscountedPricesPrivateMethod(), { mdmId })).json();
};

export const fetchSecondaryAccounts = async (mdmId: string): Promise<SecondaryAccount[]> => {
  return (await callUiApi(getMyselfSecondaryAccountsPrivateMethod(), { mdmId })).json();
};

export const fetchCompanyInfoAndEnabledOnlineModels = async (
  mdmId: string
): Promise<{ companyInfo: CompanyInfoResponse; onlineModels: OnlineModel[] }> => {
  const accountRes = await callUiApi(getMyselfAccountPrivateMethod(), {
    mdmId,
  });
  const companyInfo = await accountRes.json();

  const uiOptions = companyInfo?.uiOptions as { toggles?: string[] };
  const onlineModels = await Promise.all(
    uiOptions?.toggles
      ?.map(toggle => {
        if (toggle === SfToggles.SHOW_YRITYSPUHE) {
          return ModelType.VoiceYrityspuhe;
        } else if (toggle === SfToggles.SHOW_PERUSLIITTYMA_4G) {
          return ModelType.VoicePerusliittyma4g;
        } else if (toggle === SfToggles.SHOW_YRITYSPAKETTI) {
          return ModelType.Yrityspaketti;
        } else if (toggle === SfToggles.SHOW_YRITYSLIITTYMA_OLD) {
          return ModelType.Voice;
        }
        return undefined;
      })
      .filter(Boolean)
      .map(async code => {
        const model = (await callUiApi(getOnlineModelPublicMethod(code!))).json();
        return model;
      }) || []
  );

  if (
    companyInfo?.pbxSolutions?.filter(
      (solution: Subscription) => solution.subscriptionType === SubscriptionType.MOBILE_PBX
    ).length > 0
  ) {
    const response = await callUiApi(validatePublicMethod(), { mdmId });
    const loginResponse = await response.json();
    const ringModel = loginResponse.result.customerLevelPriceOrDiscount
      ? await callUiApi(getOnlineModelWithEffectivePricesPrivateMethod(ModelType.Ring), { mdmId })
      : await callUiApi(getOnlineModelPublicMethod(ModelType.Ring));
    onlineModels.push(await ringModel.json());
  }

  return {
    companyInfo,
    onlineModels,
  };
};

export const updateCompanyInfo = async (updateRequest: UpdateAccountRequest, mdmId: string) => {
  return await callUiApi(updateMyselfAccountPrivateMethod(), { body: JSON.stringify(updateRequest), mdmId });
};

export const getMessagesFromChatHistory = async (sessionId: string) => {
  return await callUiApi(getChatHistoryPublicMethod({ sessionId }));
};

export const postMessageToChatConversation = async (message: string): Promise<AiChatResponse> => {
  return (
    await callUiApi(postChatConversationPublicMethod(), {
      body: JSON.stringify({ message, sessionId: getAiChatSessionId() || '' }),
    })
  ).json();
};

export const createInterworksRedirectUrl = async (iwAccountId: number, accountMasterId: string) => {
  return (
    await callUiApi(licenseManagementUrlPrivateMethod({ accountId: iwAccountId }), { mdmId: accountMasterId })
  ).json();
};

export const createInterworksAccount = async (
  request: CreateInterworksAccountRequest,
  accountMasterId: string
): Promise<number> => {
  return (
    await callUiApi(postCreateInterworksAccountPrivateMethod(), {
      body: JSON.stringify(request),
      mdmId: accountMasterId,
    })
  ).json();
};

export const fetchLicenseManagementAccounts = async (accountMasterId: string): Promise<LicenseManagementAccount[]> => {
  return (
    await callUiApi(getLicenseManagementAccountsPrivateMethod(), {
      mdmId: accountMasterId,
    })
  ).json();
};

export const fetchInvoice = async (invoiceDisplayId: string, mdmId: string): Promise<InvoicesResponse> => {
  return (
    await callUiApi(getInvoicesPrivateMethod({ offset: 0, invoiceDisplayId }), {
      mdmId,
    })
  ).json();
};

export const fetchInvoices = async (query?: InvoicesQuery, mdmId?: string): Promise<InvoicesResponse> => {
  return (
    await callUiApi(getInvoicesPrivateMethod(query), {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export const fetchInvoiceDocuments = async (
  query?: InvoiceDocumentsQuery,
  mdmId?: string
): Promise<InvoiceDocumentsResponse> => {
  return (
    await callUiApi(getDocumentsPrivateMethod(query), {
      useDefaultMdmId: false,
      mdmId,
    })
  ).json();
};

export const createShoppingBasket = async (shoppingBasket: ShoppingCart): Promise<ShoppingCartResponse> => {
  return (await callUiApi(createShoppingCartPrivateMethod(), { body: JSON.stringify(shoppingBasket) })).json();
};

export const updateShoppingBasket = async (
  shoppingBasketId: string,
  shoppingBasket: ShoppingCart
): Promise<ShoppingCartResponse> => {
  return (
    await callUiApi(updateShoppingCartPrivateMethod(shoppingBasketId), { body: JSON.stringify(shoppingBasket) })
  ).json();
};

export const changeSubscriptionBillingAccount = async (
  subscriptionId: string,
  billingAccountId: string,
  mdmId: string
) => {
  const payload = { billingAccountId };
  return (
    await callUiApi(changeSubscriptionBillingAccountPrivateMethod(subscriptionId), {
      body: JSON.stringify(payload),
      mdmId,
    })
  ).json();
};

// TODO: Replace the endpoint called, after the new SFDC API is ready: https://atlas.elisa.fi/jira/browse/OFI-54345
export const fetchPendingSubscriptionActions = async (mdmId: string): Promise<Array<SubscriptionAction>> => {
  const response = await callUiApi(validatePublicMethod(), {
    mdmId,
  });
  const loginResponse = await response.json();
  return loginResponse?.result?.pendingSubscriptionActions || [];
};

export const fetchDuplicateContactCheck = async (
  contactDuplicateCheck: ContactsDuplicateCheckRequest,
  mdmId?: string
): Promise<ContactsDuplicateCheckResponse> => {
  return (
    await callUiApi(checkDuplicateContactsPrivateMethod(), { body: JSON.stringify(contactDuplicateCheck), mdmId })
  ).json();
};

export const fetchHolidays = async (opts?: CallUiApiOpts): Promise<HolidaysResponse> => {
  return (await callUiApi(getHolidaysPublicMethod(), opts)).json();
};

export const fetchSubscriptionMonthlyUsageDetails = async (
  month: string,
  subscriptionId: string
): Promise<MonthlyUsageDetailsResponse> => {
  return (await callUiApi(getMonthlyUsageDetailsPrivateMethod(month, subscriptionId))).json();
};

export const fetchElisaIdV2LoginMetadata = async (): Promise<PrepareElisaIdV2LoginResponse> => {
  return (await callUiApi(prepareElisaIdV2LoginPublicMethod())).json();
};

export const fetchElisaIdV2LogoutMetadata = async (): Promise<ElisaIdV2LogoutResponse> => {
  return (await callUiApi(elisaIdV2LogOutPrivateMethod())).json();
};

export const fetchMfaDetails = async (): Promise<MfaDetailsResponse> => {
  return (await callUiApi(getMfaDetailsPrivateMethod())).json();
};

export const fetchSaUsers = async (saId: string): Promise<AccountKeyUsersResponse> => {
  return (await callUiApi(getAccountKeyUsersByServiceAgreementIdPrivateMethod(saId))).json();
};

export const updateSaUser = async (request: UpdateAccountKeyUserRequest) => {
  return await callUiApi(updateAccountKeyUserPrivateMethod(), { body: JSON.stringify(request) });
};

export const enableMfa = async (saId: string) => {
  return await callUiApi(enableMfaPrivateMethod(saId));
};

export const fetchNumberRange = async (rangeId: string, mdmId?: string): Promise<NumberRangeData> => {
  const res = await callUiApi(getNumberRangePrivateMethod(rangeId), { noThrow: true, mdmId });
  if (res.ok) {
    const numberResponse = await res.json();
    return getNumberRangeData(numberResponse, rangeId);
  } else if (res.status === 500 || res.status === 404) {
    // General failure, allows user to retry the loading
    return {
      rangeId: rangeId,
      errors: convertToCommonErrors(res.statusText, res.status),
    };
  } else {
    // Company possibly out of numbers
    const errors = await res.json();
    return {
      rangeId: rangeId,
      errors: convertToCommonErrors(errors[0].message, res.status, errors),
    };
  }
};

export const changeSubscriptionMobilePbx = async (
  payload: SubscriptionPbxConfiguration,
  subscriptionId: string,
  mdmId?: string
) =>
  (
    await callUiApi(changeSubscriptionMobilePbxPrivateMethod(subscriptionId), {
      body: JSON.stringify(payload),
      mdmId,
    })
  ).json();

export const postChangeOwnerAccount = async (
  subscriptionId: string,
  request: SubscriptionChangeOwnerAccountRequest,
  mdmId?: string
) => {
  return await callUiApi(changeOwnerAccountPrivateMethod(subscriptionId), { body: JSON.stringify(request), mdmId });
};

export const fetchOmalaskuStatus = async (msisdn: string): Promise<OmalaskuStatusWithMsisdnResponse> => {
  return (await callUiApiForEmployee(getOmalaskuStatusPrivateMethod(msisdn))).json().then(responseStatus => {
    return { ...responseStatus, msisdn };
  });
};

export const activateOmalasku = async (msisdn: string, request: OmalaskuActivationRequest): Promise<boolean> => {
  const res = await callUiApiForEmployee(activateOmalaskuPrivateMethod(msisdn), {
    body: JSON.stringify(request),
    noThrow: true,
  });
  if (res.ok) {
    return true;
  } else if (res.status === 403) {
    return false;
  }
  throw res;
};

export const cancelOmalasku = async (msisdn: string): Promise<void> => {
  await callUiApiForEmployee(cancelOmalaskuPrivateMethod(msisdn));
};

export const fetchMobileIdContracts = async (): Promise<ContractsResponse> => {
  return (await callUiApi(getContractsPrivateMethod(), { useDefaultMdmId: false })).json();
};

export const fetchMobileIdContractsByMdmId = async (mdmId: string): Promise<ContractsResponse> => {
  return (await callUiApi(getContractsPrivateMethod({ mdmId }))).json();
};

export const fetchMobileIdContract = async (contractDisplayId: string): Promise<Contract> => {
  const response = await callUiApi(getContractsPrivateMethod({ contractDisplayId }), { useDefaultMdmId: false });
  const contractsResponse: ContractsResponse = await response.json();
  const mobileIdContract = contractsResponse?.contracts?.[0];
  if (!mobileIdContract) {
    throw new Response('Contract not found', { status: 404 });
  }
  return mobileIdContract;
};

export const validateMoveContact = async (
  request: MoveContactValidateRequest,
  mdmId: string
): Promise<MoveContactValidateResponse> => {
  return (
    await callUiApi(validateMoveContactPrivateMethod(), {
      body: JSON.stringify(request),
      mdmId,
    })
  ).json();
};

export const moveContact = async (request: MoveContactRequest, mdmId: string): Promise<MoveContactResponse> => {
  return (
    await callUiApi(submitMoveContactPrivateMethod(), {
      body: JSON.stringify(request),
      mdmId,
    })
  ).json();
};

export const hasPendingActionsForContact = async (contactMasterId: string) => {
  return (await callUiApi(hasPendingActionsForContactPrivateMethod({ contactMasterId }))).json();
};

export const fetchOnboardingRequests = async (mdmId?: string): Promise<OnboardingRequestsResponse> => {
  return (await callUiApi(getOnboardingRequestsPrivateMethod(), { mdmId, useDefaultMdmId: false })).json();
};
