import { CompanySelector } from '../../components/CompanySelector/CompanySelector.js';
import { DialogWrapper } from '../../components/DialogWrapper/DialogWrapper.js';
import { cancelMsg, continueMsg, selectCompanyHeaderMsg, selectCompanyMsg, t } from '../../common/i18n/index.js';
import { getUserAccounts } from '../../components/Header/dynamic/headerFunctions.js';
import { setActiveAccount } from '../../selfservice/actions';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import type { DialogWrapperButton } from '../../components/DialogWrapper/DialogWrapper.js';

export interface SelectCompanyDialogProps {
  onCloseDialog: () => void;
  onContinue: (selectedMdmId: string) => void;
}

export const SelectCompanyDialog = ({ onCloseDialog, onContinue }: SelectCompanyDialogProps) => {
  const { authenticatedUser } = useAuth();
  const userAccounts = getUserAccounts(authenticatedUser);
  const activeAccount = userAccounts.find(account => account.active);
  const [activeMdmId, setActiveMdmId] = useState(activeAccount?.accountMasterId || '');
  const dispatch = useDispatch();

  const buttons: DialogWrapperButton[] = [
    {
      color: 'light',
      text: t.B2V1(cancelMsg),
      onClick: () => onCloseDialog(),
    },
    {
      text: t.I62A(continueMsg),
      onClick: () => {
        onContinue(activeMdmId);
      },
    },
  ];
  return (
    <DialogWrapper onCloseDialog={onCloseDialog} buttons={buttons} header={t.BR9A(selectCompanyHeaderMsg)}>
      <CompanySelector
        userAccounts={userAccounts}
        onInputChange={(_, option) => {
          setActiveMdmId(option.accountMasterId!);
          dispatch(setActiveAccount(option.accountMasterId!));
        }}
        labelText={t.JUY2(selectCompanyMsg)}
        labelWeightNormal
      />
    </DialogWrapper>
  );
};
