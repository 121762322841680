export class OpenFormCanvas {
  static #targetOrigin: string | undefined;

  static {
    if (typeof self !== 'undefined') {
      try {
        this.#targetOrigin = decodeURIComponent(new URLSearchParams(self.location.search).get('targetOrigin')!);
      } catch {
        this.#targetOrigin = self.origin;
      }
    }
  }

  static get #top(): Window {
    let target = self.top ?? self.parent;
    do {
      target = target.parent;
    } while (target.self !== target.parent);
    return target;
  }

  static postMessageAcknowledge<T>(target: T) {
    this.#postMessage({ name: 'OpenFormCanvasEvent', action: 'acknowledgement', target });
  }

  static postMessageRedirectToAccount() {
    this.#postMessage({ name: 'OpenFormCanvasEvent', action: 'redirectToAccount' });
  }

  static #frames(target: Window): Set<Window> {
    const list = [target];
    for (let i = 0; i < target.frames.length; i++) {
      list.push(...this.#frames(target.frames[i]));
    }
    return new Set(list);
  }

  static #postMessage<T>(message: T, options = { targetOrigin: this.#targetOrigin }) {
    for (const frame of this.#frames(this.#top)) {
      frame.postMessage(message, options);
    }
  }
}
