import { type MutableRefObject, useEffect, useMemo, useRef } from 'react';
import { OpenFormCheckbox } from '../OpenFormComponents/OpenFormCheckbox.js';
import { OpenFormListTable, OpenFormListTableLabel } from './OpenFormListTable.js';
import { type OpenFormOutlet } from '../OpenFormLayout/OpenFormLayoutOutlet.js';
import { addDays } from 'date-fns';
import {
  createdByMsg,
  createdMsg,
  formMsg,
  modifiedByMsg,
  modifiedMsg,
  referenceMsg,
  removedOnMsg,
  showOnlyOwnCreatedMsg,
  t,
} from '../../../common/i18n/index.js';
import { formatLocaleDate, formatLocaleDateTime, fromLocaleDate, fromLocaleDateTime } from '../OpenFormUtils.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import { useOpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { useOutletContext } from 'react-router-dom';
import { useTable } from '../OpenFormHooks/useTable.js';
import { useToggle } from '../OpenFormHooks/useToggle.js';

export const OpenFormListTableDraft = ({
  disabled,
  selected,
  select,
  set,
  sizes = [25, 50, 100],
}: {
  disabled: boolean;
  selected: Set<string>;
  select: (set?: Set<string>) => void;
  set: MutableRefObject<Set<string>>;
  sizes?: readonly number[];
}) => {
  const async = useOpenFormAsync();
  const goto = useOpenFormGoto();
  const items = useMemo(() => Array.from(async.draft.values()), [async]);
  const list = useRef(items);
  const [cols, rows, view, sort] = useTable({
    cols: () => {
      const length = list.current.reduce((sum, draft) => sum + Number(draft.canDelete), 0);
      return [
        {
          visible: true,
          key: 'checkbox',
          label: (
            <OpenFormCheckbox
              disabled={async.state.disabled || !length}
              indeterminate={set.current.size > 0 && set.current.size < length}
              checked={set.current.size > 0 && set.current.size === length}
              onChange={() => {
                if (rows.current.every(draft => !draft.canDelete || set.current.has(draft.referenceNumber))) {
                  rows.current.forEach(draft => !draft.canDelete || set.current.delete(draft.referenceNumber));
                } else {
                  rows.current.forEach(draft => !draft.canDelete || set.current.add(draft.referenceNumber));
                }
                select(set.current);
              }}
            />
          ),
          sortable: false,
        },
        { visible: true, key: 'draft', label: t.ZLAU(referenceMsg), sorted: 'desc' },
        { visible: true, key: 'formName', label: t.G9H2(formMsg) },
        { visible: true, key: 'createdBy', label: t.XGCD(createdByMsg) },
        { visible: true, key: 'createdDate', label: t.GSYE(createdMsg), value: fromLocaleDateTime },
        { visible: true, key: 'lastModifiedBy', label: t.YULI(modifiedByMsg) },
        { visible: true, key: 'lastModified', label: t.RTJW(modifiedMsg), value: fromLocaleDateTime },
        { visible: true, key: 'removedOn', label: t.R8IE(removedOnMsg), value: fromLocaleDate },
        { visible: false, key: 'canDelete', label: null },
        { visible: false, key: 'createdByUserName', label: null },
        { visible: false, key: 'referenceNumber', label: null },
      ];
    },
    rows: () =>
      list.current.map(({ formId, referenceNumber, ...draft }) => ({
        checkbox: (
          <OpenFormCheckbox
            checked={set.current.has(referenceNumber)}
            disabled={async.state.disabled || !draft.canDelete}
            onChange={() => {
              set.current.has(referenceNumber) ? set.current.delete(referenceNumber) : set.current.add(referenceNumber);
              select(set.current);
            }}
          />
        ),
        draft: (
          <OpenFormListTableLabel
            disabled={async.state.disabled}
            label={referenceNumber}
            onClick={() => goto({ formId }, { list: paths.OPENFORM_LIST_DRAFT, referenceNumber })}
          />
        ),
        formName: draft.formName,
        createdBy: draft.createdBy,
        createdDate: formatLocaleDateTime(draft.createdDate),
        lastModifiedBy: draft.lastModifiedBy,
        lastModified: formatLocaleDateTime(draft.lastModified),
        removedOn: formatLocaleDate(addDays(Date.now(), draft.daysUntilDeleted)),
        canDelete: draft.canDelete,
        createdByUserName: draft.createdByUserName,
        referenceNumber: referenceNumber,
      })),
    view: () => ({ size: sizes[0], total: list.current.length }),
    order: 'referenceNumber',
  });

  const { user } = useOutletContext<Required<OpenFormOutlet>>();
  const [showOnlyOwn, toggleShowOnlyOwn] = useToggle();

  useEffect(() => {
    list.current = showOnlyOwn ? items.filter(item => item.createdByUserName === user.userName) : items;
    sort.current();
  }, [disabled, items, selected, sort, user, showOnlyOwn]);

  return (
    <>
      <OpenFormCheckbox
        checked={showOnlyOwn}
        disabled={disabled}
        label={t.EAPO(showOnlyOwnCreatedMsg)}
        onChange={() => {
          select();
          toggleShowOnlyOwn();
        }}
      />
      <OpenFormListTable
        cols={cols}
        disabled={disabled}
        rows={rows}
        sizes={sizes}
        sort={sort}
        view={view}
        onPageChange={select}
        onSizeChange={select}
        onSortChange={select}
      />
    </>
  );
};
