import * as React from 'react';
import { Grid } from '../Grid/Grid.js';
import { NavigationMenu, NavigationMenuItem } from '../NavigationMenu/NavigationMenu.js';
import { SelectNewSubscriptionDialog } from '../../components/Dialogs/SelectNewSubscriptionDialog.js';
import { getActiveAccountMasterId } from '../../selfservice/common/localStorageUtils.js';
import {
  invoicesMsg,
  myProductsAndServicesMsg,
  newSubscriptionMsg,
  omaElisaClassicMsg,
  orderHistoryMsg,
  t,
} from '../../common/i18n/index.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useNavigate } from 'react-router-dom';
import { useSearchParams } from '../../common/hooks/useSearchParams.js';

import './SelfServiceHomeNavigation.scss';

export interface SelfServiceHomeNavigationProps {
  waitingOrdersCount: number;
  openInvoicesCount: number;
  classicSiteUrl: string;
}
export const SelfServiceHomeNavigation = ({
  waitingOrdersCount,
  openInvoicesCount,
  classicSiteUrl,
}: SelfServiceHomeNavigationProps) => {
  const navigate = useNavigate();
  const { mdmId } = useSearchParams<{ mdmId: string }>();
  const [showSelectNewSubscriptionDialog, setShowSelectNewSubscriptionDialog] = React.useState(false);

  return (
    <Grid>
      <div className="of-self-service-home-navigation">
        {showSelectNewSubscriptionDialog && (
          <SelectNewSubscriptionDialog
            onCloseDialog={() => setShowSelectNewSubscriptionDialog(false)}
            hideBlankSim={true}
            showBlankSimLink={true}
            mdmId={mdmId || getActiveAccountMasterId()}
          />
        )}
        <NavigationMenu>
          <NavigationMenuItem
            to={paths.INVOICES}
            id="category-menu-item-invoices"
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              navigate(paths.INVOICES);
            }}
            icon={
              <span className="ea-icon ea-icon--medium ea-icon--invoice" aria-hidden="true">
                {openInvoicesCount > 0 && (
                  <span className="of-navigation-menu__item__order-count">{openInvoicesCount}</span>
                )}
              </span>
            }
            label={t.Y7C0(invoicesMsg)}
          />
          <NavigationMenuItem
            to={paths.CUSTOMER_ORDERS}
            id="category-menu-item-customer-orders"
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              navigate(paths.CUSTOMER_ORDERS);
            }}
            icon={
              <span className="ea-icon ea-icon--medium ea-icon--delivery" aria-hidden="true">
                {waitingOrdersCount > 0 && (
                  <span className="of-navigation-menu__item__order-count">{waitingOrdersCount}</span>
                )}
              </span>
            }
            label={t.GCO3(orderHistoryMsg)}
          />
          <NavigationMenuItem
            to={paths.PS_HOME}
            id="category-menu-item-subscriptions"
            onClick={(event: React.SyntheticEvent) => {
              event.preventDefault();
              navigate(paths.PS_HOME);
            }}
            icon={<span className="ea-icon ea-icon--medium ea-icon--devices" aria-hidden="true" />}
            label={t.VWNX(myProductsAndServicesMsg)}
          />
          <NavigationMenuItem
            id="category-menu-item-my-offers"
            to={paths.MY_OFFERS}
            target="_blank"
            rel="noreferrer"
            icon={
              <span className="ea-icon ea-icon--medium ea-icon--diamond of-diamond-enrichment" aria-hidden="true" />
            }
            label={t.GE4D('My offers')}
          />
          <NavigationMenuItem
            id="category-menu-item-new-subscription"
            linkType="clickable"
            onClick={() => setShowSelectNewSubscriptionDialog(true)}
            icon={<span className="ea-icon ea-icon--medium ea-icon--add" aria-hidden="true" />}
            label={t.OK8J(newSubscriptionMsg)}
          />
          <NavigationMenuItem
            id="category-menu-item-old-omaelisa"
            linkType="external"
            href={classicSiteUrl}
            icon={<span className="ea-icon ea-icon--medium ea-icon--reply" aria-hidden="true" />}
            label={t.SLBP(omaElisaClassicMsg)}
          />
        </NavigationMenu>
      </div>
    </Grid>
  );
};
