import * as CL from '@design-system/component-library';
import { type Column, Table } from '../OpenFormHooks/useTable.js';
import { type MouseEventHandler, type MutableRefObject, type ReactNode, useCallback, useMemo } from 'react';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { allMsg, t } from '../../../common/i18n/index.js';
import classNames from 'classnames';

export const OpenFormListTableLabel = ({
  disabled,
  label,
  onClick,
}: {
  disabled: boolean;
  label: ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => (
  <button className="of-openform__list__table__button--label" disabled={disabled} onClick={onClick}>
    <span aria-disabled={disabled}>{label}</span>
  </button>
);

export const OpenFormListTable = ({
  cols,
  disabled,
  sizes,
  sort,
  view,
  rows,
  rowClicks,
  onPageChange,
  onSizeChange,
  onSortChange,
}: {
  cols: Column[];
  disabled: boolean;
  rowClicks?: CL.TableProps['rowClicks'];
  rows: MutableRefObject<CL.TableProps['rows']>;
  sizes: readonly number[];
  sort: MutableRefObject<(sort?: Column) => void>;
  view: MutableRefObject<ReturnType<typeof Table.view>>;
  onPageChange?: () => void;
  onSizeChange?: () => void;
  onSortChange?: () => void;
}) => {
  const setPage = useCallback<(page: number) => void>(
    page => {
      view.current = Table.view({ page, size: view.current.size, total: view.current.total });
      onPageChange?.();
      sort.current();
    },
    [sort, view, onPageChange]
  );

  const setSize = useCallback<(size: number) => void>(
    size => {
      view.current = Table.view({ page: view.current.page, size, total: view.current.total });
      onSizeChange?.();
      sort.current();
    },
    [sort, view, onSizeChange]
  );

  const setSort = useCallback<(column?: Column) => void>(
    column => {
      onSortChange?.();
      sort.current(column);
    },
    [sort, onSortChange]
  );

  return (
    <>
      <CL.Table
        className={classNames('of-openform__list__table', { ['of-openform__list__table--disabled']: disabled })}
        tableType="bordered"
        hover={true}
        columns={cols as CL.TableProps['columns']}
        rows={rows.current}
        rowClicks={rowClicks}
        onSortClick={label => setSort(cols.find(col => col.label === label))}
      />
      <div className="of-openform__list__navigation">
        <div className="of-openform__list__navigation__dropdown" aria-disabled={disabled}>
          <CL.Dropdown
            integrated={true}
            items={sizes
              .map(size => ({ label: String(size), value: String(size) }))
              .concat({ label: t.EY6A(allMsg), value: useMemo(() => String(view.current.total), [view]) })}
            disabled={disabled}
            onValueChange={el => setSize(Number(el.dataset.value))}
          />
          <span aria-disabled={disabled}>
            {view.current.end && view.current.start + 1} - {view.current.end} / {view.current.total}
          </span>
        </div>
        <div className="of-openform__list__navigation__buttons">
          <OpenFormButton
            className="of-openform__list__navigation__buttons__page"
            icon={<OpenFormIcon icon="arrow-left" />}
            color="link"
            size="m"
            disabled={disabled || view.current.page <= 1}
            onClick={() => setPage(view.current.page - 1)}
          />
          <OpenFormButton
            className="of-openform__list__navigation__buttons__index"
            text={view.current.page}
            color="link"
            size="m"
            disabled={disabled || !view.current.total}
          />
          <OpenFormButton
            className="of-openform__list__navigation__buttons__page"
            icon={<OpenFormIcon icon="arrow-right" />}
            color="link"
            size="m"
            disabled={disabled || view.current.page >= view.current.pages}
            onClick={() => setPage(view.current.page + 1)}
          />
        </div>
      </div>
    </>
  );
};
