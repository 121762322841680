import { Navigate, generatePath, useLocation, useOutletContext } from 'react-router-dom';
import { OpenFormBar } from '../OpenFormComponents/OpenFormBar.js';
import { OpenFormGrid } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormListNavigation } from './OpenFormListNavigation.js';
import { OpenFormListTableDraft } from './OpenFormListTableDraft.js';
import { OpenFormListTableForm } from './OpenFormListTableForm.js';
import { type OpenFormOutlet } from '../OpenFormLayout/OpenFormLayoutOutlet.js';
import {
  clearFormsMsg,
  deleteSelectedMsg,
  deletingSelectedMsg,
  deselectAllMsg,
  t,
} from '../../../common/i18n/index.js';
import { paths } from '../../../common/constants/pathVariables.js';
import { useCallback, useEffect, useMemo } from 'react';
import { useOpenFormAsync, useOpenFormState } from '../OpenFormProvider.js';
import { useOpenFormList } from '../OpenFormHooks/useOpenFormList.js';
import { useRefSet } from '../OpenFormHooks/useRefSet.js';

export const OpenFormListLayout = () => {
  const { pathname, search } = useLocation();
  const { reload } = useOutletContext<Required<OpenFormOutlet>>();
  const { disabled } = useOpenFormState();
  const async = useOpenFormAsync();
  const ready = useOpenFormList();
  const [set, selected, select] = useRefSet();
  const count = useMemo(() => (selected.size ? `(${selected.size})` : ''), [selected]);

  const clearForms = useCallback(() => {
    selected.forEach(formId => async.storage.removeItem(formId));
    reload();
  }, [reload, async, selected]);

  const clearSelect = useCallback(() => select(), [select]);

  const deleteDrafts = useCallback(() => {
    async
      .deleteOpenFormDrafts({ referenceNumbers: Array.from(selected) })
      .resolved(clearForms)
      .rejected(text => async.actions.notification({ text, type: 'warning' }))
      .execute(t.WN64(deletingSelectedMsg));
  }, [async, selected, clearForms]);

  useEffect(() => clearSelect, [pathname, clearSelect]);

  switch (pathname) {
    case paths.OPENFORM_LIST_DRAFT:
      return async.flags.has('draft') ? (
        <OpenFormBar
          leftFunc={count ? clearSelect : undefined}
          leftIcon="arrow-left"
          leftText={count ? `${count} ${t.C7AE(deselectAllMsg)}` : t.C7AE(deselectAllMsg)}
          rightFunc={count ? deleteDrafts : undefined}
          rightIcon="delete"
          rightText={count ? `${t.B1Q0(deleteSelectedMsg)} ${count}` : t.B1Q0(deleteSelectedMsg)}
        >
          {!ready ? null : (
            <OpenFormGrid className="of-openform__list">
              <OpenFormListNavigation disabled={disabled} pathname={pathname} />
              <OpenFormListTableDraft disabled={disabled} selected={selected} select={select} set={set} />
            </OpenFormGrid>
          )}
        </OpenFormBar>
      ) : (
        <OpenFormBar>
          {!ready ? null : <Navigate replace to={generatePath(paths.OPENFORM_LIST_FORM) + search} />}
        </OpenFormBar>
      );
    case paths.OPENFORM_LIST_FORM:
      return async.flags.has('draft') ? (
        <OpenFormBar>
          {!ready ? null : (
            <OpenFormGrid className="of-openform__list">
              <OpenFormListNavigation disabled={disabled} pathname={pathname} />
              <OpenFormListTableForm disabled={disabled} selected={selected} select={select} set={set} />
            </OpenFormGrid>
          )}
        </OpenFormBar>
      ) : (
        <OpenFormBar
          leftFunc={count ? clearSelect : undefined}
          leftIcon="arrow-left"
          leftText={count ? `${count} ${t.C7AE(deselectAllMsg)}` : t.C7AE(deselectAllMsg)}
          rightFunc={count ? clearForms : undefined}
          rightIcon="delete"
          rightText={count ? `${t.KZJT(clearFormsMsg)} ${count}` : t.KZJT(clearFormsMsg)}
        >
          {!ready ? null : (
            <OpenFormGrid className="of-openform__list">
              <OpenFormListTableForm disabled={disabled} selected={selected} select={select} set={set} />
            </OpenFormGrid>
          )}
        </OpenFormBar>
      );
    default:
      return null;
  }
};
