import * as CL from '@design-system/component-library';
import { CheckoutLoginOrContactInfoStep } from './steps/CheckoutLoginOrContactInfoStep.js';
import { DeliveryAddressType } from '../DeliveryAddress/DeliveryAddress.js';
import { EcommerceEventTypeV4 } from '../../common/analytics';
import { PaymentMethod } from '../SelectPaymentMethod/SelectPaymentMethod.js';
import { cartProductsStep } from './steps/CartProductsStep.js';
import { convertStringMapToCommonErrors, getElementsWithErrors } from '../../common/utils/errorUtils.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { deliveryDetailsStep } from './steps/DeliveryDetailsStep.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { employeeDeviceChangeStep } from '../DeviceCheckout/EmployeeDeviceChange.js';
import { employeeUserPersonalBillingStep } from './steps/EmployeeUserPersonalBillingStep.js';
import {
  extractNewBillingAccountDetails,
  getDeliveryDetails,
  getDeliveryDetailsObj,
  getOrderItems,
  getProductDeliveryTypesInCart,
  getSubscriptionDetailForDeviceChangeFromSessionStorage,
  hasConfigurableSimCardItems,
  hasEmployeePayables,
  showDeliveryStep,
  showEppDeviceChangeStep,
  submitOrderWithCardPayment,
} from '../../common/utils/checkoutUtils.js';
import { getTopMostElement } from '../../common/utils/domUtils.js';
import { handleCheckoutAnalytics } from './checkoutAnalyticsUtils';
import { keyUserBillingStep } from './steps/KeyUserBillingStep.js';
import {
  loadDeliveryMethods,
  resetMoveToNextStep,
  resetSubscriptionDetailForDeviceChange,
  submitOnlineOrder,
} from '../../selfservice/actions/index.js';
import { scrollTo } from '../../common/utils/browserUtils.js';
import { t } from '../../common/i18n/index.js';
import { useAuth } from '../../public/site/AuthProvider.js';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCheckout } from './Checkout.js';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteLoaderData } from 'react-router-dom';
import type { BillingAccountOrErrorSupplier, CommonError } from '../../common/types/errors.js';
import type { CLStepperItem } from '../../common/types/componentLibrary';
import type { CheckoutRootLoaderData } from '../../common/loaders.js';
import type { ContactPerson } from '../../generated/api/models.js';
import type { DeviceChangeRequest } from '../../common/types/device.js';
import type { DeviceCheckoutDeliveryDetailsType } from '../DeviceCheckoutDeliveryDetails/DeviceCheckoutDeliveryDetailsUtils.js';
import type { State } from '../../selfservice/common/store.js';
import type { SubmitOrderProps } from '../../common/types/checkout.js';

export type CheckoutStepItem = CLStepperItem & {
  completedName?: string;
};

export interface CheckoutStepsProps {
  isEmployee: boolean;
  rootLoaderId: string;
  useBasketData?: boolean;
}

export const CheckoutSteps = (props: CheckoutStepsProps) => {
  const dispatch = useDispatch();
  const { hideDeliveryMethodSelection, currentStep, setCurrentStep } = useCheckout();

  const { ssoSessionValid, authenticatedUser } = useAuth();

  const {
    moveToNextStep,
    cartItems,
    deliveryDetails,
    companyInfo,
    selectedBaId,
    listOfDeliveryMethods,
    onlineModels,
    deviceSubs,
  } = useSelector(
    (state: State) => ({
      moveToNextStep: state.deviceCheckout?.moveToNextStep,
      cartItems: state.deviceCheckout?.cartItems || [],
      listOfDeliveryMethods: state.deviceCheckout?.deliveryMethods?.lisOfDeliveryMethods,
      deliveryDetails: state.deviceCheckout?.deliveryDetails,
      companyInfo: state.selfservice?.companyInfo || undefined,
      selectedBaId: state.deviceCheckout?.selectedBaId,
      onlineModels: state.selfservice?.onlineModels || undefined,
      deviceSubs: state.user?.authenticated?.subscriptions?.device || [],
    }),
    deepEqual
  );

  const { holidays, models } = useRouteLoaderData(props.rootLoaderId) as CheckoutRootLoaderData;

  useEffect(() => {
    if (ssoSessionValid) {
      dispatch(loadDeliveryMethods(getProductDeliveryTypesInCart(cartItems, onlineModels)));
    }
  }, [dispatch, ssoSessionValid, cartItems, onlineModels]);

  const [goNextStepState, setGoNextStepState] = useState<() => void>();
  const [isShowDeliveryStep, setIsShowDeliveryStep] = useState(showDeliveryStep(cartItems));
  const [deviceChangeRequest, setDeviceChangeRequest] = useState<DeviceChangeRequest>();
  const [deliveryDetailsObj, setDeliveryDetailsObj] = useState<DeviceCheckoutDeliveryDetailsType>(
    deliveryDetails ?? ({} as DeviceCheckoutDeliveryDetailsType)
  );
  const [contactPerson, setContactPerson] = useState<ContactPerson>();
  const [contactPersonValidationErrors, setContactPersonValidationErrors] = useState<{ [s: string]: string }>({});
  const [approverContactObj, setApproverContactObj] = useState<string>();
  const [approverContactValidationErrors, setApproverContactValidationErrors] = useState<{ [s: string]: string }>({});

  const subscriptionDetailForDeviceChange = useRef(
    useMemo(() => getSubscriptionDetailForDeviceChangeFromSessionStorage(), [])
  );
  const [errors, setErrors] = useState<CommonError[] | undefined>(undefined);
  const shouldScrollToError = useRef(false);
  const newBillingAccountSupplier = useRef<BillingAccountOrErrorSupplier>();

  const domRefs = useRef<{ [elementKey: string]: HTMLElement }>({});
  const hasRunOnceRef = useRef(false);
  const setRefCallback = (key: string, ref: HTMLElement | null) => {
    if (ref) {
      domRefs.current[key] = ref;
    } else {
      delete domRefs.current[key];
    }
  };

  useEffect(() => {
    if (shouldScrollToError && errors && errors.length > 0) {
      scrollTo(getTopMostElement(getElementsWithErrors(domRefs.current, errors)));
    }
  }, [shouldScrollToError, errors]);

  const onShowStep = (step: number) => {
    switch (step) {
      case 2:
        handleCheckoutAnalytics(cartItems, EcommerceEventTypeV4.ADD_SHIPPING_INFO, models);
        break;
      case 3:
        handleCheckoutAnalytics(cartItems, EcommerceEventTypeV4.ADD_PAYMENT_INFO, models);
        break;
    }
  };

  const goNextStep = () => {
    onShowStep(currentStep + 1);
    if (goNextStepState) {
      goNextStepState();
    }
  };

  const goNextStepFn = useCallback(() => {
    setCurrentStep(currentStep + 1);
  }, [currentStep, setCurrentStep]);

  useEffect(() => {
    setGoNextStepState(() => goNextStepFn);
  }, [goNextStepFn]);

  const setShouldScrollToError = (value: boolean, checkoutErrors?: CommonError[]) => {
    shouldScrollToError.current = value;
    setErrors(checkoutErrors);
  };

  const setNewBillingAccountSupplier = (value: BillingAccountOrErrorSupplier) => {
    newBillingAccountSupplier.current = value;
  };

  const onDeviceChangeNextStep = (updatedDeviceChangeRequest: DeviceChangeRequest): void => {
    setDeviceChangeRequest(updatedDeviceChangeRequest);
    goNextStep();
  };

  const submitOrder = (submitOrderProps: SubmitOrderProps) => {
    const { newBillingAccount, newBillingAccountValidationErrors } = extractNewBillingAccountDetails(
      newBillingAccountSupplier.current,
      deliveryDetails,
      authenticatedUser
    );
    const orderItems = getOrderItems(cartItems, deviceChangeRequest);

    if (submitOrderProps.selectedPaymentMethod === PaymentMethod.CARD) {
      submitOrderWithCardPayment(submitOrderProps, orderItems, dispatch, deliveryDetails, authenticatedUser);
    } else {
      dispatch(
        submitOnlineOrder(
          orderItems,
          getDeliveryDetails(submitOrderProps, deliveryDetails, authenticatedUser),
          contactPerson,
          Object.keys(contactPersonValidationErrors).length === 0
            ? undefined
            : convertStringMapToCommonErrors(contactPersonValidationErrors),
          companyInfo?.customerType,
          submitOrderProps?.updatedDeliveryDetails?.addressType === DeliveryAddressType.COMPANY_ADDRESS,
          undefined,
          undefined,
          deviceChangeRequest?.replacedSubscriptionBillingAccountId ?? selectedBaId,
          newBillingAccount,
          newBillingAccountValidationErrors,
          undefined,
          undefined,
          submitOrderProps?.updatedDeliveryDetails?.deliveryMethodType ?? deliveryDetails!.deliveryMethodType,
          approverContactObj,
          Object.keys(approverContactValidationErrors).length === 0
            ? undefined
            : convertStringMapToCommonErrors(approverContactValidationErrors),
          submitOrderProps.personBillingAccountId,
          submitOrderProps.personBillingEmail,
          submitOrderProps.personBillingAddress,
          deviceChangeRequest,
          submitOrderProps.updatedDeliveryDetails?.shipmentType ?? deliveryDetails?.shipmentType,
          undefined,
          undefined
        )
      );
    }
    setShouldScrollToError(true);
  };

  useEffect(() => {
    if (moveToNextStep) {
      goNextStep();
      dispatch(resetMoveToNextStep());
    }
  }, [dispatch, moveToNextStep]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(resetMoveToNextStep());
  }, [dispatch]);

  useEffect(() => {
    dispatch(resetSubscriptionDetailForDeviceChange());
  }, [dispatch, subscriptionDetailForDeviceChange.current]); /* TODO: rules-of-hooks */ // eslint-disable-line react-hooks/exhaustive-deps

  // We have to do the first step here, as we don't get a goNextStep call for it
  useEffect(() => {
    if (!hasRunOnceRef.current && cartItems.length) {
      handleCheckoutAnalytics(cartItems, EcommerceEventTypeV4.BEGIN_CHECKOUT, models);
      hasRunOnceRef.current = true;
    }
  }, [cartItems, dispatch, models]);

  useEffect(() => {
    setIsShowDeliveryStep(showDeliveryStep(cartItems) || hasConfigurableSimCardItems(cartItems));
  }, [cartItems]);

  useEffect(() => {
    if (listOfDeliveryMethods) {
      setDeliveryDetailsObj(
        getDeliveryDetailsObj(listOfDeliveryMethods, companyInfo, authenticatedUser, deliveryDetails)
      );
    }
  }, [props.isEmployee, cartItems, listOfDeliveryMethods, deliveryDetails, companyInfo, authenticatedUser]);

  const stepContents = [
    // step 1: login step for admin users
    !props.isEmployee &&
      ({
        id: 'checkoutLoginOrContactInfoStep',
        content: <CheckoutLoginOrContactInfoStep />,
        name: t.NF60('Customer details'),
        completedName: authenticatedUser
          ? `${authenticatedUser.firstName} ${authenticatedUser.lastName}, ${authenticatedUser.companyName}`
          : undefined,
      } as CheckoutStepItem),

    cartProductsStep(
      {
        holidays: holidays || [],
        isEmployee: props.isEmployee,
        shouldScrollToError: shouldScrollToError.current,
        setShouldScrollToError: setShouldScrollToError,
        setDeviceChangeRequest: setDeviceChangeRequest,
        deviceChangeRequest: deviceChangeRequest,
        deliveryDetails: deliveryDetailsObj,
        subscriptionDetailForDeviceChange: subscriptionDetailForDeviceChange.current,
        user: authenticatedUser,
        useBasketData: props.useBasketData,
      },
      cartItems
    ),

    // Step 2 for Device Change Request (employee)
    props.isEmployee &&
      showEppDeviceChangeStep(cartItems, authenticatedUser) &&
      authenticatedUser &&
      employeeDeviceChangeStep(
        {
          user: authenticatedUser,
          onNextClick: onDeviceChangeNextStep,
          deviceSubs: deviceSubs,
        },
        deviceChangeRequest
      ),
    (isShowDeliveryStep || props.isEmployee) &&
      deliveryDetailsStep({
        isEmployee: props.isEmployee,
        hideDeliveryMethodSelection,
        deliveryDetails: deliveryDetailsObj,
        showDeliveryStep: isShowDeliveryStep,
        setShouldScrollToError: setShouldScrollToError,
        contactPerson: contactPerson,
        setContactPerson: setContactPerson,
        contactPersonValidationErrors: contactPersonValidationErrors,
        setContactPersonValidationErrors: setContactPersonValidationErrors,
        approverContact: approverContactObj,
        setApproverContact: setApproverContactObj,
        approverContactValidationErrors: approverContactValidationErrors,
        setApproverContactValidationErrors: setApproverContactValidationErrors,
        shouldScrollToError: shouldScrollToError.current,
        deviceChangeRequest: deviceChangeRequest,
        setDeliveryDetailsObj,
        submitOrder: submitOrder,
        setRefCallback: setRefCallback,
      }),

    // admin users are not allowed to order OmaLaiteLasku, so even if a company has enabled OmaLaiteLasku and
    // device amount is greater than corporate share set in EPP solution settings,
    // the order will proceed without OmaLaiteLasku and full amount will be charged on corporate BA
    // (employee)
    props.isEmployee &&
      hasEmployeePayables(cartItems, props.isEmployee, deviceChangeRequest) &&
      employeeUserPersonalBillingStep({
        deviceChangeRequest: deviceChangeRequest,
        submitOrder: submitOrder,
        deliveryDetails: deliveryDetailsObj,
      }),

    // last step for admin users
    !props.isEmployee &&
      keyUserBillingStep({
        setNewBillingAccountSaveValuesToCheckoutState: setNewBillingAccountSupplier,
        onSubmit: (selectedPaymentMethod: PaymentMethod) =>
          submitOrder({ selectedPaymentMethod, pickupPoint: deliveryDetailsObj.pickupPoint }),
        replacedSubscriptionBillingAccountId: deviceChangeRequest?.replacedSubscriptionBillingAccountId,
        deviceChangeSubscriptionContactId: subscriptionDetailForDeviceChange.current?.contactId,
      }),
  ].filter(Boolean) as CheckoutStepItem[];

  const stepsWithCompleteAndClickHandler = stepContents.map((step, idx) => {
    if (idx < currentStep) {
      step.completed = true;
      step.onClick = () => setCurrentStep(idx);
      step.name = step.completedName ? step.completedName : step.name;
    } else {
      step.completed = false;
      step.onClick = undefined;
    }
    return step;
  });

  if (cartItems.length === 0 || ssoSessionValid === undefined || holidays === undefined) {
    return <CL.LoadingSpinner size="l" />;
  }

  return (
    <div className="of-checkout-steps">
      <h2>{t.IH3X('Checkout')}</h2>
      <div className={dsClass.RESET}>
        <CL.Stepper
          steps={stepsWithCompleteAndClickHandler}
          activeStep={stepsWithCompleteAndClickHandler[currentStep].id}
        />
      </div>
    </div>
  );
};
