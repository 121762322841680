import { type BillingAccountData } from '../OpenFormAnswers.js';
import { loadingAccountMsg, t } from '../../../common/i18n/index.js';
import { useEffect, useState } from 'react';
import { useOpenFormAsync } from '../OpenFormProvider.js';
import type { AccountHeader } from '../../../generated/api/accountHeader.js';
import type { OpenFormAccountResponse } from '../../../generated/api/openFormAccountResponse.js';

export class OpenFormAccount {
  constructor(private readonly response: OpenFormAccountResponse) {}

  get billingAccountData(): BillingAccountData {
    return {
      accountMasterId: this.response.header.accountMasterId,
      payerAddress: this.response.address,
      payerBusinessId: this.response.header.businessId,
      payerName: this.response.header.accountName,
    };
  }

  get header(): AccountHeader {
    return this.response.header;
  }
}

export const useOpenFormAccount = () => {
  const [data, setData] = useState<OpenFormAccount>();
  const async = useOpenFormAsync();

  useEffect(() => {
    const { controller } = async
      .fetchOpenFormAccount()
      .resolved(response => setData(new OpenFormAccount(response)))
      .rejected(text => async.actions.notification({ text, type: 'error' }))
      .cache()
      .execute(t.OA8P(loadingAccountMsg));

    return () => controller.abort();
  }, [async]);

  return data;
};
