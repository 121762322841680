import { BillingAccountDetails } from '../../../../../components/BillingAccountDetails/BillingAccountDetails.js';
import { InvoiceSystemError } from '../../../../../components/Invoices/Invoices';
import { useLoaderData, useRouteLoaderData } from 'react-router-dom';
import type { BillingAccountLayoutLoaderData, BillingAccountLoaderResponse } from '../../../../../common/loaders.js';

export const BillingAccountPath = () => {
  const { billingAccount, billingAccountScheduledChange } = useRouteLoaderData(
    'baRoot'
  ) as BillingAccountLayoutLoaderData;
  const { billChannels, contacts } = useLoaderData() as BillingAccountLoaderResponse;

  return (
    <InvoiceSystemError>
      <BillingAccountDetails
        billingAccount={billingAccount}
        billingAccountScheduledChangeResponse={billingAccountScheduledChange}
        billChannels={billChannels}
        contacts={contacts}
      />
    </InvoiceSystemError>
  );
};
