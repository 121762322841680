import {
  OpenFormBillingAccount,
  OpenFormBillingContact,
  OpenFormBillingInvoiceDeliveryMethod,
  OpenFormContact,
} from '../../OpenFormComponents/OpenFormFormatted.js';
import { OpenFormGridCol, OpenFormGridRow } from '../../OpenFormComponents/OpenFormGrid.js';
import { OpenFormItem } from '../../OpenFormComponents/OpenFormItem.js';
import { type OpenFormSummary } from '../../OpenFormHooks/useOpenFormSummary.js';
import {
  billingContactMsg,
  billingDetailsMsg,
  deliveryMethodMsg,
  newBillingAgreementMsg,
  selectedBillingAccountMsg,
  t,
} from '../../../../common/i18n/index.js';

export const OpenFormSummaryBillingAccount = ({
  summary: { billingAccount, billingAccountData, contactEntries },
}: {
  summary: OpenFormSummary;
}) => {
  const account = billingAccountData ?? billingAccount;
  const contact = contactEntries.find(([_, c]) => c.Id === account?.billingContactId)?.[1];
  return !account ? null : (
    <OpenFormGridRow>
      <OpenFormGridCol colWidth={9}>
        <h4>{t.RPMR(billingDetailsMsg)}</h4>
        <OpenFormGridRow key={account.billingAccountName}>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem
              title={
                'billingAccountDisplayId' in account
                  ? t.HVS2(selectedBillingAccountMsg)
                  : t.NCIJ(newBillingAgreementMsg)
              }
            >
              <OpenFormBillingAccount account={account} />
            </OpenFormItem>
          </OpenFormGridCol>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem title={t.XZH6(deliveryMethodMsg)}>
              <OpenFormBillingInvoiceDeliveryMethod account={account} />
            </OpenFormItem>
          </OpenFormGridCol>
          <OpenFormGridCol colWidth={4}>
            <OpenFormItem title={t.EY5Q(billingContactMsg)}>
              {contact ? <OpenFormContact contact={contact} /> : <OpenFormBillingContact account={account} />}
            </OpenFormItem>
          </OpenFormGridCol>
        </OpenFormGridRow>
        <hr />
      </OpenFormGridCol>
    </OpenFormGridRow>
  );
};
