export class OpenFormStorage implements Storage {
  readonly storage: Storage;
  readonly namespace: (key?: string) => string;

  constructor(storage: Storage, ...namespace: string[]) {
    this.storage = storage;
    this.namespace = key => namespace.concat(key!).filter(Boolean).join(':');
  }

  get length() {
    return this.#keys.length;
  }

  get #keys() {
    return Object.keys(this.storage).filter(key => key.startsWith(this.namespace()));
  }

  clear() {
    this.#keys.forEach(key => this.storage.removeItem(key));
  }

  getItem(key: string) {
    try {
      return JSON.parse(this.storage.getItem(this.namespace(key))!);
    } catch (error) {
      console.warn(error);
      return null;
    }
  }

  hasItem(key: string) {
    return this.namespace(key) in this.storage;
  }

  key(index: number) {
    return this.#keys[index] ?? null;
  }

  removeItem(key: string) {
    this.storage.removeItem(this.namespace(key));
  }

  setItem<T>(key: string, value: T | null = null) {
    try {
      this.storage.setItem(this.namespace(key), typeof value === 'string' ? value : JSON.stringify(value));
    } catch (error) {
      console.warn(error);
    }
  }
}
