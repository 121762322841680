import { InvoiceList } from '../../../../../components/InvoiceList/InvoiceList.js';
import {
  Invoices,
  InvoicesSceneTab,
  getInvoiceListCategory,
  getInvoicesForCategory,
} from '../../../../../components/Invoices/Invoices.js';
import { SupportCaseNotification } from '../../../../../components/SupportCaseNotification/SupportCaseNotification.js';
import { useLoaderData, useLocation } from 'react-router-dom';
import type { InvoicesLoaderData } from '../../../../../common/loaders.js';

export const InvoicesPath = () => {
  const { invoices, supportCases } = useLoaderData() as InvoicesLoaderData;
  const { hash } = useLocation();

  const category = getInvoiceListCategory(hash);
  const invoicesForCategory = getInvoicesForCategory(category, invoices.searchResults || []);

  return (
    <Invoices tab={InvoicesSceneTab.INVOICES}>
      <SupportCaseNotification openSupportCases={supportCases?.searchResults?.map(r => r.result)} />
      <InvoiceList category={category} invoices={invoicesForCategory} total={invoices.total} />
    </Invoices>
  );
};
