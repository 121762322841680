import * as CL from '@design-system/component-library';
import { HeroHeading, HeroHeadingType } from '../HeroHeading/index.js';
import { Link, generatePath, useLocation, useNavigate } from 'react-router-dom';
import { LocalNavi } from '../LocalNavi/index.js';
import {
  OPEN_INVOICES_HASH,
  PAID_INVOICES_HASH,
  billingAccountMsg,
  billingAccountsMsg,
  companyMsg,
  dueDateMsg,
  invoicesMsg,
  invoicingMsg,
  omaElisaForCompaniesMsg,
  paidMsg,
  payerMsg,
  t,
} from '../../common/i18n/index.js';
import { Status } from '../Status/Status.js';
import { SystemError, hasSystemError } from '../SystemError/SystemError.js';
import { Table } from '../Table/Table.js';
import { TableUrlParams } from '../Table/tableConstants.js';
import { deepEqual } from '../../common/utils/objectUtils.js';
import { dsClass } from '../../common/constants/dsClasses.js';
import { formatBalance, formatSum } from '../../common/utils/priceUtils.js';
import { formatTimestampToUTCDDMMYYYY } from '../../common/utils/dateUtils.js';
import { getCompanyName } from '../../common/utils/accountUtils.js';
import { getStatusColumnInvoiceState } from '../InvoiceDetails/InvoiceDetails.js';
import { highlightKeyword } from '../../common/utils/searchFieldUtils.js';
import { paths } from '../../common/constants/pathVariables.js';
import { useSelector } from 'react-redux';
import type { AuthenticatedUserState } from '../../common/types/states.js';
import type { BreadCrumbList } from '../BreadCrumbs/index.js';
import type { CLTableColumn } from '../../common/types/componentLibrary.js';
import type { InvoicesSearchResponse } from '../../generated/api/invoicesSearchResponse.js';
import type { ReactNode } from 'react';
import type { State } from '../../selfservice/common/store.js';

export const getInvoiceListCategory = (hash?: string) => {
  if (hash?.includes(OPEN_INVOICES_HASH)) {
    return 'open';
  } else if (hash?.includes(PAID_INVOICES_HASH)) {
    return 'paid';
  }
  return 'all';
};

export const getInvoicesForCategory = (category: string, invoices: InvoicesSearchResponse[]) => {
  if (category === 'all') {
    return invoices;
  } else if (category === 'open') {
    return invoices.filter(invoiceSearchResult => invoiceSearchResult.result.balance > 0);
  } else {
    return invoices.filter(invoiceSearchResult => invoiceSearchResult.result.balance <= 0);
  }
};

export enum InvoicesSceneTab {
  BILLING_ACCOUNTS = 'BILLING_ACCOUNTS',
  INVOICES = 'INVOICES',
  DOCUMENTS = 'DOCUMENTS',
}

interface InvoicesLocalNaviProps {
  children: ReactNode;
  hideHeadingAndTabs?: boolean;
  tab?: InvoicesSceneTab;
}

const getBreadCrumbPaths = (tab?: InvoicesSceneTab): BreadCrumbList | undefined => {
  if (tab === InvoicesSceneTab.INVOICES) {
    return [{ name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME }, { name: t.Y7C0(invoicesMsg) }];
  }
  if (tab === InvoicesSceneTab.BILLING_ACCOUNTS) {
    return [
      { name: t.VCUZ(omaElisaForCompaniesMsg), path: paths.SELF_SERVICE_HOME },
      { name: t.ZVMK(billingAccountsMsg) },
    ];
  }
  return;
};

export const Invoices = ({ children, tab }: InvoicesLocalNaviProps) => {
  return (
    <div className="of-invoices-scene">
      <HeroHeading
        breadCrumbPaths={getBreadCrumbPaths(tab)}
        heroHeadingType={HeroHeadingType.INVOICES}
        title={t.AUI8(invoicingMsg)}
      />
      <LocalNavi
        wide
        categories={[
          {
            children: t.Y7C0(invoicesMsg),
            to: paths.INVOICES,
          },
          {
            children: t.ZVMK(billingAccountsMsg),
            to: paths.BILLING_ACCOUNTS,
          },
          {
            children: t.OMMT('Invoice letters'),
            to: paths.INVOICE_DOCUMENTS,
          },
        ]}
      />
      {children}
    </div>
  );
};

interface InvoiceSystemErrorProps {
  children: ReactNode;
}

export const InvoiceSystemError = ({ children }: InvoiceSystemErrorProps) => {
  const contacts = useSelector((state: State) => state.selfservice?.contacts, deepEqual);
  const contactUpsertFailed =
    contacts?.errors?.filter(error => error.message === 'Failed to create duplicate contact').length !== 0;

  // Don't show generic "Oops" error page when contact creation fails due to duplicate contact
  // as this is handled in the dialog presented to the user
  if (hasSystemError(contacts?.errors) && !contactUpsertFailed) {
    return <SystemError errors={contacts?.errors} homePath={paths.SELF_SERVICE_HOME} />;
  }
  return <>{children}</>;
};

export const getEmptyListText = (category: string) => {
  if (category === 'open') {
    return `${t.HS4R('No')} ${t.FOKG('open')} ${t.VME0('invoices')}`;
  }
  if (category === 'paid') {
    return `${t.HS4R('No')} ${t.QVCE('paid')} ${t.VME0('invoices')}`;
  }
  return `${t.HS4R('No')} ${t.VME0('invoices')}`;
};

type ColumnKey = 'invoiceDisplayId' | 'payerName' | 'due' | 'sum' | 'state' | 'companyName' | 'billingAccountDisplayId';
type ColumnKeys = [ColumnKey?, ColumnKey?, ColumnKey?, ColumnKey?, ColumnKey?, ColumnKey?] & string[];

interface InvoiceTableProps {
  invoices: InvoicesSearchResponse[];
  category: 'open' | 'paid' | 'all';
  search: string;
  authenticatedUser: AuthenticatedUserState | undefined;
  tableColumns?: ColumnKeys;
}

export const InvoiceTable = ({ invoices, category, search, authenticatedUser, tableColumns }: InvoiceTableProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onStateChange = (stateOption = ''): void => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set(TableUrlParams.PAGE, '1');
    queryParams.set(TableUrlParams.OFFSET, '0');
    switch (stateOption) {
      case 'open':
        navigate(`${location.pathname}?${queryParams}#${OPEN_INVOICES_HASH}`);
        break;
      case 'paid':
        navigate(`${location.pathname}?${queryParams}#${PAID_INVOICES_HASH}`);
        break;
      default:
        navigate(`${location.pathname}?${queryParams}`);
    }
  };

  const columns: CLTableColumn[] = [
    {
      key: 'invoiceDisplayId',
      label: t.BRFX('Invoice number'),
      sortable: true,
    },
    { key: 'billingAccountDisplayId', label: `${t.IFT9(billingAccountMsg)} *`, sortable: true },
    { key: 'payerName', label: `${t.PB6S(payerMsg)} *`, sortable: true },
    { key: 'due', label: t.LA93(dueDateMsg), sortable: true },
    {
      key: 'sum',
      label: t.P4RQ('Amount'),
      sortable: true,
    },
    {
      key: 'state',
      label: (
        <CL.Dropdown
          key={category}
          items={[
            { label: t.EY6A('All'), value: 'all' },
            { label: t.GMAP('Open'), value: 'open' },
            { label: t.UQHZ('Paid'), value: 'paid' },
          ]}
          selectedValue={category}
          className="of-table-header-dropdown"
          integrated={true}
          onValueChange={value => {
            onStateChange(value?.dataset?.value);
          }}
        />
      ),
      sortable: false,
    },
    { key: 'companyName', label: t.KJTS(companyMsg), sortable: false },
  ].filter(col => !tableColumns || tableColumns.includes(col.key));

  const rows = invoices?.map(invoiceSearchResponse => {
    const invoice = invoiceSearchResponse.result;
    const matchedFields = invoiceSearchResponse.matchedFields;
    const fields = {
      billingAccountDisplayId: (
        <span>
          {invoice?.billingAccountDisplayId}
          <br />
          <div className={dsClass.FONT_SIZE_SMALL}>{invoice.billingAccountName}</div>
          <div className={dsClass.FONT_SIZE_SMALL}>{invoice?.billingAccountNameExtension}</div>
        </span>
      ),
      payerName: (
        <span>
          {invoice?.payerName}
          <div className={dsClass.FONT_SIZE_SMALL}>{invoice?.payerNameExtension}</div>
        </span>
      ),
      invoiceDisplayId: (
        <>
          <Link
            to={`${generatePath(paths.INVOICE, { invoiceId: invoice.invoiceDisplayId })}?mdmId=${
              invoice.accountMasterId
            }`}
          >
            {matchedFields?.includes('invoiceDisplayId')
              ? highlightKeyword(invoice.invoiceDisplayId, search)
              : invoice.invoiceDisplayId}
          </Link>
          {search && matchedFields?.includes('invoiceDisplayId') && (
            <div className={dsClass.FONT_SIZE_SMALL}>
              {t.IFT9(billingAccountMsg)}: {highlightKeyword(invoice.billingAccountDisplayId || '', search)}
            </div>
          )}
        </>
      ),
      state: (
        <Status
          textAfterBadge={getStatusColumnInvoiceState(invoice.balance || 0, invoice.due).text}
          color={getStatusColumnInvoiceState(invoice.balance || 0, invoice.due).color}
          inTable={true}
        />
      ),
      due: formatTimestampToUTCDDMMYYYY(invoice.due),
      sum: (
        <span>
          {formatSum(invoice.sum)}
          <div className={dsClass.FONT_SIZE_SMALL}>
            {t.RJ27(paidMsg)} {formatBalance(invoice.sum, invoice.balance || 0)}
          </div>
        </span>
      ),
      companyName: getCompanyName(authenticatedUser, invoice.accountMasterId),
    } as { [key: string]: JSX.Element | string };

    return !tableColumns
      ? fields
      : tableColumns.reduce((reducedFields: { [key: string]: JSX.Element | string }, column: string) => {
          reducedFields[column] = fields[column];
          return reducedFields;
        }, {});
  });

  return (
    <>
      <Table columns={columns} rows={rows} noItemsText={getEmptyListText('')} />
      {category !== 'all' && rows?.length === 0 && (
        <p>
          {category === 'open' && (
            <CL.Button onClick={() => navigate(`${pathname}${search}#${PAID_INVOICES_HASH}`)}>
              {t.YFT1('Go to paid invoices')}
            </CL.Button>
          )}
          {category === 'paid' && (
            <CL.Button onClick={() => navigate(`${pathname}${search}#${OPEN_INVOICES_HASH}`)}>
              {t.FTZT('Go to open invoices')}
            </CL.Button>
          )}
        </p>
      )}
    </>
  );
};
