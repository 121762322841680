import { DEFAULT_ITEMS_PER_PAGE, ListPagination, ListSearch } from '../../../../../components/Table';
import {
  InvoiceTable,
  getInvoiceListCategory,
  getInvoicesForCategory,
} from '../../../../../components/Invoices/Invoices';
import { setDefaultItemsPerPage } from '../../../../../components/Table/tableUtils';
import { useAuth } from '../../../AuthProvider';
import { useLoaderData, useLocation } from 'react-router-dom';
import type { BillingAccountInvoicesData } from '../../../../../common/loaders';

export const BillingAccountInvoicesPath = () => {
  const { invoices } = useLoaderData() as BillingAccountInvoicesData;
  const { authenticatedUser } = useAuth();
  const { search, hash } = useLocation();

  const category = getInvoiceListCategory(hash);
  const invoicesForCategory = getInvoicesForCategory(category, invoices.searchResults ?? []);

  return (
    <div className="of-billing-account-invoices-list">
      <ListSearch onSearch={() => setDefaultItemsPerPage(new URLSearchParams(search), DEFAULT_ITEMS_PER_PAGE)} />
      <InvoiceTable
        invoices={invoicesForCategory ?? invoices.searchResults ?? []}
        authenticatedUser={authenticatedUser}
        category={category}
        search={search}
        tableColumns={['invoiceDisplayId', 'payerName', 'due', 'sum', 'state']}
      />
      <ListPagination totalItems={invoices.total} />
    </div>
  );
};
