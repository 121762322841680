import { type MutableRefObject, type ReactNode, useCallback, useRef, useState } from 'react';

export type Column<Row = never> = {
  align?: 'left' | 'center' | 'right';
  key: keyof Row;
  label: ReactNode;
  sortable?: boolean;
  sorted?: 'asc' | 'desc';
  value?: (_: unknown) => string | number | Date;
  visible: boolean;
};

export class Table {
  static cols<Row>(col?: Column<Row>) {
    return col?.sortable
      ? (cols: Column<Row>[]) => cols.map(c => ((c.key === col.key ? (c.sorted ??= 'asc') : delete c.sorted) && c) || c)
      : (cols: Column<Row>[]) => cols;
  }

  static compare<Row>(key: keyof Row, sorted?: 'asc' | 'desc', value?: (_: unknown) => string | number | Date) {
    return value
      ? sorted === 'desc'
        ? (a: Row, b: Row) => (value(a[key]) <= value(b[key]) ? 1 : -1)
        : (a: Row, b: Row) => (value(a[key]) >= value(b[key]) ? 1 : -1)
      : sorted === 'desc'
        ? (a: Row, b: Row) => (a[key] <= b[key] ? 1 : -1)
        : (a: Row, b: Row) => (a[key] >= b[key] ? 1 : -1);
  }

  static rows<Row>(col?: Column<Row>, order?: keyof Row) {
    return col?.sortable
      ? order
        ? (rows: Row[]) => rows.sort(this.compare(order, col.sorted)).sort(this.compare(col.key, col.sorted, col.value))
        : (rows: Row[]) => rows.sort(this.compare(col.key, col.sorted, col.value))
      : (rows: Row[]) => rows;
  }

  static sortable<Row>(cols: Column<Row>[]) {
    return cols.map(col => Object.assign(col, { sortable: !!col.label && col.sortable !== false }));
  }

  static sorted<Row>(cols: Column<Row>[]) {
    return cols.find(({ sorted }) => !!sorted);
  }

  static view({ page, total, size }: { page?: number; size: number; total: number }) {
    return {
      get end() {
        return Math.min(this.total, this.page * this.size);
      },
      get page() {
        return Math.min(this.pages, Math.max(1, page || 0));
      },
      get pages() {
        return Math.ceil(this.total / this.size) || 0;
      },
      get size() {
        return size || 0;
      },
      get start() {
        return Math.max(0, this.page * this.size - this.size);
      },
      get total() {
        return total || 0;
      },
    };
  }
}

export const useTable = <Row>(props: {
  cols: () => Column<Row>[];
  rows: () => Row[];
  view: () => { page?: number; size: number; total: number };
  order?: keyof Row;
}): [
  Column<Row>[],
  MutableRefObject<Row[]>,
  MutableRefObject<ReturnType<typeof Table.view>>,
  MutableRefObject<(sort?: Column<Row>) => void>,
] => {
  const sortable = Table.sortable(props.cols());
  const sorted = Table.sorted(sortable);
  const [cols, setCols] = useState(Table.cols(sorted)(sortable));
  const rows = useRef(Table.rows(sorted, props.order)(props.rows()));
  const view = useRef(Table.view(props.view()));
  rows.current = rows.current.slice(view.current.start, view.current.end);
  return [
    cols,
    rows,
    view,
    useRef(
      useCallback(
        sort => {
          if (
            (sort?.sortable && (sort.sorted = sort.sorted === 'desc' ? 'asc' : 'desc')) ||
            (sort ??= Table.sorted(cols))
          ) {
            setCols(
              Table.cols(sort)(
                Table.sortable(props.cols()).map(({ label }, i) =>
                  Object.assign(cols[i], { label, sorted: sort?.sorted })
                )
              )
            );
            rows.current = Table.rows(sort, props.order)(props.rows());
            view.current = Table.view({ page: view.current.page, size: view.current.size, total: props.view().total });
          }
        },
        [props, cols, setCols]
      )
    ),
  ];
};
