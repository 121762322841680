import { type MutableRefObject } from 'react';
import { type OpenFormActions, type OpenFormState } from './OpenFormReducer.js';
import { type OpenFormStorage } from './OpenFormStorage.js';
import {
  deleteOpenFormDrafts,
  fetchAddressSearch,
  fetchBillChannels,
  fetchBillingAccounts,
  fetchHolidays,
  fetchOpenForm,
  fetchOpenFormAccount,
  fetchOpenFormAddress,
  fetchOpenFormDraft,
  fetchOpenFormDraftItemList,
  fetchOpenFormItemList,
  fetchOpenFormProductAvailability,
  fetchOpenFormSummary,
  parameterizedSearchContacts,
  postOpenForm,
  postOpenFormDraft,
} from '../../common/fetch.js';
import type { OpenFormCustomerNeedRequest } from '../../generated/api/openFormCustomerNeedRequest.js';
import type { OpenFormDraftItem } from '../../generated/api/openFormDraftItem.js';
import type { OpenFormDraftUpsert } from '../../generated/api/openFormDraftUpsert.js';
import type { OpenFormListItem } from '../../generated/api/openFormListItem.js';
import type { SourceSystem } from '../../generated/api/sourceSystem.js';

export class OpenFormAsync<Resolved = unknown> {
  static readonly cache = new Map<string, Promise<unknown>>();
  static readonly draft = new Map<string, OpenFormDraftItem>();
  static readonly flags = new Set<'draft'>(['draft']);
  static readonly forms = new Map<string, OpenFormListItem>();

  static actions: OpenFormActions;
  static storage: OpenFormStorage;
  static #state: MutableRefObject<OpenFormState>;

  static get state(): OpenFormState {
    return this.#state.current;
  }

  static set state(state: MutableRefObject<OpenFormState>) {
    this.#state = state;
  }

  static deleteOpenFormDrafts(query: { referenceNumbers: string[] }) {
    return new this(({ signal }) => deleteOpenFormDrafts(query, { signal }));
  }

  static fetchAddressSearch(query: { postalCode: string; query: string; filter: boolean }) {
    return new this(({ signal }) => fetchAddressSearch(query, { signal }));
  }

  static fetchBillChannels() {
    return new this(({ signal }) => fetchBillChannels({ signal }));
  }

  static fetchBillingAccounts({ sourceSystem }: { sourceSystem: SourceSystem }) {
    return new this(({ signal }) =>
      Promise.allSettled([
        fetchBillingAccounts({ sourceSystem }, { signal }),
        fetchBillingAccounts({ sourceSystem, useSearchService: true }, { signal }),
      ])
    );
  }

  static fetchContacts(query: { searchTerm: string }) {
    return new this(({ signal }) => parameterizedSearchContacts(query, { signal }));
  }

  static fetchHolidays() {
    return new this(({ signal }) => fetchHolidays({ signal }));
  }

  static fetchOpenForm({ formId, referenceNumber }: OpenFormDraftUpsert, preview = false) {
    return new this(({ signal }) =>
      Promise.all(
        !preview && OpenFormAsync.flags.has('draft')
          ? referenceNumber
            ? [fetchOpenForm({ formId }, { signal }), fetchOpenFormDraft({ referenceNumber }, { signal })]
            : [fetchOpenForm({ formId }, { signal }), postOpenFormDraft({ formId }, { signal })]
          : [fetchOpenForm({ formId }, { signal })]
      )
    );
  }

  static fetchOpenFormAccount() {
    return new this(({ signal }) => fetchOpenFormAccount({ signal }));
  }

  static fetchOpenFormList() {
    return new this(({ signal }) =>
      Promise.all(
        OpenFormAsync.flags.add('draft') && [fetchOpenFormItemList({ signal }), fetchOpenFormDraftItemList({ signal })]
      )
    );
  }

  static fetchOpenFormProductAvailability(query: { addressId: string; formId: string; subscriptionTypes: string[] }) {
    return new this(({ signal }) =>
      Promise.allSettled([
        fetchOpenFormAddress({ addressId: query.addressId }, { signal }),
        fetchOpenFormProductAvailability(query, { signal }),
      ])
    );
  }

  static fetchOpenFormSummary(query: { choices: string[] }) {
    return new this(({ signal }) => fetchOpenFormSummary(query, { signal }));
  }

  static postOpenForm(query: OpenFormCustomerNeedRequest) {
    return new this(({ signal }) => postOpenForm(query, { signal }));
  }

  static postOpenFormDraft(query: OpenFormDraftUpsert) {
    return new this(({ signal }) => postOpenFormDraft(query, { signal }));
  }

  private result?: (value: Resolved) => Resolved;
  private reject?: (reason: unknown) => void;
  private settle?: () => void;

  private id: string;
  private load?: boolean;
  private save?: boolean;

  private constructor(
    async: ({ signal }: AbortController) => Promise<Resolved>,
    readonly controller = new AbortController(),
    readonly promise = (load?: string | true) => {
      (this.load = !!load) && OpenFormAsync.actions.indicator({ id: this.id, load });
      return async(this.controller)
        .then(this.resolved().result)
        .catch(this.rejected().reject)
        .finally(this.settled().settle);
    }
  ) {
    this.id = String(async);
  }

  get evict() {
    return () => OpenFormAsync.cache.delete(this.id);
  }

  cache(...key: (boolean | null | number | string)[]) {
    (this.id += String(key.length && key)) && (this.save = true);
    return this;
  }

  execute(load?: string | true) {
    this.save
      ? OpenFormAsync.cache.has(this.id)
        ? OpenFormAsync.cache.get(this.id)!.then(this.result)
        : OpenFormAsync.cache.set(this.id, this.promise(load))
      : this.promise(load);
    return this;
  }

  failure({ reason }: PromiseRejectedResult, reject?: (text: string) => void) {
    if (reject && reason.name !== 'AbortError') {
      reason instanceof Response ? reason.text().then(reject) : reject(String(reason));
    }
    this.evict();
  }

  resolved(result?: (value: Resolved) => void) {
    this.result ??= (value: Resolved) => {
      result?.(value);
      return value;
    };
    return this;
  }

  rejected(reject?: (reason: string) => void) {
    this.reject ??= (reason: unknown) => {
      this.failure({ reason } as PromiseRejectedResult, reject);
    };
    return this;
  }

  settled(cleanup?: () => void) {
    this.settle ??= () => {
      cleanup?.();
      this.load && OpenFormAsync.actions.indicator({ id: this.id, load: undefined });
    };
    return this;
  }
}
