import { type OpenForm } from '../OpenFormHooks/useOpenForm.js';
import { type OpenFormAnswers } from '../OpenFormAnswers.js';
import { type OpenFormAsync } from '../OpenFormAsync.js';
import { OpenFormButton } from '../OpenFormComponents/OpenFormButton.js';
import { type OpenFormGoto } from '../OpenFormHooks/useOpenFormGoto.js';
import { OpenFormGridCol, OpenFormGridRow } from '../OpenFormComponents/OpenFormGrid.js';
import { OpenFormIcon } from '../OpenFormComponents/OpenFormIcon.js';
import { OpenFormSummaryBillingAccount } from './OpenFormSummary/OpenFormSummaryBillingAccount.js';
import { OpenFormSummaryDeliveryDateTime } from './OpenFormSummary/OpenFormSummaryDeliveryDateTime.js';
import { OpenFormSummaryOrderingContact } from './OpenFormSummary/OpenFormSummaryOrderingContact.js';
import { OpenFormSummaryOtherContacts } from './OpenFormSummary/OpenFormSummaryOtherContacts.js';
import { OpenFormSummaryProductTable } from './OpenFormSummary/OpenFormSummaryProductTable.js';
import { confirmMsg, previousMsg, submittingFormMsg, summaryMsg, t } from '../../../common/i18n/index.js';
import { useOpenFormSummary } from '../OpenFormHooks/useOpenFormSummary.js';

export const OpenFormViewSummary = ({
  form,
  answers,
  disabled,
  preview,
  async,
  goto,
}: {
  form: OpenForm;
  answers: OpenFormAnswers;
  disabled: boolean;
  preview: boolean;
  async: typeof OpenFormAsync;
  goto: OpenFormGoto;
}) => {
  const summary = useOpenFormSummary(form, answers);
  return !summary ? null : (
    <div className="of-openform__view__summary">
      <OpenFormGridRow>
        <h3>{t.QF5T(summaryMsg)}</h3>
      </OpenFormGridRow>
      <OpenFormSummaryOrderingContact summary={summary} />
      <OpenFormSummaryBillingAccount summary={summary} />
      <OpenFormSummaryOtherContacts summary={summary} />
      <OpenFormSummaryDeliveryDateTime summary={summary} />
      <OpenFormSummaryProductTable summary={summary} />
      <OpenFormGridRow className="of-openform__view__footer">
        <OpenFormGridCol className="of-openform__view__footer__content">
          <OpenFormButton
            iconPosition="left"
            icon={<OpenFormIcon icon="arrow-left" />}
            text={t.CACI(previousMsg)}
            color="link"
            size="m"
            disabled={disabled}
            onClick={() => goto({ page: 'prev' })}
          />
          {preview ? null : (
            <OpenFormButton
              iconPosition="right"
              icon={<OpenFormIcon icon="check" />}
              text={t.QVYK(confirmMsg)}
              color="primary"
              size="m"
              disabled={disabled || !form.sections.every(answers.isCompleted)}
              onClick={() => {
                try {
                  async
                    .postOpenForm(summary.openFormCustomerNeedRequest)
                    .resolved(() => goto({ page: 'completed' }))
                    .rejected(text => async.actions.notification({ text, type: 'warning' }))
                    .execute(t.JNCH(submittingFormMsg));
                } catch (error) {
                  async.actions.notification({ text: error, type: 'warning' });
                }
              }}
            />
          )}
        </OpenFormGridCol>
      </OpenFormGridRow>
    </div>
  );
};
