import { type BillingAccountData } from '../OpenFormAnswers.js';
import { concat } from '../OpenFormUtils.js';
import { eInvoiceMsg, emailInvoiceMsg, paperInvoiceMsg, t } from '../../../common/i18n/index.js';
import type { BillingAccount } from '../../../generated/api/billingAccount.js';
import type { ParameterizedSearchContactResult } from '../../../generated/api/parameterizedSearchContactResult.js';

export const OpenFormBillingAccount = ({ account }: { account: BillingAccount | BillingAccountData }) => (
  <>
    <div>{account.billingAccountName || account.payerName}</div>
    <div>{account.payerNameExtension}</div>
    <div>{account.billingAccountExtensionName}</div>
  </>
);

export const OpenFormBillingContact = ({ account }: { account: BillingAccount | BillingAccountData }) => (
  <>
    <div>{(account as BillingAccountData).billingContactAccountName}</div>
    <div>{account.billingContactName}</div>
    <div>{(account as BillingAccountData).billingContactEmail}</div>
    <div>{account.billingContactPhone}</div>
  </>
);

export const OpenFormBillingInvoiceDeliveryMethod = ({ account }: { account: BillingAccount | BillingAccountData }) => {
  switch (account.deliveryMethod) {
    case 'ELECTRONIC': {
      const operator = (account as BillingAccountData).billElectronicOperatorLabel || account.billElectronicOperator;
      return (
        <>
          <div>
            {operator} {account.billElectronicAddress}
          </div>
          <div>{t.IK1D(eInvoiceMsg)}</div>
        </>
      );
    }
    case 'EMAIL': {
      const email = account.billReceiverEmail || (account as BillingAccountData).billingContactEmail;
      return (
        <>
          <div>{email}</div>
          <div>{t.NNV9(emailInvoiceMsg)}</div>
        </>
      );
    }
    case 'PAPER':
      return (
        <>
          {!account.payerAddress ? null : (
            <>
              <div>{concat(account.payerAddress.line1, account.payerAddress.line2).join(' ')}</div>
              <div>
                {account.payerAddress.postalCode} {account.payerAddress.postOffice}
              </div>
            </>
          )}
          <div>{t.TER4(paperInvoiceMsg)}</div>
        </>
      );
  }
  return null;
};

export const OpenFormContact = ({ contact }: { contact: ParameterizedSearchContactResult }) => (
  <>
    <div>{contact.Account?.Name}</div>
    <div>{concat(contact.FirstName, contact.LastName).join(' ')}</div>
    <div>{contact.Email}</div>
    <div>{contact.MobilePhone}</div>
  </>
);
